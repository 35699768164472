import { cloneDeep } from 'lodash';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Col, Row } from 'reactstrap';
import { setLoading } from '../../store/slices/auth';
import { CustomButton, showToaster, toasterTypes } from '../../widgets';
import { FIELD_TYPES, FieldItem } from '../../widgets/fields';
import useFieldItem from '../../widgets/fields/UseFieldItem';
import ModalComponent from '../common/Modal/modal';
import { createVariable, getRoles, getUsers, getVariableTypes, getVariableValidations, updateVariable } from './apiController';
import { CreateVariableElements } from './FormElements';
import { MESSAGES } from '../../utils/Constants';
import { getProjectTeamList } from '../people/apiController';
import { DeleteFilled, PlusCircleOutlined } from '@ant-design/icons';

export default function CreateVariable(props) {
	const dispatch = useDispatch();
	const initialValues = useMemo(() => (
		props.previousState ?
			{ fieldName: props.previousState.fields.fieldName, fieldDescription: props.previousState.fields.fieldDescription, fieldType: props.previousState.fields.fieldType, useType: [], formuidType: null, autoFormFields: null, validation: null, visibility: null, timeSeries: props.previousState.fields?.timeSeries, timeStamp: props.previousState.fields?.timeStamp }
			:
			{ fieldName: '', fieldDescription: '', fieldType: null, useType: null, formuidType: null, autoFormFields: null, validation: null, visibility: null, timeSeries: null, timeStamp: null }
	), [props.previousState]);
	const [values, fields, handleChange, { validateValues, reset, updateValue, resetValue, updateError }] = useFieldItem(CreateVariableElements, initialValues, { onValueChange });
	const [variableTypes, setvariableTypes] = useState([]);
	const [variables, Setvariables] = useState([]);
	const [variableValidations, setvariableValidations] = useState([]);
	const [forms, setforms] = useState([]);
	const [roles, setroles] = useState<any | []>([]);
	const [permissions, setpermissions] = useState([{ id: 1, role: null, users: [], user: '', accessType: [] }]);
	const [isTimeSeries, setisTimeSeries] = useState(false);
	const [timeSheet, settimeSheet] = useState([{ prePost: null, value: 0, duration: null }]);
	const [options, setoptions] = useState(props.previousState ? props.previousState.options : ['', '', '']);
	const [disabled, SetDisabled] = useState(props?.isEdit)
	const [isCom, SetCom] = useState(false);
	/*
	  removed { label: 'Parent Identifier', value: 'parent_identifier' }
	  from useTypeValues array 
	*/
	const useTypeValues = [
		{ label: 'Identifier', value: 'identifier' },
		{ label: 'Authenticator', value: 'authenticator' },
		{ label: 'Analytical', value: 'analytical' },
		{ label: 'Non-Analytical', value: 'non-analytical' },
		{ label: 'Communicator', value: 'communicator' },
		{ label: 'GEN-UID', value: 'gen-uid' },
		{ label: 'GEN-UID without -', value: 'gen-uid-without' },
		{ label: 'GEN-UID use first 2 char', value: 'gen-uid-2' },
		{ label: 'GEN-UID use first 3 char', value: 'gen-uid-3' },
		{ label: 'GEN-UID use first 4 char', value: 'gen-uid-4' },
		{ label: 'GEN-UID use first 5 char', value: 'gen-uid-5' },
		{ label: 'Dashboard', value: 'dashboard' },
		{ label: 'NON-MERGE', value: 'non-merge' },
		{ label: 'Group-by', value: 'group_by' },
		{ label: 'SearchIndex', value: 'SearchIndex' },
		{ label: 'VisibleInTabularView', value: 'VisibleInTabularView' }
	];

	useEffect(() => {
		//getVariableList()
	}, [variables])

	useEffect(() => {
		getVariableList()
		async function getData() {
			fetchRoles(0);
			let tempForms = cloneDeep(props.forms);
			tempForms.forEach(obj => {
				obj.label = obj.form_name;
				obj.value = obj.id;
			});

			setforms(tempForms.filter(obj => obj.id !== props.formData.id));
			await fetchVariableTypes();
			await fetchVariableValidations();
			updateValue({ useType: [{ label: 'Analytical', value: 'analytical' }], timeSeries: { label: "No", value: "no" } })
			if (props.previousState) {
				let temp: any = [];
				props.previousState.fields.useType.split(",").map(values => {
					temp.push(useTypeValues.filter(obj => obj.label === values)[0])
				})

				if (props.previousState.fields.dependentType.length > 0) {
					updateValue({ formuidType: "auto" })
				} else {
					updateValue({ formuidType: "manual" })
				}

				let tempUID: any = [];
				await props.previousState.fields.dependentType.map(values => {
					console.log(props.variableData.filter(obj => obj['value'] == values))
					tempUID.push(props.variableData.filter(obj => obj['value'] == values)[0])
				})

				console.log(tempUID)
				let tempVisibility: any = tempForms.filter(function (obj) { return props.previousState.fields.visibility.indexOf(obj.value) !== -1; });
				let tempValidation: any = variableValidations?.filter(function (obj) { return props.previousState.fields.validation.indexOf(obj) !== -1; });
				updateValue({ minValue: props.previousState?.fields?.minValue, maxValue: props.previousState?.fields?.maxValue, useType: temp, autoFormFields: tempUID, visibility: tempVisibility, timeStamp: props.previousState?.fields?.timeStamp ? new Date(moment().format('MM-DD-YYYY') + ' ' + props.previousState?.fields?.timeStamp) : null });

				let tempTimeSheet: any = [];
				props.previousState?.data?.timesheet?.forEach((obj: any) => {
					if (obj) {
						obj = obj.split('_');
						tempTimeSheet.push({
							prePost: CreateVariableElements.prePost.values.filter(subObj => subObj.value === obj[1])[0],
							value: obj[2],
							duration: CreateVariableElements.duration.values.filter(subObj => subObj.value === obj[3])[0]
						})
					}
				});
				settimeSheet(tempTimeSheet.length > 0 ? tempTimeSheet : [{ prePost: null, value: 0, duration: null }]);

			}
		}

		getData()
	}, []);

	const setDependentValue = async () => {
		if (props.previousState) {
			let tempUID: any = [];
			await props.previousState.fields.dependentType.map(values => {
				console.log(variables.filter(obj => obj['value'] == values))
				tempUID.push(variables.filter(obj => obj['value'] == values)[0])
			})

			updateValue({ autoFormFields: tempUID })
		}
	}

	/**
	 * get variables list for form UID
	 */
	const getVariableList = () => {
		let VariableData: any = [];
		if (props.previousState?.fields) {

			VariableData = props.variableData.filter(item => item.value != props.previousState.fields.id)

		} else {
			VariableData = props.variableData
			console.log(props.variableData)
		}

		Setvariables(VariableData)

		return VariableData;
	}
	/**
	 * get vaitable types
	 */
	const fetchVariableTypes = async () => {

		try {
			dispatch(setLoading(true));
			let tempVariableTypes = await getVariableTypes();
			if (tempVariableTypes && tempVariableTypes.length > 0) {
				tempVariableTypes.forEach(obj => {
					obj.label = obj.display_name;
					obj.value = obj.id;
				})
				if (props.previousState) {
					let temp: any = tempVariableTypes.filter(obj => obj.type === props.previousState.fields.fieldType);
					updateValue({ fieldType: temp[0] });
				} else {
					updateValue({ fieldType: tempVariableTypes[0] });
				}
			}
			setvariableTypes(tempVariableTypes || []);
			dispatch(setLoading(false));
		} catch (e: any) {
			setvariableTypes([]);
			dispatch(setLoading(false));
		}
	}

	const fetchVariableValidations = async () => {
		try {
			dispatch(setLoading(true));
			let tempVariableValidations = await getVariableValidations();
			if (tempVariableValidations && tempVariableValidations.length > 0) {
				tempVariableValidations.forEach(obj => {
					obj.label = obj.name;
					obj.value = obj.type;
				});

				setvariableValidations(tempVariableValidations || []);

				updateValue({ validation: [] });
				if (props.previousState) {
					let temp: any = tempVariableValidations.filter(function (obj) { return props.previousState.fields.validation.indexOf(obj.type) !== -1; });
					updateValue({ validation: temp });
				}
			}
			dispatch(setLoading(false));
		} catch (e: any) {
			setvariableTypes([]);
			dispatch(setLoading(false));
		}
	}

	function onValueChange(name, value, values, fieldValues = null) {
		console.log(values)
		if (name === 'fieldType') {
			setoptions(['', '', '']);
			if (!(values.value === '635907ccbc422db8f32277f3' || values.value === '635907e9bc422db8f322780a' || values.value === '6359089ebc422db8f32278b6')) {
				values.validation = [];
				values.minValue = '';
				values.maxValue = '';
			}
		} else if (name === 'timeSeries' && value?.value === 'no') {
			setTimeout(() => {
				resetValue({ timeStamp: null });
			}, 0);
			settimeSheet([{ prePost: null, value: 0, duration: null }]);
		} else if (name === 'visibility' && value.length > 0) {
			updateError('visibility', { hasError: false, errorMsg: "" })
		} else if (name === 'fieldName') {
			values["fieldDescription"] = value;
		}

		if (value.value == "communicator") {
			SetCom(true)
		}




		return [{
			...values,
			[name]: value,
		}]
	}

	/**
	 * if use type is communicator
	 */
	const confirmCommunicator = () => {

		if (values.validationpopup) {
			updateValue({ useType: { label: 'Communicator', value: 'communicator' } })
			updateError('validationpopup', { hasError: true, errorMsg: "Please select validation" })
			let temp: any = variableValidations.filter(item => item["type"] == values.validationpopup);
			updateValue({ validation: temp });
			SetCom(false)
		}
	}

	const onCloseCom = () => {
		updateValue({ useType: { label: 'Analytical', value: 'analytical' } })
		SetCom(false)
	}

	function changeTimeSheet(name, value, index) {
		if (index >= 0) {
			let tempTimeSheet = cloneDeep(timeSheet);
			tempTimeSheet[index][name] = value;
			settimeSheet(tempTimeSheet);
		}
	}

	const addTimeSheet = () => {
		let tempTimeSheet = cloneDeep(timeSheet);
		tempTimeSheet.push({ prePost: null, value: 0, duration: null });
		settimeSheet(tempTimeSheet);
	}

	const fetchRoles = async (index) => {
		try {
			dispatch(setLoading(true));
			let tempRoles = await getRoles();
			if (tempRoles && tempRoles.data && tempRoles.data.length > 0) {
				tempRoles.data.forEach((obj, index) => {
					obj.label = obj.name;
					obj.value = obj.name.toLowerCase();
					obj.index = index;
					obj.users = [];
				});
			}
			if (props.previousState) {
				let temp: any = [];
				props.previousState?.data?.field_role_access?.forEach((roleObj) => {
					let tempPermissions = tempRoles.data.filter((obj) => obj.value === roleObj.role_name);
					temp.push({ role: tempPermissions[0], users: [], user: '', accessType: roleObj.permissions });
				});
				if (props.previousState?.data?.field_user_access && props.previousState.data.field_user_access.length > 0) {
					props.previousState?.data?.field_user_access?.forEach(async (userObj, userIndex) => {
						let tempPermissions = tempRoles.data.filter((obj) => obj.value === userObj.role_name);
						userObj.label = userObj.email;
						userObj.value = userObj.user_number;
						let roleIndex = tempRoles?.data.findIndex((obj) => obj.value === userObj.role_name);
						if (roleIndex >= 0) {
							try {
								dispatch(setLoading(true));
								let projectid: any = { projectid: props.projectData.id };
								let tempUsers = await getProjectTeamList(projectid, 1, 100, "", "accepted", userObj.role_name);
								//let tempUsers = await getUsers(props.projectData.TopicCode || props.projectData.TopicID, userObj.role_name);
								if (tempUsers?.users && tempUsers.users.length > 0) {
									tempUsers.users.forEach((obj) => {
										obj.label = obj.invitee_name;
										obj.value = obj.invitee_id;
									});
									tempRoles.data[roleIndex].users = tempUsers.users;
									userObj.permissions.filter(item => item.is_selected == true).map(permissionItem => {
										let accessTypeValue = permissionItem.title == "read" ? "r" : permissionItem.title == "write" ? "w" : "f";

										temp.push({
											role: tempPermissions[0],
											users: tempUsers.users,
											user: userObj, accessType: accessTypeValue
										});
									})
								} else {
									userObj.permissions.filter(item => item.is_selected == true).map(permissionItem => {
										let accessTypeValue = permissionItem.title == "read" ? "r" : permissionItem.title == "write" ? "w" : "f";

										temp.push({
											role: tempPermissions[0],
											users: [],
											user: userObj, accessType: accessTypeValue
										});
									})
								}
								dispatch(setLoading(false));
							} catch (e: any) {
								userObj.permissions.filter(item => item.is_selected == true).map(permissionItem => {
									let accessTypeValue = permissionItem.title == "read" ? "r" : permissionItem.title == "write" ? "w" : "f";

									temp.push({
										role: tempPermissions[0],
										users: [],
										user: userObj, accessType: accessTypeValue
									});
								})
								dispatch(setLoading(false));
							}
						}
						if (userIndex + 1 === props.previousState?.data?.field_user_access.length) {
							if (temp.length === 0) {
								temp = [{ id: 1, role: null, users: [], user: '', accessType: [] }];
							}
							setpermissions(temp);
							setroles(tempRoles.data);
						}
					});
				} else {
					if (temp.length === 0) {
						temp = [{ id: 1, role: null, users: [], user: '', accessType: [] }];
					}
					setpermissions(temp);
					setroles(tempRoles.data);
				}
			} else {
				if (tempRoles?.data && tempRoles.data.length > 0) {
					let tempPermissions: any = cloneDeep(permissions);
					tempPermissions[index] = { id: 1, role: null, users: [], user: '', accessType: [] };
					fetchUsers(tempRoles.data, 0, tempPermissions, index);
				}
			}
			dispatch(setLoading(false));
		} catch (e: any) {
			setroles([]);
			dispatch(setLoading(false));
		}
	}

	const addUser = () => {
		let temp: any = cloneDeep(permissions);
		temp.push({ id: temp[temp.length - 1].id + 1, role: null, users: [], user: '', accessType: [] });
		setpermissions(temp);
	}

	const fetchUsers = async (roleData, roleIndex, tempPermissions, perIndex) => {
		if (roleData[roleIndex].users.length === 0) {
			try {
				dispatch(setLoading(true));
				//let tempUsers = await getUsers(props.projectData.TopicCode || props.projectData.TopicID, roleData[roleIndex].label.toLowerCase());
				let projectid: any = { projectid: props.projectData.id };
				let tempUsers = await getProjectTeamList(projectid, 1, 100, "", "accepted", roleData[roleIndex].label.toLowerCase());
				if (tempUsers?.users && tempUsers.users.length > 0) {
					tempUsers.users.forEach((obj) => {
						obj.label = obj.invitee_name;
						if (obj.invitee_role == "field-executive") {
							obj.value = obj.imei;
						} else {
							obj.value = obj.invitee_id;
						}
					});
					roleData[roleIndex].users = tempUsers.users;
					tempPermissions[perIndex].users = tempUsers.users;
				}
				setpermissions(tempPermissions)
				setroles(roleData);
				dispatch(setLoading(false));
			} catch (e: any) {
				setroles(roleData);
				setpermissions(tempPermissions)
				dispatch(setLoading(false));
			}
		} else {
			tempPermissions[perIndex].users = roleData[roleIndex].users;
			setroles(roleData);
			setpermissions(tempPermissions)
		}
	}

	function changePermissions(name, value, indexid) {
		console.log(values)
		let temp: any = cloneDeep(permissions);
		if (name === 'role' && value) {
			temp[indexid].user = null;
			temp[indexid].users = [];
			temp[indexid][name] = value;
			fetchUsers(roles, value.index, temp, indexid);
		} else if (name === 'user' && value) {
			let isUser = false;
			// value.invitee_id && temp.forEach((obj) => {
			// 	console.log(values.invitee_id)
			// 	if (obj.user && (obj.user.invitee_id == value.invitee_id || obj.user?.user_number == value.invitee_id || obj.user?.imei == value.imei)) {
			// 		isUser = true;
			// 		return;
			// 	}
			// });

			permissions && permissions.map(item => {
				console.log(item.user)
				if (item.user && item.user['invitee_id'] == value.invitee_id) {
					isUser = true;
				}
			})
			if (isUser) {
				showToaster(toasterTypes.ERROR, 'User is already added');
			} else {
				temp[indexid].user = null;
				temp[indexid].users = permissions[0].users;
				temp[indexid][name] = value;
			}
			setpermissions(temp);
		} else if (name == 'accessType') {
			temp[indexid][name] = value;
			setpermissions(temp);
		} else {

		}
	}

	function changeOption(name, value, index) {
		let temp: any = cloneDeep(options);
		temp[index] = value;
		setoptions(temp);
	}

	const addOptions = () => {
		let tempOptions = cloneDeep(options);
		tempOptions.push('');
		setoptions(tempOptions);
	}

	/**
	 * remove permission from array
	 */
	const removePermission = (id) => {
		setpermissions(permissions.filter(item => item.id != id))
	}

	/**
	 * add permission
	 */
	const onAdd = () => {

		if (isTimeSeries) {
			if (validateValues(["timeStamp"])) {
				showToaster(toasterTypes.ERROR, "Please enter the proper values in the fields highlighted in red");
				return;
			} else {
				let tempTimeSheet = timeSheet.filter((obj) => (obj?.prePost?.['value'] != 'baseline' ? obj.prePost && obj.value && obj.duration : obj.prePost));
				if (tempTimeSheet.length === 0) {
					showToaster(toasterTypes.ERROR, "Please add proper time sheet values");
					return;
				} else {
					settimeSheet(tempTimeSheet);
					setisTimeSeries(false);
				}
			}
		} else if (checkValidation()) {
			if (validateValues(["minValue", "maxValue"])) {
				showToaster(toasterTypes.ERROR, "Please enter the proper values in the fields highlighted in red");
				return;
			} else {
				callUpdateAPI();
			}
		} else {
			if ((values?.useType?.value === 'identifier' || values?.useType?.value === 'authenticator')) {
				if (values?.fieldType?.label === 'fileUpload') {
					showToaster(toasterTypes.ERROR, `File Upload Type can't be ${values?.useType?.label}`);
					return;
				}
				if (validateValues(["fieldName", "fieldDescription", "fieldType", "useType", "timeSeries"])) {
					showToaster(toasterTypes.ERROR, "Please enter the proper values in the fields highlighted in red");
					return;
				} else if (values?.visibility?.length === 0) {
					updateError('visibility', { hasError: true, errorMsg: MESSAGES.MANDATORY })
					showToaster(toasterTypes.ERROR, "Please enter the proper values in the fields highlighted in red");
					return;
				} else {
					callUpdateAPI();
				}
			} else if (validateValues(["fieldName", "fieldDescription", "fieldType", "useType", "timeSeries"])) {
				showToaster(toasterTypes.ERROR, "Please enter the proper values in the fields highlighted in red");
				return;
			} else {
				callUpdateAPI();
			}
		}
	}

	const callUpdateAPI = async () => {
		let field_role_access: any = [];
		let field_user_access: any = [];
		permissions.forEach((obj: any) => {
			if (obj.accessType.length > 0 && obj.role) {
				if (obj.user && obj.user.value && obj.user.label && (obj.user.invitee_role || obj.user.role_name) && obj.accessType && obj.accessType.length > 0) {
					field_user_access.push({
						"user_id": obj.user.invitee_id || obj.user.user_number,
						"user_number": obj.user.invitee_id || obj.user.user_number,
						"email": obj.user.label,
						"role_name": obj.user.invitee_role || obj.user.role_name,
						"permissions": [
							{
								"title": "read",
								"permission": "read",
								"is_selected": obj.accessType == "r" ? true : false
							},
							{
								"title": "write",
								"permission": "write",
								"is_selected": obj.accessType == "w" ? true : false
							},
							{
								"title": "forbidden",
								"permission": "forbidden",
								"is_selected": obj.accessType == "f" ? true : false
							}
						]
					})
				} else if (obj.role?.value && obj.accessType && obj.accessType.length > 0) {
					field_role_access.push({
						"role_name": obj.role.value,
						"permissions": [
							{
								"title": "read",
								"permission": "read",
								"is_selected": obj.accessType == "r" ? true : false
							},
							{
								"title": "write",
								"permission": "write",
								"is_selected": obj.accessType == "w" ? true : false
							},
							{
								"title": "forbidden",
								"permission": "forbidden",
								"is_selected": obj.accessType == "f" ? true : false
							}
						]
					})
				}
			}
		});
		// if (values.timeSeries.value === 'yes' && !values.timeStamp) {
		// 	showToaster(toasterTypes.ERROR, "Please add time series");
		// 	return;
		// }
		//commented becuase all field types will allowed 
		// if ((values.useType.label === 'Identifier' || values.useType.label === 'Authenticator' || values.useType.label === 'Parent Identifier') && (values.fieldType.type !== 'fileUpload' && values.fieldType.type !== 'textBox' && values.fieldType.type !== 'number' && values.fieldType.type !== 'dropDown')) {
		// 	showToaster(toasterTypes.ERROR, `Field type can't be ${values.fieldType.label} for ${values.useType.label}`);
		// 	return;
		// }
		let tempTimeSheet: any = [];
		//commented if condition to remove timestamp dependency
		//if (values.timeStamp) {
		timeSheet.forEach((obj: any) => {
			console.log(obj)
			if (obj.prePost) {
				if (obj.prePost?.value == 'baseline') {
					console.log(obj.prePost)
					tempTimeSheet.push(`${values.fieldName}_${obj.prePost.value}`);
				} else {
					tempTimeSheet.push(`${values.fieldName}_${obj.prePost.value}_${obj.value}_${obj.duration.value}`);
				}
			}
		});
		//}

		let userTypeVariables = "";
		values.useType && values.useType.map((items, i) => {
			userTypeVariables += items.label
			if (values.useType.length != i + 1) {
				userTypeVariables += ","
			}
		})

		//prepare dependent fields data
		let dependent_variables: any = [];
		if (values.formuidType == "auto") {
			values.autoFormFields && values.autoFormFields.map(item => {
				dependent_variables.push(item['value'])
			})
		}


		//changed on validations line 401 : values?.fieldType?.value === '635907ccbc422db8f32277f3' || values?.fieldType?.value === '635907e9bc422db8f322780a' || values?.fieldType?.value === '6359089ebc422db8f32278b6' ? values.validation?.map(obj => obj.type) || [] : [],
		let reqObj: any = {
			topic_id: props.projectData.TopicCode || props.projectData.TopicID,
			field_name: values.fieldName,
			field_description: values.fieldDescription,
			field_type: values.fieldType.type,
			field_value: options.filter(obj => { return obj !== '' }),
			field_use_type: userTypeVariables,
			is_time_series: values.timeSeries.value === 'yes' ? true : false,
			timesheet: tempTimeSheet,
			time_series_time_stamp: values.timeStamp ? moment(values.timeStamp).format('HH:MM:SS') : moment().format('HH:MM:SS'),
			validations: values.validation?.map(obj => obj.type),
			field_visibility: values.visibility?.map(obj => obj.value) || [],
			field_role_access,
			field_user_access,
			dependent_fields: dependent_variables
		}
		if (values.minValue) {
			reqObj.field_min_value = values.minValue;
		}
		if (values.maxValue) {
			reqObj.field_max_value = values.maxValue;
		}
		if (props.previousState?.category) {
			reqObj.field_category = props.previousState.category;
		}
		if (props.previousState?.category) {
			reqObj.id = props.previousState?.data.id;
		}

		try {
			dispatch(setLoading(true));
			let response: any;

			if (props.previousState?.data.id) {
				response = await updateVariable(reqObj);
			} else {
				response = await createVariable(reqObj, props.formData.id);
			}
			dispatch(setLoading(false));
			showToaster(toasterTypes.SUCCESS, response.message);
			props.onClose('callAPI');
		} catch (e: any) {
			dispatch(setLoading(false));
		}
	}

	const checkValidation = () => {
		let check = (values?.validation || []).filter(obj => obj.name === "Range");
		return check.length > 0;
	}

	/**
	 * check special characters 
	 */
	const checkFrontEndValidation = (obj, index) => {
		const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
		if (specialChars.test(obj)) {
			updateError('option', { hasError: true, errorMsg: "Special Characters !@#$%^&*, not allowed" })
		} else {
			updateError('option', { hasError: false, errorMsg: "" })

		}
	}

	return (
		<>

			<ModalComponent
				width="800px"
				isOpen={isCom}
				onClose={() => onCloseCom()}
				header={"User Type Confirmation"}
				centered={true}
				customClass='profile-modal variable-modal'
				body={
					<>
						<p>
							Use Type Communicator will be used for comminication purpose.
							Please confirm with appropriate validator?
						</p>
						<FieldItem
							isDisabled={disabled}
							{...CreateVariableElements.validationpopup}
							value={values.validationpopup}
							values={variableValidations.filter(item => item["type"] == "FE-MobileNumber" || item["type"] == "FE-EmailFormat")}
							type={FIELD_TYPES.RADIO_BUTTON}
							onChange={(...e) => handleChange(CreateVariableElements.validationpopup.name, ...e)}
							touched={fields.validationpopup && fields.validationpopup.hasError}
							error={fields.validationpopup && fields.validationpopup.errorMsg}
						/>
					</>
				}
				footer={
					<div>
						<CustomButton type="primary" text={"Confirm"} onClick={() => confirmCommunicator()}></CustomButton>
					</div>
				}
			/>

			<ModalComponent
				width="1000px"
				isOpen={true}
				onClose={() => isTimeSeries ? setisTimeSeries(false) : props.onClose()}
				header={isTimeSeries ? "Time Series" : props.previousState?.data.id ? "Update Variable" : "Create Variable"}
				centered={true}
				customClass='variable-modal'
				body={
					<div>
						{isTimeSeries ?
							<>
								<Row>
									{/* <Col md={4}>
										<FieldItem
											{...CreateVariableElements.timeStamp}
											value={values.timeStamp}
											dateFormat='hh : MM aa'
											timeInterval={15}
											showTimeSelect={true}
											showTimeSelectOnly={true}
											onChange={(...e) => handleChange(CreateVariableElements.timeStamp.name, ...e)}
											touched={fields.timeStamp && fields.timeStamp.hasError}
											error={fields.timeStamp && fields.timeStamp.errorMsg}
										/>
									</Col> */}
								</Row>
								<div className='time-sheet-container'>
									<div className='time-sheet'>Time Sheet</div>
									{timeSheet.map((obj, index) => (
										<Row key={index}>
											<Col md={4}>
												<FieldItem
													{...CreateVariableElements.prePost}
													value={obj.prePost}
													onChange={(...e) => changeTimeSheet(CreateVariableElements.prePost.name, ...e, index)}
												/>
											</Col>
											{obj?.prePost && obj?.prePost['value'] != 'baseline' ?
												<>
													<Col md={4}>
														<FieldItem
															{...CreateVariableElements.value}
															isMandatory={obj?.prePost['value'] != 'baseline' ? false : true}
															value={obj.value}
															onChange={(...e) => changeTimeSheet(CreateVariableElements.value.name, ...e, index)}
														/>
													</Col>
													<Col md={4}>
														<FieldItem
															{...CreateVariableElements.duration}
															isMandatory={obj?.prePost['value'] != 'baseline' ? false : true}
															value={obj.duration}
															onChange={(...e) => changeTimeSheet(CreateVariableElements.duration.name, ...e, index)}
														/>
													</Col>
												</>
												: ""}
										</Row>
									))}
									<div className='add-text text-center' onClick={() => addTimeSheet()}>Add More</div>
								</div>
							</> :
							<>
								<Row>
									<Col md={4}>
										<FieldItem
											isDisabled={disabled}
											{...CreateVariableElements.fieldName}
											value={values.fieldName}
											onChange={(...e) => handleChange(CreateVariableElements.fieldName.name, ...e)}
											touched={fields.fieldName && fields.fieldName.hasError}
											error={fields.fieldName && fields.fieldName.errorMsg}
										/>
									</Col>
									<Col md={4}>
										<FieldItem
											isDisabled={disabled}
											{...CreateVariableElements.fieldDescription}
											value={values.fieldDescription}
											onChange={(...e) => handleChange(CreateVariableElements.fieldDescription.name, ...e)}
											touched={fields.fieldDescription && fields.fieldDescription.hasError}
											error={fields.fieldDescription && fields.fieldDescription.errorMsg}
										/>
									</Col>
									<Col md={4}>
										<FieldItem
											isDisabled={disabled}
											{...CreateVariableElements.fieldType}
											value={values.fieldType}
											values={variableTypes}
											onChange={(...e) => handleChange(CreateVariableElements.fieldType.name, ...e)}
											touched={fields.fieldType && fields.fieldType.hasError}
											error={fields.fieldType && fields.fieldType.errorMsg}
										/>
									</Col>
									<Col md={4}>
										<FieldItem
											isDisabled={disabled}
											{...CreateVariableElements.useType}
											value={values.useType}
											values={props.formData.survey_form ? useTypeValues.filter(temp => temp.value !== '') : useTypeValues.filter(temp => temp.value !== 'authenticator')}
											onChange={(...e) => handleChange(CreateVariableElements.useType.name, ...e)}
											touched={fields.useType && fields.useType.hasError}
											error={fields.useType && fields.useType.errorMsg}
										/>
									</Col>
									{
										values && values?.useType?.filter(item => item.value.includes("gen-uid")).length > 0 ?
											<Col md={4}>
												<FieldItem
													isDisabled={disabled}
													{...CreateVariableElements.formuidType}
													value={values.formuidType}
													onChange={(...e) => handleChange(CreateVariableElements.formuidType.name, ...e)}
													touched={fields.formuidType && fields.formuidType.hasError}
													error={fields.formuidType && fields.formuidType.errorMsg}
												/>
											</Col>
											: ""
									}


									{
										values.formuidType && values.formuidType == "auto" ?
											<Col md={4}>
												<FieldItem
													isDisabled={disabled}
													{...CreateVariableElements.autoFormFields}
													value={values.autoFormFields}
													values={variables && variables}
													onChange={(...e) => handleChange(CreateVariableElements.autoFormFields.name, ...e)}
													touched={fields.autoFormFields && fields.autoFormFields.hasError}
													error={fields.autoFormFields && fields.autoFormFields.errorMsg}
												/>
											</Col>
											: ""

									}

									<Col md={4}>
										<FieldItem
											isDisabled={disabled}
											{...CreateVariableElements.validation}
											value={values.validation && values.validation}
											values={variableValidations}
											onChange={(...e) => handleChange(CreateVariableElements.validation.name, ...e)}
											touched={fields.validation && fields.validation.hasError}
											error={fields.validation && fields.validation.errorMsg}
										/>
									</Col>
									<Col md={4}>
										<FieldItem
											isDisabled={disabled}
											{...CreateVariableElements.visibility}
											value={values.visibility}
											values={forms}
											onChange={(...e) => handleChange(CreateVariableElements.visibility.name, ...e)}
											isMandatory={values?.useType?.value === 'identifier' || values?.useType?.value === 'authenticator' ? true : false}
											touched={fields.visibility && fields.visibility.hasError}
											error={fields.visibility && fields.visibility.errorMsg}
										/>
									</Col>
								</Row>
								{(values.fieldType?.type === 'dropDown' || values.fieldType?.type === 'checkBox' || values.fieldType?.type === 'radioButton') &&
									<div className='options-container'>
										<Row>
											<Col md={4}>
												<div className='head-text'>{values.fieldType.label} Options</div>
											</Col>
											<Col md={8}>
												{
													!disabled ?
														<div className='user-text' onClick={() => addOptions()}>Add Options</div>
														:
														""
												}
											</Col>
										</Row>
										<Row>
											{options.map((obj, index) => (
												<Col md={4} key={index}>
													<FieldItem
														isDisabled={disabled}
														{...CreateVariableElements.option}
														value={obj}
														onChange={(...e) => changeOption('option', ...e, index)}
														touched={fields.option && fields.option.hasError}
														error={fields.option && fields.option.errorMsg}
														onBlur={() => checkFrontEndValidation(obj, index)}
													/>
												</Col>
											))}
										</Row>
									</div>
								}
								{checkValidation() &&
									<div className='options-container'>
										<div className='head-text'>Range</div>
										<Row>
											<Col md={4}>
												<FieldItem
													isDisabled={disabled}
													{...CreateVariableElements.minValue}
													value={values.minValue}
													onChange={(...e) => handleChange(CreateVariableElements.minValue.name, ...e)}
													touched={fields.minValue && fields.minValue.hasError}
													error={fields.minValue && fields.minValue.errorMsg}
												/>
											</Col><Col md={4}>
												<FieldItem
													isDisabled={disabled}
													{...CreateVariableElements.maxValue}
													value={values.maxValue}
													onChange={(...e) => handleChange(CreateVariableElements.maxValue.name, ...e)}
													touched={fields.maxValue && fields.maxValue.hasError}
													error={fields.maxValue && fields.maxValue.errorMsg}
												/>
											</Col>
										</Row>
									</div>
								}
								<div className='options-container'>
									<div className='head-text'>Time Series</div>
									<Row>
										<Col md={4}>
											<FieldItem
												isDisabled={disabled}
												{...CreateVariableElements.timeSeries}
												value={values.timeSeries}
												onChange={(...e) => handleChange(CreateVariableElements.timeSeries.name, ...e)}
												touched={fields.timeSeries && fields.timeSeries.hasError}
												error={fields.timeSeries && fields.timeSeries.errorMsg}
											/>
										</Col>
										{values.timeSeries?.value === 'yes' &&
											<Col md={8} className='text-right'>
												<div className='user-text' onClick={() => setisTimeSeries(true)}>Add Series</div>
											</Col>
										}
									</Row>
								</div>
								{!props?.isAction ?
									<div className='options-container'>
										<Row className='m-0'>
											<Col className='p-0'>
												<div className='head-text'>Permissions</div>
											</Col>
											<Col className='p-0'>
												{
													!disabled ?
														<div className='user-text' onClick={() => addUser()}>Add User</div>
														:
														""}
											</Col>
										</Row>
										{roles.length > 0 && (
											permissions.map((obj: any, index) => (
												<Row className='m-0 permissions-row' key={index}>
													<Col md="3">
														<FieldItem
															isDisabled={disabled}
															{...CreateVariableElements.role}
															value={obj.role}
															values={roles}
															onChange={(...e) => changePermissions(CreateVariableElements.role.name, ...e, index)}
														/>
													</Col>
													<Col md="4">
														<FieldItem
															isDisabled={disabled}
															{...CreateVariableElements.user}
															value={obj.user ? obj.user : ""}
															values={obj.users}
															onChange={(...e) => changePermissions(CreateVariableElements.user.name, ...e, index)}
														/>
													</Col>
													<Col md="4">
														<FieldItem
															isDisabled={disabled}
															{...CreateVariableElements.accessType}
															value={obj.accessType}
															onChange={(...e) => changePermissions(CreateVariableElements.accessType.name, ...e, index)}
														/>
													</Col>
													<Col md="1">
														{permissions.length > 1 ?
															<DeleteFilled style={{ color: 'red' }} onClick={() => removePermission(obj.id)} />
															:
															""
														}
													</Col>
												</Row>
											)
											))}
									</div>
									:
									""
								}
							</>
						}
					</div>
				}
				footer={
					<div className="submit">
						{!disabled ?
							<CustomButton type="primary" text={props.previousState?.data.id ? "Update" : "Add"} onClick={() => onAdd()}></CustomButton>
							: ""
						}
					</div>
				}
			/>

		</>
	)
}
