import { RightOutlined } from '@ant-design/icons';
import { cloneDeep } from 'lodash';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import { CurrentUser, removeProject, setLoading, setProjectPermissionData } from '../../store/slices/auth';
import { CustomButton, showToaster, toasterTypes } from '../../widgets';
import { FieldItem, FIELD_TYPES } from '../../widgets/fields';
import useFieldItem from '../../widgets/fields/UseFieldItem';
import ModalPopup from '../../widgets/modal';
import { getInvitesReceived } from '../people/apiController';
import InviteeCard from '../people/inviteeCard';
import { getProfileDetails } from '../profile/apiController';
import ProfileDetails from '../profile/ProfileDetails';
import { getProjects } from '../project/apiController';
import ProjectCardMentor from './ProjectCardMentor';
import { RightArrow } from '../surveyForm/Arrow';
import { deleteProject, getCurrentPayment, getPayment, getUserProjects, getUserTopics, hasPayment, moveProject } from './apiController';
import ProjectCard from './ProjectCard';
import Tabs from './Tabs';
import { RejectedModal } from '../people/RejectedModal';
import { getPieactive } from '../submissions/apiController';
import { getPermissionData } from '../projectDetails/apiController';
import ProjectCardCollab from './ProjectCardCollab';

interface CurrentProjects {
	CreatedOn: string,
	PGCourse: string,
	TopicBigDesc: any
	TopicDesc: string
	TopicID: string
	TopicStatus: string
	TopicCode: string
	id: string
	UserNumber: string,
	type: string
}

export default function ProjectMobile(props) {
	const dispatch = useDispatch();
    const navigate = useNavigate();
	const [selectedTab, setselectedTab] = useState(0);
	const [currentProjects, setcurrentProjects] = useState<any>([]);
	const [currentInvites,setcurrentInvites] = useState<any>([]);
	const [eventInvites, SetEventInvites] = useState<any>([]);
	const [userTopics, setuserTopics] = useState([]);
	const [popupType, setpopupType] = useState('');
	const [selectedData, setselectedData] = useState<CurrentProjects | null>(null);
	const [userData, setuserData] = useState<CurrentUser | null>(null);
	const [profile, setprofile] = useState<any | null>(null);
	const [pageno,Setpageno] = useState(1);
	const [perpage,Setperpage]= useState(10);
	const [mentorProject,SetmentorProjects] = useState([]);
	const [collabProject, SetcollabProjects] = useState([]);
	const [mentorCount, SetmentorCount] = useState(0);
	const [collabCount, SetcollabCount] = useState(0);
	const [inviteCount, SetinviteCount] = useState(0);

	useEffect(() => {
		let temp: any = localStorage.getItem("coguide_user");

		if (temp) {
			temp = JSON.parse(temp);
			setuserData(temp);
			fetchCurrentProjects(temp);
			//fetchCurrentTopics(temp);
		}
	}, []);

	const FormElements = {
		search: {
			name: "search",
			placeholder: "Search",
			label: "",
			type: FIELD_TYPES.SEARCH,
			isMandatory: false,
		},
	}
	
	const [values, fields, handleChange] = useFieldItem(FormElements, {}, {});

	/**
	 * create project
	 */
	const createProject = () => {
		navigate('/createprojectmobile');
	}

	
	/**
	 * fetch list of invites
	 * @param data 
	 */
	 const fetchCurrentInvites = async (data) => {
		try {
			dispatch(setLoading(true));
			console.log(data)
			// const getActiveData = await getPieactive(data.UserNumber);
			// SetEventInvites(getActiveData.data);
			let receviedInvites = await getInvitesReceived(pageno,perpage)
			setcurrentInvites(receviedInvites?.users || []);
			SetinviteCount(receviedInvites["total-count"] ? receviedInvites["total-count"] : 0);
			dispatch(setLoading(false));
		} catch (e: any) {
			setcurrentInvites([]);
			dispatch(setLoading(false));
		}
	}

	/**
	 * fetch list of projects
	 * @param data 
	 */
	const fetchCurrentProjects = async (data) => {
		try {
			dispatch(setLoading(true));
			console.log(data.UserNumber)
			const getActiveData = await getPieactive(data.UserNumber);
			SetEventInvites(getActiveData.data);
			let projects = await getUserProjects(data.UserNumber);
			setcurrentProjects(projects?.data);
			fetchCurrentInvites("");
			fetchProfileDetails();
			fetchUserProjectsCollab();
			fetchUserProjectsMentor();
			dispatch(setLoading(false));
		} catch (e: any) {
			setcurrentProjects([]);
			dispatch(setLoading(false));
		}
	}

	/**
	 * get mentor projects
	 */
	 const fetchUserProjectsMentor = async () => {
		try {
			dispatch(setLoading(true));
			let projects = await getProjects(pageno,perpage,"mentor");
			let temp: any = localStorage.getItem("coguide_user");
			let userid : any = JSON.parse(temp);
			//console.log(temp1.id)
			let permissionArray : Array<object> = []

			projects?.users.map(async(item,i) => {
				const permissionData = await getPermissionData("mentor",userid.id,item.id);
				permissionArray.push({projectid:item.id,data:permissionData.data})
				dispatch(removeProject(item.id));
				dispatch(setProjectPermissionData({projectId:item.id,permissionData:permissionData.data}));

				item.permissions = permissionData.data
			})
			SetmentorProjects(projects?.users || []);
			SetmentorCount(projects["total-count"] ? projects["total-count"] : 0);
			dispatch(setLoading(false));

		} catch (e: any) {
			SetmentorProjects([]);
			dispatch(setLoading(false));
		}
	}

		/**
	 * get collab projects
	 */
		const fetchUserProjectsCollab = async () => {
			try {
				dispatch(setLoading(true));
				let projects = await getProjects(pageno, perpage, "collaborator");
				let temp: any = localStorage.getItem("coguide_user");
				let userid: any = JSON.parse(temp);
				//console.log(temp1.id)
				projects?.users && projects?.users.map(async (item, i) => {
					const permissionData = await getPermissionData("collaborator", userid.id, item.id);
					item.permissions = permissionData.data
					dispatch(removeProject(item.id));
					dispatch(setProjectPermissionData({projectId:item.id,permissionData:permissionData.data}));
	
				})
				console.log(projects?.users)
				SetcollabCount(projects["total-count"] ? projects["total-count"] : 0)
				SetcollabProjects(projects?.users || []);
				dispatch(setLoading(false));
			} catch (e: any) {
				SetcollabProjects([]);
				dispatch(setLoading(false));
			}
		}
	


	/**
	 * fetch profile data
	 */
	const fetchProfileDetails = async () => {
		try {
			dispatch(setLoading(true));
			let response = await getProfileDetails({userid:""});
			setprofile(response);
			dispatch(setLoading(false));
		} catch (e: any) {
			dispatch(setLoading(false));
		}
	}

	const fetchCurrentTopics = async (data) => {
		try {
			dispatch(setLoading(true));
			let topics = await getUserTopics(data.PGCourse, data.UserNumber);
			setuserTopics(topics?.data || []);
			dispatch(setLoading(false));
		} catch (e: any) {
			setuserTopics([]);
			dispatch(setLoading(false));
		}
	}

	const onDelete = async () => {
		try {
			dispatch(setLoading(true));
			await deleteProject(userData?.UserNumber, selectedData?.TopicID || selectedData?.TopicCode, selectedTab);
			showToaster(toasterTypes.SUCCESS, 'Deleted Successfully');
			setpopupType('');
			setselectedData(null);
			dispatch(setLoading(false));
			if (selectedTab) {
				fetchCurrentTopics(userData);
			} else {
				fetchCurrentProjects(userData);
			}
		} catch (e: any) {
			setpopupType('');
			setselectedData(null);
			dispatch(setLoading(false));
		}
	}

	const onSampleSize = (data) => {
		setselectedData(data);
		setpopupType('sampleSize');
	}

	const onStudyModule = async(data) => {
		try {
			let dummyData: any = data;
			dispatch(setLoading(true));
			dummyData = await checkPayment(dummyData);
			let response = await hasPayment(dummyData?.UserNumber, dummyData?.TopicCode || dummyData?.TopicID);
			if(response.data) {
				setpopupType('');
				setselectedData(null);
				navigate('/studyModule', {state: dummyData});
			} else {
				setpopupType('subscriptions');
			}
			dispatch(setLoading(false));
		} catch (e: any) {
			setpopupType('');
			setselectedData(null);
			dispatch(setLoading(false));
		}
	}

	const checkPayment = async(dummyData) => {
		let paymentData = await getCurrentPayment(dummyData?.UserNumber, dummyData?.TopicCode || dummyData?.TopicID);
		if (paymentData.data === "no_active_plan" || paymentData.data === "free_trial") {                
			dummyData.type = 'can_buy';
		} else if (paymentData.data === "basic") {
			dummyData.type = 'can_upgrade';
		} else {
			dummyData.type = '';
		}
		return dummyData;
	}

	const sampleSize = async() => {
		try {
			dispatch(setLoading(true));
			let dummyData: any = cloneDeep(selectedData);
			dummyData = await checkPayment(dummyData);
			let response = await hasPayment(dummyData?.UserNumber, dummyData?.TopicCode || dummyData?.TopicID);
			if(response.data) {
				setpopupType('');
				setselectedData(null);
				navigate('/sampleSize', {state: dummyData});
			} else {
				setpopupType('subscriptions');
			}
			dispatch(setLoading(false));
		} catch (e: any) {
			setpopupType('');
			setselectedData(null);
			dispatch(setLoading(false));
		}
	}

	const onDataEntry = async(obj) => {
		try {
			dispatch(setLoading(true));
			let response = await hasPayment(obj?.UserNumber, obj?.TopicID);
			if(response.data) {
				navigate('/dataCollection', {state: obj});
			} else {
				setpopupType('subscriptions');
			}
			dispatch(setLoading(false));
		} catch (e: any) {
			dispatch(setLoading(false));
		}
	}

	const onDataCollection = async(obj) => {
		try {
			dispatch(setLoading(true));
			let response = await hasPayment(obj?.UserNumber, obj?.TopicCode);
			if(response.data) {
				setselectedData(obj);
				setpopupType('dataCollection');
			} else {
				setpopupType('subscriptions');
			}
			dispatch(setLoading(false));
		} catch (e: any) {
			setpopupType('');
			setselectedData(null);
			dispatch(setLoading(false));
		}
	}
	
	const dataCollection = () => {
		setpopupType('');
		setselectedData(null);
		navigate('/dataCollection', {state: selectedData});
	}

	const onProjectEdit = (obj) => {
		navigate('/createprojectmobile', {state: obj});
	}

	const onMove = async() => {
		try {
			dispatch(setLoading(true));
			let response = await moveProject({usernumber: selectedData?.UserNumber, topiccode: selectedData?.TopicID});
			if(response?.data === "Success") {
				fetchCurrentProjects(userData);
			}
			setpopupType('');
			setselectedData(null);
			dispatch(setLoading(false));
		} catch (e: any) {
			setpopupType('');
			setselectedData(null);
			dispatch(setLoading(false));
		}
	}

	var greetText='';
    if (new Date().getHours() < 12) {
        greetText = 'Good Morning';
    } else if (new Date().getHours() < 18) {
        greetText = 'Good Afternoon';
    } else {
        greetText = 'Good Evening';
    }

	/**
	 * action on register events
	 */
	 const registerdEvent = (data) => {
		console.log(data)
		navigate('/registeredevents', {state: data});
	}

	/**
	 * callback as props to get counts from invite module
	 * @param count 
	 */
	const getInviteCount = (count) =>{
		SetinviteCount(count);
		fetchUserProjectsCollab();
		fetchUserProjectsMentor();
	}

		/**
	 * on project card click get details of project
	 */
		 const OnProjectDetail = (data) =>{
			navigate("/projectdetail",{state: data})
		}
	

	return (

		<>

		<div className='header-bar-mob'>
		<Row className='m-0 mb-3'>
				<Col className='p-0 d-flex m-0'>
					<div className='profile-data-mobile'>
							{profile?.photo_link ?
							<div className='profile-container'>
								<img className='profile-image' alt='Profile' src={profile.photo_link} /> </div> :
								<div className='profile-container'>
									<img className='profile-image' alt='Profile' src={`${process.env.PUBLIC_URL}/images/logo.png`} />
								</div>
							}
							<div className='d-flex m-0 align-items-center'>			
								
									<div className='name-container'>
										{/* {!profile?.profile_title && <div className='greet-text'>Hello</div>} */}
									{/* <div className='title-text'>{profile?.profile_title}</div> */}

										<div className='first-text'>Hi, {profile?.first_name}<span className='last-text'></span></div>
										{(profile?.photo_link || profile?.first_name || profile?.profile_title) &&
											<div className='edit-container'><i className='icon-edit' onClick={() => setpopupType('profile')}></i></div>
										}
									</div>
										<div className='info-container'>
										<div className='greet-text'>{greetText}</div>
										</div>				
									
							</div>
					</div>
				</Col>
				
				<Col className='p-0 d-flex justify-content-end'>
					<i className='icon icon-notification'></i>
				</Col>
			</Row>

		
		</div>

		<div className="pending-action-mob">
			    <span className="pending-head">Pending Action</span>	
				<p className='pending-content'>Pending action to be completed</p>						
			
		</div>

		<div className='project-header'>
			<Row>
				<Col>
					<span className='projects'>Projects</span>
				</Col>
				<Col>
				<CustomButton className="create-project" type="primary" text="Create" onClick={createProject}></CustomButton>
				</Col>
			</Row>
		</div>

			
		<div className='project-page-mob'>
			{popupType === 'delete' &&
				<ModalPopup
					modalType='delete'
					modalText="Are you sure, you want to delete?"
					actionText="This action cannot be undone"
					closeModal={() => { setpopupType(''); setselectedData(null); }}
					onAction={() => onDelete()}
				/>
			}
			{popupType === 'subscriptions' &&
				<ModalPopup
					modalType='confirm'
					modalText="Please subscribe or renew subscription to proceed. Do you want to a make payment now?"
					actionText=""
					buttonText="Pay"
					closeModal={() => { setpopupType(''); setselectedData(null); }}
					onAction={() => navigate('/subscriptions')}
				/>
			}
			{popupType === 'sampleSize' &&
				<ModalPopup
					modalType='confirm'
					modalText="Do you want to start project from Sample size calculation?"
					actionText=""
					closeModal={() => { setpopupType(''); setselectedData(null); }}
					onAction={() => sampleSize()}
				/>
			}
			{popupType === 'dataCollection' &&
				<ModalPopup
					modalType='confirm'
					modalText="Do you want to start project from data entry?"
					actionText=""
					closeModal={() => { setpopupType(''); setselectedData(null); }}
					onAction={() => dataCollection()}
				/>
			}
			{popupType === 'move' &&
				<ModalPopup
					modalType='delete'
					modalText="Are you sure you want to move?"
					actionText="This action cannot be undone"
					closeModal={() => { setpopupType(''); setselectedData(null); }}
					onAction={() => onMove()}
				/>
			}
			

			{popupType === 'profile' &&
				<ProfileDetails {...props} data={profile} userNumber={profile.usernumber} onClose={() => setpopupType('')} />
			}
			<Tabs 
			   data={[{ title: currentProjects && currentProjects?.length, subTitle: 'MyProjects' },
			   		//   { title: userTopics.length, subTitle: 'NewProjects' },
						 { title: collabCount, subTitle: 'Collaborations' },
						 { title: mentorCount, subTitle: 'Mentoring Projects' },
						 { title: inviteCount, subTitle: 'Invites' }
					  ]} 
			   selected={selectedTab} 
			   onTab={(index) => setselectedTab(index)} />
 
			   	   
			{selectedTab == 0 ?
					 <div className="project-main">
						<Row className='project-container'>
						{currentProjects && currentProjects?.map((obj, index) => (
							<Col className={`mt-4 p-0 ${index % 2 === 0 ? 'pr-1' : 'pr-1'}`} key={index} xs={12}>
								<ProjectCard
									data={obj}
									type='projects'
									onDelete={() => { setselectedData(obj); setpopupType('delete'); }}
									onSampleSize={(data) => data.SDMName === 'NA' || data.SDMName === '' ? onSampleSize(data) : onStudyModule(data)}
									onData={() => onDataEntry(obj)}
									onEdit={() => onProjectEdit(obj)}
									onRegisterEvent={()=>registerdEvent(obj)}
									onMove={(obj) => {
										setselectedData(obj)
										setpopupType('move')
									}}
									onprojectDetail={()=>OnProjectDetail(obj)}
									isEventInvite={eventInvites && eventInvites.filter(item => item.project_id == obj["TopicID"])}

								/>
							</Col>
						))}
						{ currentProjects?.length == 0 &&
							<div className='no-data-container'>
								<div className='no-data-text'>No Data</div>
							</div>
						}
						</Row>
						</div>
				 :

				// selectedTab == 1 ?					
				// <div className="project-main">
				//   <Row className='project-container'>
				// 		{userTopics.map((obj, index) => (
				// 			<Col className={`mt-4 p-0 ${index % 2 === 0 ? 'pr-1' : 'pr-1'}`} key={index} xs={12}>
				// 			<ProjectCard
				// 					data={obj}
				// 					type='topics'
				// 					onDelete={() => { setselectedData(obj); setpopupType('delete'); }}
				// 					onSampleSize={(data) => { setselectedData(data); setpopupType('sampleSize'); }}
				// 					onData={() => onDataCollection(obj)}
				// 					onEdit={() => onProjectEdit(obj)}
				// 				/>
				// 			</Col>
				// 		))}
				// 		{userTopics.length === 0 &&
				// 			<div className='no-data-container'>
				// 				<div className='no-data-text'>No Data</div>
				// 			</div>
				// 		}
				// 	</Row>
				// 	</div>
				// 	:
					selectedTab == 1 ?					
					 <div className="project-main">
				  		<Row className='project-container'>			
							{collabProject.map((obj, index) => (
							<Col className={`mt-4 p-0 ${index % 2 === 0 ? 'pr-1' : 'pr-1'}`} key={index} xs={12}>
							<ProjectCardCollab
									data={obj}
									type='collaborator'
									onDelete={() => { setselectedData(obj); setpopupType('delete'); }}
									onSampleSize={(data) => data.SDMName === 'NA' ? onSampleSize(data) : onStudyModule(data)}
									onData={() => onDataEntry(obj)}
									onEdit={() => onProjectEdit(obj)}
									onMove={(obj) => {
										setselectedData(obj)
										setpopupType('move')
									}}
								/>
							</Col>
						))}
							{collabProject.length === 0 &&
								<div className='no-data-container'>
									<div className='no-data-text'>No Data</div>
								</div>
							}
						</Row>
					</div>
					:
					selectedTab == 2 ?	

					<div className="project-main">
				  		<Row className='project-container'>
						{mentorProject.map((obj, index) => (
							<Col className={`mt-4 p-0 ${index % 2 === 0 ? 'pr-1' : 'pr-1'}`} key={index} xs={12}>					
								<ProjectCardMentor
									data={obj}
									type='mentor'
									onDelete={() => { setselectedData(obj); setpopupType('delete'); }}
									onSampleSize={(data) => data.SDMName === 'NA' ? onSampleSize(data) : onStudyModule(data)}
									onData={() => onDataEntry(obj)}
									onEdit={() => onProjectEdit(obj)}
									onMove={(obj) => {
										setselectedData(obj)
										setpopupType('move')
									}}
								/>
							</Col>
						))}
						{
						mentorProject.length === 0 &&
							<div className='no-data-container'>
								<div className='no-data-text'>No Data</div>
							</div>
						}
						</Row>
					</div>
					:
					selectedTab == 3 ?
						<div className="invites-container">
							<InviteeCard data={currentInvites} getCount={getInviteCount}/>
						</div>
					:
					<div className='no-data-container'>
						<div className='no-data-text'>No Data</div>
					</div>
			}
		</div>
		</>
	)
}