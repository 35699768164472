import { Col, Row } from "react-bootstrap";
import { FIELD_TYPES, FieldItem } from "../../widgets/fields";
import { useEffect, useState } from "react";
import VariableModal from "./VariableModal";
import ConfigSelectorCard from "./ConfigSelectorCard";
import { getAnalysisChartTypes, getAnalysisVariables, getFeatureList, getFormulaList, getOutcomeVariable, getSplitOnList, updateSelectedConfig } from "./apiController";
import { useDispatch } from "react-redux";
import { setLoading } from "../../store/slices/auth";
import useFieldItem from "../../widgets/fields/UseFieldItem";
import { CustomButton, showToaster, toasterTypes } from "../../widgets";
import { Button } from "reactstrap";

export default function AddConfiguration(props) {
    const { previousData, data } = props;
    const [values, fields, handleChange, { validateValues, reset, updateValue, updateError }] = useFieldItem({}, {}, { onValueChange });
    const [outcomeVariable, SetOutcomeVariable] = useState<any>([]);
    const [explanatoryVariable, SetexplanatoryVariable] = useState<any>([]);
    const [popupType, SetpopupType] = useState("");
    const [variableType, SetVaraibleType] = useState("");
    const [isChart, SetisChart] = useState(false);
    const [chartTypes, SetChartTypes] = useState<any>([]);
    const [formulaTypes, SetFormulaTypes] = useState<any>([]);
    const [splitonTypes, SetSplitonTypes] = useState<any>([]);
    const [featureTypes, SetFeatureTypes] = useState<any>([]);
    const [addedConfig, SetAddedConfig] = useState<any>([]);
    const [editData, SeteditData] = useState<any>([]);
    const [variables, Setvariables] = useState<any>([]);
    const dispatch = useDispatch();

    useEffect(() => {
        console.log(props)
        fetchIntialData();
    }, [])

    /**
     * get chart types
     * get variables data
     * find previous data variables details and update the state SetAddedConfig 
     */
    const fetchIntialData = async () => {
        try {
            dispatch(setLoading(true));
            let response = await getAnalysisChartTypes();
            
            SetChartTypes(response.map(({
                key: value,
                name: label
            }) => ({
                value,
                label
            })))


            getPreviousData(response.map(({
                key: value,
                name: label
            }) => ({
                value,
                label
            })))

            dispatch(setLoading(false));
        } catch (e: any) {
            dispatch(setLoading(false));
        }
    }


    /**
     * set inital data if exist
     * rearrange the array in to format required for setting previous values
     */
    const getPreviousData = async (datachart) => {
        let responseVariables = await getAnalysisVariables(data?.formData?.id);
        let responseArray: any = [];
        if (previousData?.configs && previousData?.configs.length > 0) {
            previousData?.configs.map((items, i) => {
                let outComeValue = responseVariables.data.filter(item => item.id == items.outcome_fields[0].field_id)
                let explanatoryValue: any = [];
                let splitOnValue: any = [];

                items && items.explanatory_fields.map(expItem => {

                    let explanatoryValueItem = responseVariables.data.filter(itemexp => itemexp.id == expItem.field_id)
                    explanatoryValue.push({
                        "label": explanatoryValueItem[0]?.field_name,
                        "value": explanatoryValueItem[0]?.field_name,
                        "id": explanatoryValueItem[0]?.id
                    })

                })

                /**
                 * chart data for multi data
                 */
                let chartTypes: any = [];
                items.chart_type && items.chart_type?.map(item => {
                    let chart = datachart.filter(chartItem => chartItem.value == item)
                    chartTypes.push(chart[0])
                })

                console.log(chartTypes)

                let chart = datachart.filter(chartItem => chartItem.value == items.chart_type)
                let Obj = {
                    id: i + 1,
                    charttype: chartTypes,
                    outcomeVar: [{
                        "label": outComeValue[0]?.field_name,
                        "value": outComeValue[0]?.field_name,
                        "id": outComeValue[0]?.id
                    }],
                    expoVariable: explanatoryValue,
                    formula: items.formulas && items.formulas,
                    spliton: items.spliton && items.spliton || [],
                    feature: items.features && items.features

                }
                responseArray.push(Obj)
            })

            SetAddedConfig(responseArray)
        }
    }
    const onClose = () => {
        SetpopupType("")
    }

    const onAddOutcomeVaraible = (data) => {
        console.log(data)
        if(variableType == "outcome"){
            SetOutcomeVariable(data)
        }else{
            SetSplitonTypes(data)
        }
    }

    /**
     * on Add action set variables to respective states
     * @param data 
     */
    const onAddExplantoryVaraible = (data) => {
        console.log(data)
        if (variableType == "formula") {
            SetFormulaTypes(data)
        } 
        // else if (variableType == "spliton") {
        //     SetSplitonTypes(data)
        // } 
        else if (variableType == "feature") {
            SetFeatureTypes(data)
        } else {
            SetexplanatoryVariable(data)
        }
    }

    /**
     * remove selected items 
     * @param itemRemove 
     * @param type 
     */
    const removeItem = (itemRemove, type) => {
        if (type == "formula") {
            let removedVariable = formulaTypes && formulaTypes.filter(item => item != itemRemove);
            SetFormulaTypes(removedVariable)
        } else if (type == "spliton") {
            let removedVariable = splitonTypes && splitonTypes.filter(item => item != itemRemove);
            SetSplitonTypes(removedVariable)
        } else if (type == "feature") {
            let removedVariable = featureTypes && featureTypes.filter(item => item != itemRemove);
            SetFeatureTypes(removedVariable)
        } else {
            let removedVariable = explanatoryVariable && explanatoryVariable.filter(item => item != itemRemove);
            SetexplanatoryVariable(removedVariable)
        }

    }

    const configChart = () => {
        SetisChart(true)
    }

    /**
   * handle on change values of form elements
   * @param name 
   * @param value 
   * @param values 
   * @param fieldValues 
   * @returns 
   */
    function onValueChange(name, value, values, fieldValues = null) {
        const resetValue = {}
        console.log(values)

        return [{
            ...values,
            ...resetValue,
            [name]: value,
        }]
    }

    /**
     * on add click
     */
    const addData = () => {
        if (values.chart && outcomeVariable && explanatoryVariable.length != 0) {
            if (editData.length > 0) {
                console.log(addedConfig)

                // let updateData = addedConfig && addedConfig.filter(item => item.id == editData[0].id);
                let Obj = {
                    id: editData[0].id,
                    charttype: values.chart,
                    outcomeVar: outcomeVariable,
                    expoVariable: explanatoryVariable,
                    formula: formulaTypes,
                    spliton: splitonTypes,
                    feature: featureTypes,
                    combined: values.combineddata || false
                }
                SetAddedConfig([...addedConfig, Obj]);

            } else {
                let id = addedConfig && addedConfig.length + 1 || 1;
                console.log(addedConfig)
                let Obj = {
                    id: id,
                    charttype: values.chart,
                    outcomeVar: outcomeVariable,
                    expoVariable: explanatoryVariable,
                    formula: formulaTypes,
                    spliton: splitonTypes,
                    feature: featureTypes,
                    combined: values.combineddata || false
                }
                SetAddedConfig([...addedConfig, Obj]);
            }

            updateValue({ chart: [] });
            SetOutcomeVariable([]);
            SetexplanatoryVariable([]);
            SetFeatureTypes([]);
            SetFormulaTypes([]);
            SetSplitonTypes([]);
            SeteditData([]);
        } else {
            showToaster(toasterTypes.ERROR, "Fields cannot be empty")
        }
    }

    /**
     * delete config
     */
    const removeConfigItem = (id) => {
        let removedData = addedConfig && addedConfig.filter(item => item.id != id);
        SetAddedConfig(removedData);
    }

    /**
     * edit config
     */
    const editConfigItem = (id) => {
        let editData = addedConfig && addedConfig.filter(item => item.id == id);
        console.log(editData)
        SeteditData(editData)
        updateValue({ chart: editData[0].charttype })
        SetOutcomeVariable(editData[0].outcomeVar)
        SetexplanatoryVariable(editData[0].expoVariable)
        SetFormulaTypes(editData[0].formula ? editData[0].formula : [])
        SetSplitonTypes(editData[0].spliton ? editData[0].spliton : [])
        SetFeatureTypes(editData[0].feature ? editData[0].feature : [])
        let updateData = addedConfig && addedConfig.filter(item => item.id != id);
        SetAddedConfig(updateData);
    }

    /**
     * save configuration
     */
    const saveConfiguration = async () => {
        try {
            dispatch(setLoading(true))
            let responseOutcome = await getOutcomeVariable(props.id);
            let configArray: any = [];
            addedConfig && addedConfig.map((item, index) => {
                let id = index + 1;
                let outcomeArray: any = [];
                let explanatoryArray: any = [];
                outcomeArray.push({ "field_id": item.outcomeVar[0].id });
                item.expoVariable.map(expoItem => {
                    explanatoryArray.push({
                        "field_id": expoItem.id
                    })
                })
                let chartTemp: any = [];
                item.charttype.map((item, i) => {
                    chartTemp.push(item.value)
                })

                console.log(item)
                configArray.push({
                    "name": "config-" + id,
                    "outcome_fields": outcomeArray,
                    "explanatory_fields": explanatoryArray,
                    "chart_type": chartTemp,
                    "formulas": item.formula || [],
                    "spliton": item.spliton || [],
                    "features": item.feature || [],
                    "combined": item?.combined && item?.combined[0] || false
                })
            })
            let response = {
                "name": responseOutcome.name,
                "topic_id": responseOutcome.topic_id,
                "type": responseOutcome.type,
                "group_id": responseOutcome.group_id,
                "form_id": responseOutcome.form_id,
                "fields": responseOutcome.fields,
                "configs": configArray
            }

            console.log(configArray)
            let responseUpdate = await updateSelectedConfig(props.id, response)
            if (responseUpdate) {
                showToaster(toasterTypes.SUCCESS, "Configurations Updated Successfully");
            }
            dispatch(setLoading(false))
        } catch (err: any) {
            dispatch(setLoading(false))
            console.log(err)
        }
    }
    return (
        <>

            {/* <ConfigChart/> */}

            {
                popupType == "variable" ?
                    <VariableModal
                        onClose={onClose}
                        data={props.data}
                        id={props.id}
                        outcomeSelected={outcomeVariable.length > 0 ? outcomeVariable[0]['value'] : ""}
                        getData={onAddOutcomeVaraible}
                        getExplanatory={onAddExplantoryVaraible}
                        variabletype={variableType}
                        selectedValues={variableType == "formula" ? formulaTypes : variableType == "spliton" ? splitonTypes : variableType == "feature" ? featureTypes : explanatoryVariable} />
                    : ""
            }
            <div className="add-configuration-container">
                <div className="ml-3 mt-2 p-1 config-container">
                    <Row className="mr-2 ml-2" >
                        <Col md={11}>
                            <Row>
                                <Col md={4}>
                                    {outcomeVariable.length == 0 ?
                                        <div className="outcome-variable" onClick={() => { SetpopupType("variable"); SetVaraibleType("outcome"); }}>
                                            <i className="icon icon-add"></i>
                                            Outcome Variable
                                        </div>
                                        :
                                        <>
                                            <label style={{ fontSize: "12px" }}>Outcome</label>
                                            <div className="outcome-variable">
                                                <Row>
                                                    <Col md={8}>
                                                        <span style={{ color: "#000" }}>{outcomeVariable && outcomeVariable[0]['value']}</span>
                                                    </Col>
                                                    <Col md={2}>
                                                        <i className="icon icon-close" style={{ color: "red" }} onClick={() => SetOutcomeVariable([])} />
                                                    </Col>
                                                </Row>
                                            </div>
                                        </>
                                    }
                                </Col>
                                <Col md={4}>
                                    {!explanatoryVariable.length ?
                                        <div className="explanatory-variable"
                                            onClick={() => {
                                                if (outcomeVariable.length) {
                                                    SetpopupType("variable")
                                                    SetVaraibleType("explanatory")
                                                } else {
                                                    showToaster(toasterTypes.INFO, "Select Outcome variable")
                                                }
                                            }}>
                                            <i className="icon icon-add"></i>
                                            Explanatory Variable
                                        </div>
                                        :
                                        <>
                                            <label style={{ fontSize: "12px" }}>Explanatory</label>
                                            <div className="explanatory-variable">
                                                <Row>
                                                    <Col md={10} className="exp-variable">
                                                        {
                                                            explanatoryVariable && explanatoryVariable.map((item, i) => {
                                                                return <div className="variable-exp" key={i}>
                                                                    {item.label}
                                                                    <i className="icon icon-close" style={{ color: "red", padding: "0px 5px" }} onClick={() => removeItem(item, "")} />
                                                                </div>
                                                            })
                                                        }

                                                    </Col>
                                                    <Col md={2}>
                                                        <i className="icon icon-add" style={{ color: "red" }} onClick={() => { SetpopupType("variable"); SetVaraibleType("explanatory"); }} />
                                                    </Col>
                                                </Row>
                                            </div>
                                        </>
                                    }
                                </Col>
                                <Col md={4}>
                                    <div className="chart">
                                        <FieldItem
                                            name={"chart"}
                                            value={values["chart"]}
                                            values={outcomeVariable.length > 0 && explanatoryVariable.length > 0 ?
                                                outcomeVariable[0]?.type == "number" && explanatoryVariable[0]?.type == "number" ?
                                                    chartTypes.filter(item => item.value == "boxplot" || item.value == "bar" || item.value == "scatterplot")
                                                    :
                                                    outcomeVariable[0]?.type != "number" && explanatoryVariable[0]?.type == "number" ?
                                                        chartTypes.filter(item => item.value == "boxplot" || item.value == "bar")
                                                        :
                                                        outcomeVariable[0]?.type == "number" && explanatoryVariable[0]?.type != "number" ?
                                                            chartTypes.filter(item => item.value == "boxplot" || item.value == "bar" || item.value == "errorbar")
                                                            :
                                                            outcomeVariable[0]?.type != "number" && explanatoryVariable[0].type != "number" ?
                                                                chartTypes.filter(item => item.value == "stackedbarchart" || item.value == "clusterBarchart" || item.value == "horizontalbarchart" || item.value == "errorbar")
                                                                :
                                                                []
                                                : []
                                            }
                                            label={"Chart Type"}
                                            type={FIELD_TYPES.MULTI_SELECT}
                                            onChange={(...e) => handleChange("chart", ...e)}
                                        />
                                    </div>
                                </Col>
                                {/* commented for time being */}
                                {/* <Col md={4}>
                                    {!formulaTypes.length ?
                                        <div className="explanatory-variable" onClick={() => { SetpopupType("variable"); SetVaraibleType("formula"); }}>
                                            <i className="icon icon-add"></i>
                                            Formulas
                                        </div>
                                        :
                                        <>
                                            <label style={{ fontSize: "12px" }}>Formula</label>
                                            <div className="formula-variable">
                                                <Row>

                                                    <Col md={10} className="exp-variable">
                                                        {
                                                            formulaTypes && formulaTypes.map((item, i) => {
                                                                return <div className="variable-exp" key={i}>
                                                                    {item}
                                                                    <i className="icon icon-close" style={{ color: "red", padding: "0px 5px" }} onClick={() => removeItem(item, "formula")} />
                                                                </div>
                                                            })
                                                        }

                                                    </Col>
                                                    <Col md={2}>
                                                        <i className="icon icon-add" style={{ color: "red" }} onClick={() => { SetpopupType("variable"); SetVaraibleType("formula"); }} />
                                                    </Col>
                                                </Row>
                                            </div>
                                        </>
                                    }
                                </Col> */}
                                <Col md={4}>
                                    {!splitonTypes.length ?
                                        <div className="explanatory-variable" onClick={() => { SetpopupType("variable"); SetVaraibleType("spliton"); }}>
                                            <i className="icon icon-add"></i>
                                            Split On
                                        </div>
                                        :
                                        <>
                                            <label style={{ fontSize: "12px" }}>Split On</label>
                                            <div className="formula-variable">
                                                <Row>
                                                    <Col md={10} className="exp-variable">
                                                        {
                                                                 <div className="variable-exp" >
                                                                    {splitonTypes && splitonTypes[0].label}
                                                                    <i className="icon icon-close" style={{ color: "red", padding: "0px 5px" }} onClick={() => removeItem(splitonTypes && splitonTypes[0].label, "spliton")} />
                                                                </div>
                                                            
                                                        }

                                                    </Col>
                                                    <Col md={2}>
                                                        <i className="icon icon-add" style={{ color: "red" }} onClick={() => { SetpopupType("variable"); SetVaraibleType("spliton"); }} />
                                                    </Col>
                                                </Row>
                                            </div>
                                        </>
                                    }
                                </Col>
                                <Col md={4}>
                                    {!featureTypes.length ?
                                        <div className="explanatory-variable" onClick={() => { SetpopupType("variable"); SetVaraibleType("feature"); }}>
                                            <i className="icon icon-add"></i>
                                            Feature
                                        </div>
                                        :
                                        <>
                                            <label style={{ fontSize: "12px" }}>Feature</label>
                                            <div className="formula-variable">
                                                <Row>
                                                    <Col md={10} className="exp-variable">
                                                        {
                                                            featureTypes && featureTypes.map((item, i) => {
                                                                return <div className="variable-exp" key={i}>
                                                                    {item}
                                                                    <i className="icon icon-close" style={{ color: "red", padding: "0px 5px" }} onClick={() => removeItem(item, "feature")} />
                                                                </div>
                                                            })
                                                        }
                                                    </Col>
                                                    <Col md={2}>
                                                        <i className="icon icon-add" style={{ color: "red" }} onClick={() => { SetpopupType("variable"); SetVaraibleType("feature"); }} />
                                                    </Col>
                                                </Row>
                                            </div>
                                        </>
                                    }
                                </Col>
                                <Col md={4}>
                                    <FieldItem
                                        name={"combineddata"}
                                        value={values["combineddata"]}
                                        values={[{ label: "Show combined table", value: true }]}
                                        type={FIELD_TYPES.CHECK_BOX}
                                        onChange={(...e) => handleChange("combineddata", ...e)}
                                    />
                                </Col>
                            </Row>
                        </Col>
                        <Col md={1}>
                            <div className="add" >
                                <Button className="add-btn" onClick={() => addData()}>
                                    Add
                                </Button>

                            </div>
                        </Col>
                    </Row>
                </div>
                <div className="ml-5 p-2 selector-card">
                    {
                        addedConfig && addedConfig?.map((item, index) => {
                            return <ConfigSelectorCard key={index} index={index + 1} configChartData={item} removeItem={removeConfigItem} editItem={editConfigItem} />
                        })
                    }
                </div>

                <div className="pr-3 pb-3 save-data">
                    {

                        addedConfig && addedConfig.length > 0 ?
                            <CustomButton type={"primary"} text={"Save Configuration"} onClick={() => saveConfiguration()} />
                            :
                            ""
                    }
                </div>

            </div>
        </>
    )
}