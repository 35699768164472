import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Button } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { Col, Row } from "reactstrap";
import { setLoading } from "../../store/slices/auth";
import { copyData } from "../../utils/Utils";
import { CustomButton, showToaster, toasterTypes } from "../../widgets";
import { FieldItem, FIELD_TYPES } from "../../widgets/fields";
import useFieldItem from "../../widgets/fields/UseFieldItem";
import ModalPopup from "../../widgets/modal";
import CustomTable from "../../widgets/table";
import Tabs from "../sampleSize/Tabs";
import { createForm, deletForm, deleteGroup, getFormData, getFormTemplates, getForms, getGroups, postFormCopy, postFormTemplates, updateRearrangedData, updateStrictMode } from "./apiController";
import CreateForm from "./CreateForm";
import ViewFormData from "./ViewFormData";
import { ArrowLeftOutlined, BackwardOutlined, CopyOutlined, LockOutlined, UnlockOutlined } from "@ant-design/icons";
import ModalComponent from "../common/Modal/modal";
import CreateCriteria from "./CreateCriteria";
import FormDataCardMobile from "./FormDataCardMobile";
import AskQuestions from "./AskQuestions";
import SectionModal from "../Editor/utils/SectionModal";
import CopyTooltip from "../Editor/utils/CopyTooltip";
import PushToEditor from "../Editor/utils/PushToEditor";
import { TabsData } from "../../utils/TabsData";
import CreateGroup from "./CreateGroup";
import { get, set } from "lodash";
import { use } from "i18next";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import TableSkeleton from "../common/skeletons/TableSkeleton";
import ButtonSkeleton from "../common/skeletons/ButtonSkeleton";


export default function DataCollection(props) {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [projectData, setprojectData] = useState<any | null>(null);
  const [userData, setuserData] = useState<any | null>(null);
  const [groups, setgroups] = useState<any | null>([]);
  const [selectedGroup, setselectedGroup] = useState<any | null>(null);
  const [selectedForm, setselectedForm] = useState<any | null>(null);
  const [forms, setforms] = useState<any | null>([]);
  const [action, setaction] = useState<any | "">("");
  const [lockData, SetLockData] = useState<any>({});
  const [formData, setformData] = useState<any | null>(null);
  const [groupData, setgroupData] = useState<any | null>(null);
  const [formCount, SetFormCount] = useState(0);
  const [permissionParams, SetPermissionParams] = useState<any>({});
  const [formTemplates, setFormTemplates] = useState<any>([]);
  const [isRearranged, setIsRearranged] = useState(false);
  const [selectedText, setSelectedText] = useState('');
  const [tooltipStyle, setTooltipStyle] = useState({});
  const [showTooltip, setShowTooltip] = useState(false);
  const [selectedRecords, SetSelectedRecords] = useState<any>("");
  const [deleteId, setDeleteId] = useState<any>("");

  /**
 * function call to Push code to editor
 */
  const addToEditor = (data) => {
    console.log(data)
    let Obj = {
      "type": "paragraph",
      "attrs": {
        "id": "",
        "class": "paragraph",
        "track": [],
        "group": "",
        "viewid": ""
      },
      "content": [
        {
          "type": "text",
          "text": selectedText
        }
      ]
    };
    let response = PushToEditor(Obj, data.section.value)
    if (response) {
      showToaster(toasterTypes.SUCCESS, "Pushed code to editor")
    }
    setaction("")

  }

  useEffect(() => {
    // Attach the mouseup event listener to the document
    document.addEventListener('mouseup', getSelectedText);

    // Clean up the event listener on component unmount
    return () => {
      document.removeEventListener('mouseup', getSelectedText);
    };
  }, [selectedText]);

  /**
   * selected text
   * @param data 
   */
  const getSelectedText = () => {
    const selection: any = window.getSelection();
    if (selection.toString().trim()) {
      const range = selection.getRangeAt(0);
      const rect = range.getBoundingClientRect();

      // Update the state with the selected text and tooltip position
      setSelectedText(selection.toString());
      setTooltipStyle({
        top: `${rect.top + window.scrollY + rect.height - 50}px`,
        left: `${rect.left + window.scrollX - 100}px`,
      });

      setShowTooltip(true);
    } else {
      setShowTooltip(false);
    }
  };

  const sectionAction = () => {
    setaction("section")
  }



  /**
   * form elements
   */
  const FormElements = {
    search: {
      name: "search",
      placeholder: "Search",
      label: "",
      type: FIELD_TYPES.SEARCH,
      isMandatory: false,
    },
    lockmode: {
      name: "lockmode",
      placeholder: "Please enter remarks",
      label: "Remarks to Unlock",
      type: FIELD_TYPES.TEXT_AREA,
      isMandatory: true,
    },
    formTemplate: {
      name: "formTemplate",
      placeholder: "Select",
      label: "Select Template",
      type: FIELD_TYPES.DROP_DOWN,
      isMandatory: false
    },
    formName: {
      name: "formName",
      placeholder: "Form Name",
      label: "New Form Name",
      type: FIELD_TYPES.TEXT,
      isMandatory: true
    },
    cloneType: {
      name: "cloneType",
      placeholder: "Clone Type",
      label: "Clone Type",
      type: FIELD_TYPES.DROP_DOWN,
      values: [{ label: "Soft Clone", value: "softclone" }, { label: "Deep Clone", value: "deepclone" }],
      isMandatory: true
    },
    formTypes: {
      name: "formType",
      placeholder: "Form Type",
      label: "",
      type: FIELD_TYPES.RADIO_BUTTON,
      values: [{ label: "Create Form", value: "createform" },
      // { label: "Create using predefined Template", value: "createtemplate" },
      { label: "Create using Template Group", value: "createtemplate" }
        // { label: "Clone Existing", value: "createclone" }
      ],
      isMandatory: true
    },
    groupType: {
      name: "groupType",
      placeholder: "Group",
      label: "Group",
      type: FIELD_TYPES.DROP_DOWN,
      isMandatory: false
    },
    cumformName: {
      name: "cumformName",
      placeholder: "Cumulative Form Name",
      label: "Cumulative Name",
      type: FIELD_TYPES.TEXT,
      isMandatory: true
    },
  };

  /**
    * fetch form templates and update the state
    * 
    */
  const fetchTemplates = async () => {
    try {
      dispatch(setLoading(true));
      setFormTemplates([])
      let tempTemplates = await getFormTemplates("GOF");
      tempTemplates.data.map(item => {
        setFormTemplates(prevState => [...prevState, { label: item.templatename, value: item.ref_id }])
      })
      dispatch(setLoading(false));
    } catch (error) {
      dispatch(setLoading(false));
    }
  }

  /**
   * argument permissions data
   * @param data 
   */
  const getPermissions = async (data) => {
    console.log(data.permissions)
    await data.permissions && data.permissions.map(items => {
      if (Object.keys(items)[0].replace(/([^\w]+|\s+)/g, '') == "Tabs") {
        items[Object.keys(items)[0]].map(item => {
          if (item.permissions.filter(itm => itm.title == "allowed" && itm.is_selected == true).length != 0) {
            if (item.action == "project details ,") {
              SetPermissionParams(prevState => ({ ...prevState, projectdetail: "allowed" }))
            } else if (item.action == "study design,") {
              SetPermissionParams(prevState => ({ ...prevState, studydesign: "allowed" }))
            } else if (item.action == "Data collection,") {
              SetPermissionParams(prevState => ({ ...prevState, datacollection: "allowed" }))
            } else if (item.action == "Data analysis,") {
              SetPermissionParams(prevState => ({ ...prevState, dataanalysis: "allowed" }))
            } else if (item.action == "mentors,") {
              SetPermissionParams(prevState => ({ ...prevState, mentors: "allowed" }))
            } else if (item.action == "register events") {
              SetPermissionParams(prevState => ({ ...prevState, registerevents: "allowed" }))
            } else if (item.action == "Planner") {
              SetPermissionParams(prevState => ({ ...prevState, registerevents: "allowed" }))
            }
          } else {
            if (item.action == "project details ,") {
              SetPermissionParams(prevState => ({ ...prevState, projectdetail: "forbidden" }))
            } else if (item.action == "study design,") {
              SetPermissionParams(prevState => ({ ...prevState, studydesign: "forbidden" }))
            } else if (item.action == "Data collection,") {
              SetPermissionParams(prevState => ({ ...prevState, datacollection: "forbidden" }))
            } else if (item.action == "Data analysis,") {
              SetPermissionParams(prevState => ({ ...prevState, dataanalysis: "forbidden" }))
            } else if (item.action == "mentors,") {
              SetPermissionParams(prevState => ({ ...prevState, mentors: "forbidden" }))
            } else if (item.action == "register events") {
              SetPermissionParams(prevState => ({ ...prevState, registerevents: "forbidden" }))
            } else if (item.action == "Planner") {
              SetPermissionParams(prevState => ({ ...prevState, registerevents: "forbidden" }))
            }
          }

        })
      }
      if (Object.keys(items)[0] == "Forms") {

        items.Forms.map(item => {
          if (item.permissions.filter(itm => itm.title == "allowed" && itm.is_selected == true).length != 0) {
            if (item.action == "delete form") {
              SetPermissionParams(prevState => ({ ...prevState, delete: "allowed" }))
            } else if (item.action == "edit form") {
              SetPermissionParams(prevState => ({ ...prevState, edit: "allowed" }))
            } else if (item.action == "update form button") {
              SetPermissionParams(prevState => ({ ...prevState, update: "allowed" }))
            } else if (item.action == "view data") {
              SetPermissionParams(prevState => ({ ...prevState, view: "allowed" }))
            } else if (item.action == "Add data button") {
              SetPermissionParams(prevState => ({ ...prevState, adddata: "allowed" }))
            } else if (item.action == "create forms button") {
              SetPermissionParams(prevState => ({ ...prevState, createform: "allowed" }))
            } else if (item.action == "survey link button") {
              SetPermissionParams(prevState => ({ ...prevState, surveylink: "allowed" }))
            } else if (item.action == "Criteria") {
              SetPermissionParams(prevState => ({ ...prevState, criteria: "allowed" }))
            }
          } else {
            if (item.action == "delete form") {
              SetPermissionParams(prevState => ({ ...prevState, delete: "forbidden" }))
            } else if (item.action == "edit form") {
              SetPermissionParams(prevState => ({ ...prevState, edit: "forbidden" }))
            } else if (item.action == "update form button") {
              SetPermissionParams(prevState => ({ ...prevState, update: "forbidden" }))
            } else if (item.action == "view data") {
              SetPermissionParams(prevState => ({ ...prevState, view: "forbidden" }))
            } else if (item.action == "Add data button") {
              SetPermissionParams(prevState => ({ ...prevState, adddata: "forbidden" }))
            } else if (item.action == "create forms button") {
              SetPermissionParams(prevState => ({ ...prevState, createform: "forbidden" }))
            } else if (item.action == "survey link button") {
              SetPermissionParams(prevState => ({ ...prevState, surveylink: "forbidden" }))
            } else if (item.action == "Criteria") {
              SetPermissionParams(prevState => ({ ...prevState, criteria: "forbidden" }))
            }
          }

        })
      }

    })
  }



  useEffect(() => {

  }, [permissionParams])

  const [values, fields, handleChange, { validateValues, updateValue }] = useFieldItem(FormElements, {}, { onValueChange });


  /**
 * handle on change values of form elements
 * @param name 
 * @param value 
 * @param values 
 * @param fieldValues 
 * @returns 
 */
  function onValueChange(name, value, values, fieldValues = null) {
    const resetValue = {}
    console.log(values)
    if (name == "formType") {
      setaction(value)
    } else if (name == "groupType") {
      let index = groups.findIndex(item => item.id === value.id);
      fetchForms(index)
    }
    return [{
      ...values,
      ...resetValue,
      [name]: value,
    }]
  }


  /**
   * table header
   */
  const columns = [
    {
      id: "form_name",
      label: "Form Name",
      accessor: "form_name",
      sort: "asc",
    },
    {
      id: "total_responses",
      label: "Total Records",
      Cell: ({ row, data }) => {
        const { original } = row;
        return (
          permissionParams.adddata == "allowed" || Object.keys(permissionParams).length == 0 ?
            <>
              <span>{original.total_responses}</span>
              {/* {!original.survey_form && ( */}
              <span className="add-icon" onClick={() => addData(original)}>
                <i className="icon icon-add_circle"></i>
                <span className="icon-text">Add</span>
              </span>
              {/* )} */}
            </>
            : ""
        );
      },
    },
    {
      id: "variables",
      label: "Variables",
      width: "50",
      className: "text-center",
      Cell: ({ row, data }) => {
        const { original } = row;
        return (
          permissionParams.update == "allowed" || Object.keys(permissionParams).length == 0 ?
            !original?.permission || original?.permission == "write" ?
              !original.locked ?
                <span className="icon-text" onClick={() => addVariable(original)}>Update</span>
                : "-"
              : ""
            : ""
        );
      },
    },
    {
      id: "survey",
      label: "Survey",
      width: "50",
      className: "text-center",
      Cell: ({ row, data }) => {
        const { original } = row;
        return permissionParams.surveylink == "allowed" || Object.keys(permissionParams).length == 0 ? original.survey_form ? (
          <i
            className="icon-copy"
            onClick={() => onAction(5, original)}
          ></i>
        ) : "" : (
          <>-</>
        );
      },
    },
    {
      id: "strict",
      label: "IsLocked",
      width: "20",
      className: "text-center",
      Cell: ({ row, data }) => {
        const { original } = row;
        return (
          projectData?.strict_mode == true ?
            original.locked ?
              <i className="icon icon-lock" onClick={() => onAction("unlock", original)}></i>
              :
              <i className="icon icon-lock_open" onClick={() => onAction("lock", original)}></i>
            : "-"
        );
      },
    },
    {
      id: "askquestion",
      label: "Ask Question",
      width: "20",
      className: "text-center",
      Cell: ({ row, data }) => {
        const { original } = row;
        return (
          <i
            className="icon icon-question"
            onClick={() => onAction("question", original)}
          ></i>

        );
      },
    },
    {
      id: "view",
      label: "View",
      width: "20",
      className: "text-center",
      Cell: ({ row, data }) => {
        const { original } = row;
        return (
          permissionParams.view == "allowed" || Object.keys(permissionParams).length == 0 ?
            <i
              className="icon icon-eye"
              onClick={() => onAction(1, original)}
            ></i>
            : ""

        );
      },
    },
    {
      id: "edit",
      label: "Edit",
      width: "20",
      className: "text-center",
      Cell: ({ row, data }) => {
        const { original } = row;
        return (
          permissionParams.edit == "allowed" || Object.keys(permissionParams).length == 0 ?
            !original?.permission || original?.permission == "write" ?
              !original.locked ?
                <i
                  className="icon icon-edit"
                  onClick={() => onAction(2, original)}
                ></i>
                : "-"
              : ""
            : ""
        );
      },
    },
    {
      id: "delete",
      label: "Delete",
      width: "30",
      className: "text-center",
      Cell: ({ row, data }) => {
        const { original } = row;
        return (
          permissionParams.delete == "allowed" || Object.keys(permissionParams).length == 0 ?
            !original?.permission || original?.permission == "write" ?
              !original.locked ?
                <i
                  className="icon icon-delete"
                  onClick={() => onAction(3, original)}
                ></i>
                : "-"
              : ""
            :
            ""
        );
      },
    },
    {
      id: "duplicate",
      label: "Clone Form",
      width: "110",
      className: "text-center",
      Cell: ({ row, data }) => {
        const { original } = row;
        return (
          <>
            <CopyOutlined onClick={() => onAction("duplicate", original)} />
          </>
        );
      },
    }
  ];

  useEffect(() => {
    dispatch(setLoading(true));
    fetchTemplates();
    console.log(location.state)
    if (location.state) {
      let tempState: any = location.state;
      console.log(tempState)
      getPermissions(tempState);
      setprojectData(tempState);
      let temp: any = localStorage.getItem("coguide_user");
      if (temp) {
        temp = JSON.parse(temp);
        setuserData(temp);
        fetchGroups(tempState.TopicID || tempState.TopicCode);
      }
    } else {
      navigate("/project");
    }
  }, [location.state, navigate]);


  const addVariable = (data) => {
    data.formsList = forms;
    data.projectData = projectData;
    navigate("/addVariables", { state: { data, projectData } });
  };

  const addData = (data) => {
    data.formsList = forms;
    data.projectData = projectData;
    data.isPartialModify = false;
    navigate("/dataEntry", { state: { data, projectData } });
  };

  /**
   * get list of groups
   * @param id 
   */
  const fetchGroups = async (id) => {
    try {
      dispatch(setLoading(true));
      let tempGroups = await getGroups(id);
      tempGroups?.data.forEach((obj) => {
        obj.label = obj.name;
        obj.value = obj.id;
      });
      setgroups(tempGroups?.data || []);
      let groupIndex = tempGroups?.data.findIndex((obj) => obj.is_default);
      if (groupIndex >= 0) {
        fetchForms(groupIndex, tempGroups?.data[groupIndex].id);
      } else {
        fetchForms(0, tempGroups?.data[0].id);
      }
      dispatch(setLoading(false));
    } catch (e: any) {
      setgroups([]);
      dispatch(setLoading(false));
    }
  };

  /**
   * form list
   * @param index 
   * @param id 
   */
  const fetchForms = async (index, id = "") => {
    try {
      dispatch(setLoading(true));
      let tempForms = await getForms(id || groups[index].id);
      setforms(tempForms || []);
      setselectedGroup(index);
      updateValue({ groupType: groups[index] })
      dispatch(setLoading(false));
    } catch (e: any) {
      setgroups([]);
      dispatch(setLoading(false));
    }
  };

  /**
   * actions for button clicks
   * @param type 
   * @param data 
   */
  const onAction = async (type, data: any = null) => {
    // console.log(type, data);
    switch (type) {
      case 1:
        if (data?.total_responses === 0) {
          showToaster(toasterTypes.ERROR, "No Records Found");
        } else {
          dispatch(setLoading(true));
          let response = await getFormData(data?.id);
          setformData(response?.data);
          SetFormCount(data.total_responses);
          dispatch(setLoading(false));
          setaction(type);
        }
        break;
      case 2:
        try {
          dispatch(setLoading(true));
          let response = await getFormData(data?.id);
          setformData(response?.data);
          dispatch(setLoading(false));
          console.log(type)
          setaction(type);
        } catch (e: any) {
          setgroups([]);
          dispatch(setLoading(false));
        }
        break;
      case 3:
        setformData(data);
        setaction(type);
        break;
      case 5:
        copyData(
          `${window.location.href}?topicId=${projectData.TopicID || projectData.TopicCode
          }&formId=${data?.id}`,
          true
        );
        showToaster(toasterTypes.SUCCESS, "Link Copied Successfully");
        break;

      case 19:
        if (Object.keys(data.selectedRowIds).length > 0) {
          SetSelectedRecords(Object.keys(data.selectedRowIds));
          setaction("cumulative")
        } else {
          showToaster(toasterTypes.ERROR, "Please select forms to create")
        }
        break;
      case "unlock":
        SetLockData(data)
        setaction(type);
        break;
      case "lock":
        updateStrictModeAction(data, "lock")
        break;
      case 8:
        navigate("/dataanalysis", { state: projectData })
        break;
      case 15:
        onSaveRearrangeData();
        break;
      case "duplicate":
        setselectedForm(data.id)
        setaction("createclone")
        break;
      case "question":
        setselectedForm(data.id)
        setaction("question")
        break;
      default:
        setaction(type);
        break;
    }
  };

  /**
   * get selected forms create structure and create cumulative form
   */
  const createCumulativeForm = async () => {
    console.log(forms)
    let idArray: any = [];
    selectedRecords.map(item => {
      idArray.push(forms[item].id)
    })

    console.log(values)
    let Obj = {
      "topicid": groups[selectedGroup].topicid,
      "form_name": values.cumformName,
      "form_desc": values.cumformName,
      "form_type": "cumulative",
      "form_group_id": groups[selectedGroup].id,
      "form_group": "Default",
      "survey_form": false,
      "form_ids": idArray,
      "form_role_access": [
      ]
    };

    try {
      let cumultiveFormResponse = await createForm(Obj);
      if (cumultiveFormResponse) {
        showToaster(toasterTypes.SUCCESS, "Cumulative form created")
        updateValue({ cumformName: "" })
        setaction("")
        fetchForms(selectedGroup, groups[selectedGroup].id);

      }
    } catch (error) {

    }

    console.log(Obj)

  }
  /**
   * on From crete get all the forms list
   */
  const onFormCreation = () => {
    setaction("");
    setforms([]);
    fetchForms(selectedGroup);
  };

  /**
  * on Group crete get all the forms list
  */
  const onGroupCreation = () => {
    setaction("");
    setforms([]);
    let tempState: any = location.state;
    let temp: any = localStorage.getItem("coguide_user");
    if (temp) {
      temp = JSON.parse(temp);
      setuserData(temp);
      fetchGroups(tempState.TopicID || tempState.TopicCode);
    }
  };

  //delete action
  const onDelete = async () => {
    try {
      dispatch(setLoading(true));
      let response = await deletForm(formData?.id);
      setaction("");
      fetchForms(selectedGroup);
      showToaster(toasterTypes.SUCCESS, response.message);
    } catch (e: any) {
      setgroups([]);
      dispatch(setLoading(false));
    }
  };

  //onclose
  const onCloseView = (type = "") => {
    // if (type === "forms") {
    //   onFormCreation();
    // }
    setaction("");
  };

  /**
   * unlock and lock
   * on lock update the status of form to strict mode true
   * on unlock update the status to false with remarks
  */
  const updateStrictModeAction = async (data, type) => {
    try {
      dispatch(setLoading(true))
      let id = data["id"];
      let datavalue = {
        remark: type == "lock" ? " " : values.lockmode
      }
      let UpdateResponse = await updateStrictMode(id, datavalue);
      if (UpdateResponse) {
        onFormCreation();
      } else {
        dispatch(setLoading(false))
      }
      dispatch(setLoading(false))
    } catch (e: any) {
      throw e
      dispatch(setLoading(false))
    }
  }

  /**
   * on save create form using template
   */
  const onSaveFormTempalte = async () => {
    try {
      dispatch(setLoading(true));
      let TempObj = {
        "name": values.formTemplate?.label,
        "type": "GOF",
        "group_id": groups[selectedGroup].id,
        "topic_id": groups[selectedGroup].topicid,
        "template_id": values.formTemplate?.value
      }

      let submitResponse = await postFormTemplates(TempObj);
      if (submitResponse['success']) {
        showToaster(toasterTypes.SUCCESS, "From created with template.")
        fetchForms(selectedGroup, groups[selectedGroup].id);
        setaction("");
      }
      dispatch(setLoading(false));

    } catch (error) {
      dispatch(setLoading(false));

    }

  }
  /**
   * create criteria navigate
   */
  const createCriteria = () => {
    setaction('criteria');
    //navigate("/createCriteria", { state: {projectData:projectData,groupData:groups[selectedGroup]} });
  };

  /**
   * on save create copy of the selected form with new form Name
   */
  const createDuplicateForm = async () => {
    if (validateValues(["formName", "cloneType"])) {
      //showToaster(toasterTypes.ERROR,"Madnadtory")
    } else {
      console.log(values.formName)
      try {
        dispatch(setLoading(true))
        let createResponse = await postFormCopy(selectedForm, { form_name: values.formName, tags: [values.cloneType.value] });
        if (createResponse) {
          showToaster(toasterTypes.SUCCESS, "Form Created");
          fetchForms(selectedGroup, groups[selectedGroup].id);
          setaction("")
        }
        dispatch(setLoading(false))
      } catch (error) {
        dispatch(setLoading(false))
        console.log("Error creating Form", error)
      }
    }
  }

  /**
   * rearrange table content call back function
   * get updated list and setstate
   */
  const getRearrangedData = (data) => {
    setIsRearranged(true)
    setforms(data)
  }

  /**
   * Save rearanged data
   * @tempArray stores array of object in new order
   * @updateRearrangedData API call to save data
   */
  const onSaveRearrangeData = async () => {
    let tempArray: any = [];
    forms && forms.map((formItem, i) => {
      tempArray.push({
        "id": formItem.id,
        "form_order_number": i + 1
      })
    })

    try {
      let response = await updateRearrangedData(tempArray);
      if (response) {
        showToaster(toasterTypes.SUCCESS, "Updated successfully");
        fetchForms(selectedGroup, groups[selectedGroup].id);
        setIsRearranged(false)
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
  }, [forms, selectedGroup])

  const onDeleteGroup = async () => {

    try {
      let response = await deleteGroup(deleteId);
      if (response) {
        showToaster(toasterTypes.SUCCESS, "Group deleted successfully");
        setaction("");
        fetchGroups(projectData.TopicID || projectData.TopicCode);
      }
    } catch (error) {
      console.log(error)
    }
  }

  const onEditGroup = (obj) => {
    setaction("creategroup");
    console.log(obj)
    setgroupData(obj);
  }



  return (
    <div className="data-collection">
      {
        action == "section" &&
        <SectionModal pushAction={addToEditor} onclose={() => setaction("")} />
      }
      {showTooltip && (
        <CopyTooltip tooltipStyle={tooltipStyle} data={selectedText} pushAction={sectionAction} />
      )}

      {
        action == "createclone" &&
        <ModalComponent
          width={"350px"}
          isOpen={true}
          onClose={() => setaction("")}
          header={"Create using exisitng form"}
          centered={true}
          customClass='template-modal'
          body={
            <>
              <div className="form-template">
                <FieldItem
                  {...FormElements.formName}
                  value={values.formName}
                  onChange={(...e) => handleChange(FormElements.formName.name, ...e)}
                  touched={fields.formName && fields.formName.hasError}
                  error={fields.formName && fields.formName.errorMsg}
                />

                <FieldItem
                  {...FormElements.cloneType}
                  value={values.cloneType}
                  onChange={(...e) => handleChange(FormElements.cloneType.name, ...e)}
                  touched={fields.cloneType && fields.cloneType.hasError}
                  error={fields.cloneType && fields.cloneType.errorMsg}
                />
              </div>
            </>
          }
          footer={
            <>
              <CustomButton text="Create" type="primary" onClick={() => createDuplicateForm()}></CustomButton>
            </>
          }
        />
      }

      {
        action == "deletegroup" &&
        <ModalComponent
          width={"350px"}
          isOpen={true}
          onClose={() => setaction("")}
          header={"Delete Group"}
          centered={true}
          customClass='template-modal'
          body={
            <div>
              <p>Are you sure, you want to delete this group?</p>
            </div>
          }
          footer={
            <>
              <CustomButton text="Delete" type="primary" onClick={() => onDeleteGroup()}></CustomButton>
            </>
          }
        />
      }

      {
        action == "cumulative" &&
        <ModalComponent
          width={"350px"}
          isOpen={true}
          onClose={() => setaction("")}
          header={"Create cumulative"}
          centered={true}
          customClass='template-modal'
          body={
            <>
              <div className="form-template">
                <FieldItem
                  {...FormElements.cumformName}
                  value={values.cumformName}
                  onChange={(...e) => handleChange(FormElements.cumformName.name, ...e)}
                  touched={fields.cumformName && fields.cumformName.hasError}
                  error={fields.cumformName && fields.cumformName.errorMsg}
                />
              </div>
            </>
          }
          footer={
            <>
              <CustomButton text="Create" type="primary" onClick={() => createCumulativeForm()}></CustomButton>
            </>
          }
        />
      }
      {
        action == "question" &&
        <AskQuestions {...props} isClose={() => setaction("")} formId={selectedForm} />
      }
      {
        action == "criteria" &&
        <CreateCriteria {...props} projectData={projectData} groupData={groups[selectedGroup]} onClose={() => setaction("")} />
      }
      {
        (action == "createform" || action === 2) &&
        <CreateForm
          {...props}
          formData={formData}
          isEdit={action === 2}
          projectData={projectData}
          groups={groups}
          group={groups[selectedGroup]}
          onClose={() => setaction("")}
          onFormCreation={() => onFormCreation()}
        />
      }

      {
        (action == "creategroup") &&
        <CreateGroup
          {...props}
          groupData={groupData}
          projectData={projectData}
          onClose={() => { setaction(""); setgroupData(null) }}
          onFormCreation={() => onGroupCreation()}
        />
      }
      {(action === 4) && (
        <ModalComponent
          width={"350px"}
          isOpen={true}
          onClose={() => setaction("")}
          header={"Create Form"}
          centered={true}
          customClass='template-modal'
          body={
            <>
              <div className="form-template">
                <FieldItem
                  {...FormElements.formTypes}
                  value={values.formType}
                  onChange={(...e) => handleChange(FormElements.formTypes.name, ...e)}
                  touched={fields.formTypes && fields.formTypes.hasError}
                  error={fields.formTypes && fields.formTypes.errorMsg}
                />
              </div>
            </>
          }
          footer={
            <>
            </>
          }
        />
      )}
      {
        action === 'createtemplate' &&
        <ModalComponent
          width={"350px"}
          isOpen={true}
          onClose={() => setaction("")}
          header={"Create form using template"}
          centered={true}
          customClass='template-modal'
          body={
            <div className="form-template">
              <FieldItem
                {...FormElements.formTemplate}
                value={values.formTemplate}
                values={formTemplates && formTemplates}
                onChange={(...e) => handleChange(FormElements.formTemplate.name, ...e)}
                touched={fields.formTemplate && fields.formTemplate.hasError}
                error={fields.formTemplate && fields.formTemplate.errorMsg}
              />
            </div>
          }
          footer={
            <>
              <CustomButton text="Create" type="primary" onClick={() => onSaveFormTempalte()}></CustomButton>
            </>
          }
        />
      }
      {
        action == "unlock" && (
          <ModalComponent
            width={window.innerWidth > 700 ? "500px" : "350px"}
            header={"Unlock the Form"}
            isOpen={true}
            onClose={() => setaction("")}
            customClass='invite-modal remark-modal'
            body={
              <>
                <div className="lock-remarks">
                  <FieldItem
                    {...FormElements.lockmode}
                    value={values.lockmode}
                    values={values.lockmode}
                    onChange={(...e) => handleChange(FormElements.lockmode.name, ...e)}
                    touched={fields.lockmode && fields?.lockmode.hasError}
                    error={fields.lockmode && fields?.lockmode.errorMsg}
                  />
                </div>
              </>
            }
            footer={
              <CustomButton text="Save" className="save-btn" onClick={() => updateStrictModeAction(lockData, "unlock")}></CustomButton>
            }
          />
        )
      }
      {action === 3 && (
        <ModalPopup
          modalType="delete"
          modalText="Are you sure, you want to delete?"
          actionText="This action cannot be undone"
          closeModal={() => {
            setaction("");
            setformData(null);
          }}
          onAction={() => onDelete()}
        />
      )}
      {action === 1 && (
        <ViewFormData
          {...props}
          forms={forms}
          formData={formData}
          formPageCount={formCount}
          projectData={projectData}
          groups={groups}
          group={groups[selectedGroup]}
          selectedGroup={selectedGroup}
          onClose={(type = "") => onCloseView(type)}
        />
      )}
      {action != 1 && action != "criteria" ? (
        <>
          <div className="data-analysis-container">
            {/* <div className="page-header-data">
              <div className={window.innerWidth > 750 ? "project-title" : "project-title-mob"}>
                <ArrowLeftOutlined style={{ color: "#fff", fontSize: "24px", margin: "0px 10px" }} onClick={() => window.innerWidth > 750 ? navigate("/project") : navigate("/projectmobile")} />
                <span className="project-title">
                  {projectData?.TopicDesc || projectData?.topicdesc}
                </span>
              </div>
            </div> */}
            <Row className='page-header m-0'>
              <Col className={window.innerWidth > 750 ? "project-title" : "project-title-mob"} md={12}>
                <ArrowLeftOutlined style={{ color: "#fff", fontSize: "24px", margin: "0px 10px" }} onClick={() => window.innerWidth > 750 ? navigate("/project") : navigate("/projectmobile")} />
                {projectData?.TopicDesc || projectData?.topicdesc}
              </Col>
              <Col className='p-0'></Col>
            </Row>
            <Tabs data={TabsData} selectedTab={2} projectData={projectData} />

            {/* {
              window.innerWidth < 750 ?
                <div className="m-3 groups-container">
                  <div className="group-header row m-0">
                    <div className="col p-0 group-text">Groups</div>
                    <i className="icon icon-add_circle"></i>
                  </div>
                  <div className="p-2">
                    <FieldItem
                      {...FormElements.search}
                      value={values.search}
                      onChange={(...e) =>
                        handleChange(FormElements.search.name, ...e)
                      }
                      touched={fields.search && fields.search.hasError}
                      error={fields.search && fields.search.errorMsg}
                    />
                  </div>
                  <div className="group-scroll">
                    {groups.map((obj, index) => (
                      <div
                        className={`group-row ${selectedGroup === index ? "selected-group" : ""
                          }`}
                        key={index}
                        onClick={() => fetchForms(index)}
                      >
                        <div className="group-label">{obj.name}</div>
                      </div>
                    ))}
                  </div>
                </div> : ""
            } */}
            {
              window.innerWidth > 750 ?
                <Row className="m-0 page-height">
                  <Col className="p-0 col-md-3 groups-container">
                    <div className="group-header row m-0">
                      <div className="col p-0 group-text">Groups</div>
                      <i className="icon icon-add_circle" onClick={() => setaction("creategroup")}></i>
                    </div>
                    <div className="p-2">
                      <FieldItem
                        {...FormElements.search}
                        value={values.search}
                        onChange={(...e) =>
                          handleChange(FormElements.search.name, ...e)
                        }
                        touched={fields.search && fields.search.hasError}
                        error={fields.search && fields.search.errorMsg}
                      />
                    </div>
                    <div className="group-scroll">
                      {groups.map((obj, index) => (
                        <div
                          className={`group-row ${selectedGroup === index ? "selected-group" : ""
                            }`}
                          key={index}
                          onClick={() => fetchForms(index)}
                        >
                          <Row>
                            <Col md={8}>
                              <div className="group-label">{obj.name}</div>
                            </Col>
                            <Col md={2}>
                              <i className="icon icon-edit" onClick={() => onEditGroup(obj)}></i>
                            </Col>
                            <Col md={2}>
                              <i className="icon icon-delete" onClick={() => { setDeleteId(obj.id); setaction("deletegroup") }}></i>
                            </Col>
                          </Row>
                        </div>
                      ))}
                    </div>
                  </Col>

                  <Col className="p-0 col-md-9">
                    <div className="p-3 d-flex justify-content-between ">
                      <span className="font-weight-bold">Group</span>
                      {permissionParams.criteria == "allowed" || Object.keys(permissionParams).length == 0 ?
                        <Button onClick={createCriteria} style={{ width: "fit-content" }}>
                          <span>Criterias</span>{" "}
                          <i className="icon icon-add_circle"></i>
                        </Button>
                        : ""
                      }
                    </div>
                    <div className="p-3">
                      {selectedGroup !== null && forms.length > 0 ? (
                        <CustomTable
                          header="Records"
                          data={forms}
                          tableProps={columns}
                          isSearch={true}
                          isFilter={true}
                          isSelectForm={true}
                          isSort={false}
                          isView={false}
                          isSummarise={true}
                          isDraggable={true}
                          isRearranged={isRearranged}
                          isCreate={permissionParams.createform == "allowed" || Object.keys(permissionParams).length == 0 ? true : false}
                          getRearrangeData={getRearrangedData}
                          onAction={(type, data = null) => onAction(type, data)}
                        ></CustomTable>
                      ) : (
                        <>
                          <div>
                            {selectedGroup !== null && forms.length === 0 ?
                            "":
                              <div className="p-3">
                                <div className="m-3" style={{display: "flex", gap:"10px", alignItems: "center", height: "100%"}}>
                                <ButtonSkeleton/>
                                <ButtonSkeleton/>
                                <ButtonSkeleton/>
                                </div>
                                <TableSkeleton />
                              </div>
                            }
                          </div>
                          <div className="group-center">

                            <div>
                              {selectedGroup !== null && forms.length === 0 &&
                                "No Forms"
                              }
                            </div>
                            {selectedGroup !== null && forms.length === 0 && (
                              <Row>
                                <CustomButton
                                  className="mt-2"
                                  type="alert-primary"
                                  text="Create"
                                  onClick={() => onAction(4)}
                                ></CustomButton>
                              </Row>
                            )}
                          </div>
                        </>
                      )}
                    </div>
                  </Col>
                </Row>
                :
                <div className="form-data-mobile">

                  <div className="group-data">
                    {groups &&
                      <FieldItem
                        {...FormElements.groupType}
                        value={values.groupType || groups[selectedGroup]}
                        values={groups}
                        onChange={(...e) =>
                          handleChange(FormElements.groupType.name, ...e)
                        }
                        touched={fields.groupType && fields.groupType.hasError}
                        error={fields.groupType && fields.groupType.errorMsg}
                      />
                    }
                  </div>
                  <div className="form-list">
                    <div className="records">
                      <span>Records | {forms && forms.length}</span>
                      {permissionParams.criteria == "allowed" || Object.keys(permissionParams).length == 0 ?
                        <span id="criteria" onClick={createCriteria}>Criterias<i className="icon icon-add"></i></span>
                        : ""
                      }
                    </div>
                    {
                      forms && forms.map((items, i) => {
                        return <><FormDataCardMobile
                          data={items}
                          permissionParams={permissionParams}
                          addData={addData}
                          addVariable={addVariable}
                          onActions={onAction}
                          projectData={projectData}
                        />
                          <hr></hr>
                        </>
                      })
                    }
                  </div>
                  <div className="footer-button">
                    <span id="combined-btn" onClick={() => onAction(8)}>View combined data</span>
                    {
                      permissionParams.createform == "allowed" || Object.keys(permissionParams).length == 0 ?
                        <CustomButton type="primary" text="create" onClick={() => onAction(4)}></CustomButton>
                        : ""
                    }
                  </div>
                </div>
            }
          </div>
        </>
      ) : ""}
    </div>
  );
}
