import AjaxUtil from '../../axios/axios';

/**
 * 
 * @param {*} data 
 * @returns 
 */
export const addGroups = async(data) => {
    try {
        return await AjaxUtil.sendRequest(`group`, data, false, { method: 'post', showError: true, isProfile: true} );
    } catch (error) {
        throw error;
    }
}

/**
 * edit group
 * @param {string} id 
 */
export const editGroup = async(id, data) => {
    try {
        return await AjaxUtil.sendRequest(`group`, data, false, { method: 'put', showError: true, isProfile: true} );
    } catch (error) {
        throw error;
    }
}

/**
 * delete group
 * @param {string} id 
 * @returns 
 */
export const deleteGroup = async(id) => {
    try {
        return await AjaxUtil.sendRequest(`group/${id}`, {}, false, { method: 'delete', showError: true, isProfile: true} );
    } catch (error) {
        throw error;
    }
}


/**
 * 
 * @param {*} id 
 * @returns 
 */
export const getGroups = async(id) => {
    try {
        return await AjaxUtil.sendRequest(`groups?topic_id=${id}`, {}, false, { method: 'get', showError: true, isProfile: true} );
    } catch (error) {
        throw error;
    }
}

export const searchGroup = async(id) => {
    try {
        return await AjaxUtil.sendRequest(`groups?key=`, {}, false, { method: 'get', showError: true, isProfile: true} );
    } catch (error) {
        throw error;
    }
}

export const getRoles = async(id) => {
    try {
        return await AjaxUtil.sendRequest(`roles`, {}, false, { method: 'get', showError: true, isProfile: true} );
    } catch (error) {
        throw error;
    }
}

/**
 * fetch form templates
 */
export const getFormTemplates = async(type) => {
    try {
        return await AjaxUtil.sendRequest(`formtemplates/${type}`, {}, false, { method: 'get', showError: true, isProfile: false} );
    } catch (error) {
        throw error;
    }
}

/**
 * fetch form usage types
 */
export const getFormUsageTypes = async() => {
    try {
        return await AjaxUtil.sendRequest(`futypes?account_type=NGO`, {}, false, { method: 'get', showError: true, isProfile: true} );
    } catch (error) {
        throw error;
    }
}

/**
 * fetch form templates
 */
export const postFormTemplates = async(data) => {
    try {
        return await AjaxUtil.sendRequest(`form/from/template`, data, false, { method: 'post', showError: true, isProfile: true} );
    } catch (error) {
        throw error;
    }
}

/**
 * create copy of form
 */
export const postFormCopy = async(formid,data) => {
    try {
        return await AjaxUtil.sendRequest(`form/${formid}/clone`, data, false, { method: 'post', showError: true, isProfile: true} );
    } catch (error) {
        throw error;
    }
}

/**
 * 
 * @param {g} projectId 
 * @param {*} id 
 * @returns 
 */

export const getUsers = async(projectId, id) => {
    try {
        return await AjaxUtil.sendRequest(`team/${projectId}/${id}`, {}, false, { method: 'get', showError: true, isProfile: true} );
    } catch (error) {
        throw error;
    }
}

export const createGroup = async() => {
    try {
        let data = {
            "name": "Group 1",
            "description": "Group 1",
            "topicid": "C4215",
            "group_type": "test_group"
        }
        return await AjaxUtil.sendRequest(`group`, data, false, { method: 'post', showError: true, isProfile: true} );
    } catch (error) {
        throw error;
    }
}




export const getForms = async(id) => {
    try {
        return await AjaxUtil.sendRequest(`forms/${id}`, {}, false, { method: 'get', showError: true, isProfile: true} );
    } catch (error) {
        throw error;
    }
}

export const getFormData = async(id) => {
    try {
        return await AjaxUtil.sendRequest(`form/${id}`, {}, false, { method: 'get', showError: true, isProfile: true} );
    } catch (error) {
        throw error;
    }
}

export const createForm = async(data) => {
    try {
        return await AjaxUtil.sendRequest(`form`, data, false, { method: 'post', showError: true, isProfile: true} );
    } catch (error) {
        throw error;
    }
}

export const updateForm = async(data) => {
    try {
        return await AjaxUtil.sendRequest(`form`, data, false, { method: 'put', showError: true, isProfile: true} );
    } catch (error) {
        throw error;
    }
}

export const deletForm = async(id) => {
    try {
        return await AjaxUtil.sendRequest(`form/${id}`, {}, false, { method: 'delete', showError: true, isProfile: true} );
    } catch (error) {
        throw error;
    }
}

export const getVariables = async(keyword, page) => {
    try {
        return await AjaxUtil.sendRequest(`variables/search?key=${keyword}&page=${page}&per_page=20`, {}, false, { method: 'get', showError: true, isProfile: true, isHeaders: true} );
    } catch (error) {
        throw error;
    }
}

export const getVariableTypes = async(id) => {
    try {
        return await AjaxUtil.sendRequest(`variable/types`, {}, false, { method: 'get', showError: true, isProfile: true} );
    } catch (error) {
        throw error;
    }
}

export const getVariableValidations = async(id) => {
    try {
        return await AjaxUtil.sendRequest(`variable/validations`, {}, false, { method: 'get', showError: true, isProfile: true} );
    } catch (error) {
        throw error;
    }
}

export const createVariable = async(data, formId) => {
    try {
        return await AjaxUtil.sendRequest(`form/${formId}/variable`, data, false, { method: 'post', showError: true, isProfile: true} );
    } catch (error) {
        throw error;
    }
}

export const getVariable = async(id) => {
    try {
        return await AjaxUtil.sendRequest(`variable/${id}`, {}, false, { method: 'get', showError: true, isProfile: true} );
    } catch (error) {
        throw error;
    }
}

export const updateVariable = async(data) => {
    try {
        return await AjaxUtil.sendRequest(`variable`, data, false, { method: 'put', showError: true, isProfile: true} );
    } catch (error) {
        throw error;
    }
}

export const deleteVariable = async(formId, variableId) => {
    try {
        return await AjaxUtil.sendRequest(`variable/${formId}/${variableId}`, {}, false, { method: 'delete', showError: true, isProfile: true} );
    } catch (error) {
        throw error;
    }
}

export const getFormVariables = async(formId) => {
    try {
        return await AjaxUtil.sendRequest(`form/${formId}/variables`, {}, false, { method: 'get', showError: true, isProfile: true} );
    } catch (error) {
        throw error;
    }
}

export const arrangeCategories = async(formId, data) => {
    try {
        return await AjaxUtil.sendRequest(`form/${formId}/variables`, data, false, { method: 'put', showError: true, isProfile: true} );
    } catch (error) {
        throw error;
    }
}

export const onAction = async(data) => {
    try {
        return await AjaxUtil.sendRequest(`form/action`, data, false, { method: 'post', showError: true, isProfile: true} );
    } catch (error) {
        throw error;
    }
}
/**
 * 
 * @param {*} formId 
 * @param {*} page 
 * @param {*} size 
 * @returns 
 */
export const getFormRecords = async(formId, page, size, checkAll) => {
    console.log("CHECKALLLL",checkAll)

    try {
        if(checkAll == false){
            return await AjaxUtil.sendRequest(`form/${formId}/responses?page=${page}&per_page=${size}&l=l`, {}, false, { method: 'get', showError: true, isProfile: true} );
        }else{
            return await AjaxUtil.sendRequest(`form/${formId}/responses?page=${page}&per_page=${size}`, {}, false, { method: 'get', showError: true, isProfile: true} );
        }
    } catch (error) {
        throw error;
    }
}

/**
 * get filter form data
 * @param {*} formId 
 * @param {*} page 
 * @param {*} size 
 * @returns 
 */
export const getFilteredFormRecords = async(formId,data,page, size,checkAll) => {
    try {
        if(checkAll == false){
            return await AjaxUtil.sendRequest(`form/sr/${formId}?page=${page}&per_page=${size}&l=l`, data, false, { method: 'post', showError: true, isProfile: true} );
        }else{
            return await AjaxUtil.sendRequest(`form/sr/${formId}?page=${page}&per_page=${size}`, data, false, { method: 'post', showError: true, isProfile: true} );
        }
    } catch (error) {
        throw error;
    }
}

/**
 * 
 * @param {*} recordId 
 * @returns 
 */

export const deleteRecord = async(recordId) => {
    try {
        return await AjaxUtil.sendRequest(`form/response/${recordId}`, {}, false, { method: 'delete', showError: true, isProfile: true} );
    } catch (error) {
        throw error;
    }
}

/**
 * Upload
 * @param {*} formData 
 * @returns 
 */
export const uploadRecord = async(formData) => {
    try {
        return await AjaxUtil.sendRequest(`form/responses/upload`, formData, false, { method: 'post', showError: true, isProfile: true} );
    } catch (error) {
        throw error;
    }
}

export const downloadFieldTemplate = async(formId) => {
    //const headers =  {'Content-Type': "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"}, responseType: "arraybuffer"}
    try {
        return await AjaxUtil.sendRequestFile(`form/${formId}/variables/download`, {}, false, { method: 'get', showError: true, isProfile: true,responseType: "arraybuffer"} );
    } catch (error) {
        throw error;
    }
}

export const downloadFormData = async(formId) => {
    // const headers =  headers: {'Content-Type': "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"}, responseType: "arraybuffer"}
    try {
        return await AjaxUtil.sendRequest(`form/${formId}/data/download`, {}, false, { method: 'get', showError: true, isProfile: true,responseType: "arraybuffer"} );
    } catch (error) {
        throw error;
    }
}

export const downloadAnalyticalData = async(topicId) => {
    // const headers =  headers: {'Content-Type': "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"}, responseType: "arraybuffer"}
    try {
        return await AjaxUtil.sendRequest(`ad/download/${topicId}`, {}, false, { method: 'get', showError: true, isProfile: true,responseType: "arraybuffer"} );
    } catch (error) {
        throw error;
    }
}

export const getNonSurveyForm = async(formId) => {
    let versionid  = Number(localStorage.getItem("versionid"));

    try {
        return await AjaxUtil.sendRequest(`form/${formId}/datacollect?version_id=${versionid}`, {}, false, { method: 'get', showError: true, isProfile: true} );
    } catch (error) {
        throw error;
    }
}

export const submitIdentifierForm = async(reqObj) => {
    try {
        return await AjaxUtil.sendRequest(`form/submit`, reqObj, false, {method: 'put', showError: true, isProfile: true} );
    } catch (error) {
        throw error;
    }
}

export const updateIdentifierForm = async(reqObj) => {
    try {
        return await AjaxUtil.sendRequest(`form/response`, reqObj, false, {method: 'put', showError: true, isProfile: true} );
    } catch (error) {
        throw error;
    }
}

/**
 * validate the data entry form data
 * @param {*} formId 
 * @param {*} reqObj 
 * @returns 
 */
export const validateIdentifierForm = async(formId, reqObj) => {
    try {
        if(formId == ""){
            return await AjaxUtil.sendRequest(`variable/validate`, reqObj, false, { method: 'post', showError: true, isProfile: true} );

        }else{
            return await AjaxUtil.sendRequest(`variable/validate?source_form_id=${formId}`, reqObj, false, { method: 'post', showError: true, isProfile: true} );

        }
    } catch (error) {
        throw error;
    }
}

/**
 * 
 * @param {*} formId 
 * @param {*} fieldId 
 * @param {*} answer 
 * @returns 
 */
export const fetchNextVariable = async(formId, fieldId, answer) => {
    let versionid = localStorage.getItem("versionid");
    let answers = encodeURIComponent(answer)
    try {
        return await AjaxUtil.sendRequest(`variable/next?form_id=${formId}&field_id=${fieldId}&answer=${answers}&version_id=${versionid}`, {}, false, { method: 'get', showError: true, isProfile: true} );
    } catch (error) {
        throw error;
    }
}

/**
 * update strict mode
 */
export const updateStrictMode = async(id,data) => {
    try {
        return await AjaxUtil.sendRequest(`form/${id}/lock/toggle`, data, false, { method: 'put', showError: true, isProfile: true} );
    } catch (error) {
        throw error;
    }
}

/**
 * get tree data
 */
export const getTreeData = async(formid,versionid) => {
    try {
        return await AjaxUtil.sendRequest(`/variable/flows?form_id=${formid}&version_id=${versionid}`, {}, false, { method: 'get', showError: true, isProfile: true} );
    } catch (error) {
        throw error;
    }
}

/**
 * get target forms list  
 */
export const getTargetFormList = async(formId) => {
    try {
        return await AjaxUtil.sendRequest(`dforms/${formId}`, {}, false, { method: 'get', showError: true, isProfile: true} );
    } catch (error) {
        throw error;
    }
}

/**
 * post array of ids 
 * 
 */
export const postgenerateLink = async(formId,targetId,idArray) => {
    try {
        return await AjaxUtil.sendRequest(`sle/${formId}/${targetId}`, idArray, false, { method: 'post', showError: true, isProfile: true} );
    } catch (error) {
        throw error;
    }
}

/**
 * update rearranged data
 */
export const updateRearrangedData = async(data) => {
    try {
        return await AjaxUtil.sendRequest(`form/order`, data, false, { method: 'put', showError: true, isProfile: true} );
    } catch (error) {
        throw error;
    }
}

/**
 * create criteria
 */
export const postCreateCriteria = async(data) => {
    try {
        return await AjaxUtil.sendRequest(`form/criteria`, data, false, { method: 'post', showError: true, isProfile: true} );
    } catch (error) {
        throw error;
    }
}

/**
 * update criteria
 */
export const updateCreateCriteria = async(data) => {
    try {
        return await AjaxUtil.sendRequest(`form/criteria`, data, false, { method: 'put', showError: true, isProfile: true} );
    } catch (error) {
        throw error;
    }
}

/**
 * get list of criteria
 */
export const getCriteria = async(groupid) => {
    try {
        return await AjaxUtil.sendRequest(`all/criteria/${groupid}`, {}, false, { method: 'get', showError: true, isProfile: true});
    } catch (error) {
        throw error;
    }
}

/**
 * delete criteria
 */
export const deleteCriteriaItem = async(id) => {
    try {
        return await AjaxUtil.sendRequest(`form/criteria/${id}`, {}, false, { method: 'delete', showError: true, isProfile: true});
    } catch (error) {
        throw error;
    }
}

/**
 * Apply criteria
 */
export const postApplyCriteria = async(id) => {
    try {
        return await AjaxUtil.sendRequest(`form/criteria/${id}/process`, {}, false, { method: 'post', showError: true, isProfile: true} );
    } catch (error) {
        throw error;
    }
}

/**
 * Apply criteria
 */
export const postAskQuestion = async(id,data) => {
    try {
        return await AjaxUtil.sendRequest(`qfai/${id}`, data, false, { method: 'post', showError: true, isAnalysisAI: true} );
    } catch (error) {
        throw error;
    }
}

/**
 * Get Visualization Data
 */
export const getVisualisationData = async(id) => {
    try {
        return await AjaxUtil.sendRequest(`form/${id}/data/explore`, {}, false, { method: 'get', showError: true, isProfile: true} );
    } catch (error) {
        throw error;
    }
}

/**
 * serach index post call
 */
export const postSearchIndex = async(id,formId,data,checkAll) => {
    try {
        if(checkAll == false){
            return await AjaxUtil.sendRequest(`form/sir/${id}/${formId}?page=1&per_page=10&l=l`, {"search_data":data}, false, { method: 'post', showError: true, isProfile: true} );
        }else{
            return await AjaxUtil.sendRequest(`form/sir/${id}/${formId}?page=1&per_page=10`, {"search_data":data}, false, { method: 'post', showError: true, isProfile: true} );
        }
    } catch (error) {
        throw error;
    }
}

/**
 * 
 */
export const updateBulkForm = async(reqObj) => {
    try {
        return await AjaxUtil.sendRequest(`form/responses/bulk`, reqObj, false, {method: 'put', showError: true, isProfile: true} );
    } catch (error) {
        throw error;
    }
}