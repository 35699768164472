import { Col, Label, Row } from "reactstrap";
import ModalComponent from "../common/Modal/modal";
import { Form } from "react-router-dom";
import { FIELD_TYPES, FieldItem } from "../../widgets/fields";
import useFieldItem from "../../widgets/fields/UseFieldItem";
import { CustomButton } from "../../widgets";
import { useEffect, useState } from "react";

const ExistingDataModal = (props) => {
     const [values, fields, handleChange, { validateValues, reset, updateValue, resetValue, updateError }] = useFieldItem({}, {}, { onValueChange });
     const { existingData, getExistingData } = props;
     console.log(props)
     const [tableData, setTableData] = useState<any>([]);
     function onValueChange(name, value, values, fieldValues = null) {

          console.log(values)
          return [{
               ...values,
               [name]: value,
          }]
     }

     useEffect(() => {
          let tableData: any = [];
          existingData && existingData.map((data, index) => {
               const objectWithLargestFields = data.record_info.reduce((max, current) =>
                    current.fields.length > max.fields.length ? current : max, data.record_info[0]);

               const sortedData = data.record_info.sort((a, b) => b.fields.length - a.fields.length);
               // console.log(objectWithLargestFields, sortedData);
               let tabObj = {
                    formName: data.form_name,
                    headers: objectWithLargestFields,
                    bodydata: sortedData
               }
               tableData.push(tabObj)
               console.log(tabObj)

          })
          setTableData(tableData)

     }, [])


 

     useEffect(() => {

     }, [tableData])

     /**
      * on submit function
      */
     const onSubmit = () => {
          let id = values.form.split("_")
          let form = existingData[id[0]].record_info[id[1]]
          console.log(form)
          getExistingData(form)
          props.isClose()
     }

     return (
          <ModalComponent
               width={"80%"}
               isOpen={true}
               onClose={() => props.isClose()}
               header="Select Existing Data"
               centered={true}
               customClass='existing-modal'
               body={
                    <div className="existing-data-modal">
                         {
                              tableData.map((data, index) => {
                                   return (data.headers && <div key={index} className="existing-data-item">
                                        <div className="existing-data-form">
                                             <div style={{ color: '#333', whiteSpace: 'nowrap', fontWeight: '600', fontSize: '16px' }}><Label>Form Name:</Label></div>
                                             <div style={{ fontSize: '20px' }}> {data.formName}</div>
                                        </div>

                                        <div className="field-data">
                                             <div className="field-item" key={index}>
                                                  <table>
                                                       <thead>
                                                            <tr>
                                                                 <th id="sel">#</th>
                                                                 {
                                                                      data.headers && data.headers.fields?.map((item, i) => (
                                                                           <th>{item.field_name}</th>
                                                                      ))
                                                                 }
                                                            </tr>
                                                       </thead>
                                                       <tbody>

                                                            {
                                                                 data.bodydata.map((itemd, ind) => (
                                                                      <tr>
                                                                           <td id="seld">
                                                                                <FieldItem
                                                                                     name={"form"}
                                                                                     value={values.form}
                                                                                     values={[{ label: "", value: index + "_" + ind }]}
                                                                                     type={FIELD_TYPES.RADIO_BUTTON}
                                                                                     onChange={(...e) => handleChange("form", ...e)}
                                                                                />
                                                                           </td>
                                                                           {
                                                                                itemd.fields?.map((item, tabindex) => (
                                                                                     <>
                                                                                          <td>
                                                                                               {item?.field_type == "checkBox" ? item.field_selected_value?.map(itm => { return <span key={index}>{itm}, </span> }) : item?.field_selected_value || ""}
                                                                                          </td>
                                                                                     </>
                                                                                ))
                                                                           }
                                                                           {
                                                                                console.log(data.headers.fields.length - itemd.fields.length)
                                                                           }
                                                                           {
                                                                                data.headers.fields.length != itemd.fields.length ? 
                                                                                data.headers.fields.slice(data.headers.fields.length - (data.headers.fields.length - itemd.fields.length)).map(itm =>(
                                                                                     <td> </td>
                                                                                ))
                                                                                : ""
                                                                           }
                                                                      </tr>
                                                                 ))
                                                            }

                                                       </tbody>
                                                  </table>
                                             </div>
                                        </div>
                                   </div>
                                   )
                              })
                         }
                         {/* {
                              existingData && existingData.map((data, index) => {
                                   return <div key={index} className="existing-data-item">
                                        <div className="existing-data-form">
                                             <div style={{ color: '#333', whiteSpace: 'nowrap', fontWeight: '600', fontSize: '16px' }}><Label>Form Name:</Label></div>
                                             <div style={{ fontSize: '20px' }}> {data.form_name}</div>
                                        </div>

                                        <div className="field-data">
                                             {
                                                  data.record_info && data.record_info.map((field, j) => (
                                                       <div className="field-item" key={j}>

                                                            <table>
                                                                 <thead>
                                                                      {
                                                                           j == 0 &&
                                                                           <tr>
                                                                                <th id="sel">#</th>
                                                                                {
                                                                                     data.record_info.reduce((max, current) =>
                                                                                          current.fields.length > max.fields.length ? current : max, data.record_info[0]).fields.map((item, i) => (
                                                                                               <th>{item.field_name}</th>
                                                                                          ))
                                                                                }

                                                                           </tr>


                                                                      }
                                                                 </thead>
                                                                 <tbody>
                                                                      {
                                                                           data.record_info.sort((a, b) => b.fields.length - a.fields.length).map((itemd, i) => (

                                                                                <tr>
                                                                                     <td id="seld">
                                                                                          <FieldItem
                                                                                               name={"form"}
                                                                                               value={values.form}
                                                                                               values={[{ label: "", value: index + "_" + j }]}
                                                                                               type={FIELD_TYPES.RADIO_BUTTON}
                                                                                               onChange={(...e) => handleChange("form", ...e)}
                                                                                          />
                                                                                     </td>
                                                                                     {
                                                                                          itemd.fields.map((item, i) => (
                                                                                               <td>
                                                                                                    {item?.field_type == "checkBox" ? item.field_selected_value?.map(itm => { return <span key={i}>{itm}, </span> }) : item?.field_selected_value || ""}
                                                                                               </td>
                                                                                          ))
                                                                                     }
                                                                                </tr>
                                                                           ))
                                                                      }

                                                                 </tbody>
                                                            </table>


                                                       </div>
                                                  ))
                                             }
                                        </div>

                                   </div>
                              })

                         } */}

                    </div>
               }
               footer={
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                         <CustomButton type="primary-alt" className="mr-2" onClick={props.isClose} text="Cancel" />
                         <CustomButton type="primary" className="mr-2" onClick={() => onSubmit()} text="Continue" />
                    </div>
               }
          />
     )
}
export default ExistingDataModal;