import { useEffect } from "react";
import { Input, InputGroup, InputGroupText } from "reactstrap"

export const ColumnFilter = ({ getSearchText, column }) => {
    const { filtervalue, setFilter } = column;

    useEffect(() =>{
       // console.log("--->",column)
    },[])
    
    return (
        <span>
            <Input
                className="search"
                style={{ width: "150px", height: "20px", fontSize:"12px", margin:"2px 0px" }}
                value={filtervalue}
                placeholder="Search"
                onChange={(e) => getSearchText(column,e.target.value)}
            />
        </span>
    )
}