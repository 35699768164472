import { useEffect, useRef, useState } from "react";
import { FIELD_TYPES, FieldItem } from "../../widgets/fields";
import useFieldItem from "../../widgets/fields/UseFieldItem";
import dayjs from 'dayjs';
import { cloneDeep } from "lodash";
import { Col, Row } from "reactstrap";
import { CustomButton, showToaster, toasterTypes } from "../../widgets";
import { updateBulkForm } from "./apiController";
import ModalPopup from "../../widgets/modal";
import { useDispatch } from "react-redux";
import { setLoading } from "../../store/slices/auth";
import { uploadPhoto } from "../profile/apiController";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";


const TableViewData = (props) => {
  const { formData, existData } = props;
  const [values, fields, handleChange, { validateValues, reset, updateValue, updateError, removeErrors }] =
    useFieldItem({}, {}, { onValueChange });
  var selectedParentIndex: any = null;
  var selectedChildIndex: any = null;
  const [formTabData, setformTabData] = useState<any | null>(null);
  const [startDate, SetstartDate] = useState(new Date());
  const [edit, SetEdit] = useState(false)
  const dispatch = useDispatch();
  let formContainerRef = useRef(null);

  useEffect(() => {
    // console.log(formData);
    // console.log("ExistData===>", existData)
    // formData.isTable.unshift(false)
    formData.isTable.map((itemchk, index) => {
      if (itemchk == true) {
        let fieldDataArray: any = [];
        console.log("FLT", formData.form_fields[index].filter((item) => item.field_type == "dropDownPreviousResponses"))
        let dt = formData.form_fields[index].filter((item) => item.field_type == "dropDownPreviousResponses")
        const objectWithLargestFields = dt.length > 0 && dt?.reduce((maxObj, currentObj) => {
          const currentLength = currentObj.field_value?.length || 0;
          const maxLength = maxObj.field_value?.length || 0;
          return currentLength > maxLength ? currentObj : maxObj;
        });

        // console.log("FILTER DAT---", objectWithLargestFields)
        //  formData && formData.form_fields[index].filter((item) => item.field_type == "dropDownPreviousResponses").map((items, i) => {
        //items && items.filter((item) => item.field_type == "dropDownPreviousResponses").map((itemVal, j) => {

        objectWithLargestFields && objectWithLargestFields.field_value.map((itemData, k) => {
          let tempArray: any = [];
          let combinedData: any = [];
          //tempArray[0].field_selected_value = itemData;
          formData.form_fields[index] && formData.form_fields[index].map((itemtd, l) => {


            if (itemtd.field_type === "dropDownPreviousResponses") {
              // console.log(itemtd.field_value[k])
              //console.log({ [itemtd.id + k + 0]: itemData })
              updateValue({ [itemtd.id + k + 0]: itemData })
            }

            if (l != 0 && existData && existData?.fields?.length > 0) {
              let d = existData && existData.fields.filter(itm => itm.id == itemtd.id && itemData.label == existData.fields[0].field_selected_value);
              // console.log(l, "EXIST DATA--->", d)
              d && d[0] && updateValue({ [d[0]?.id + k + l]: d[0].field_selected_value })
            }

            tempArray.push(itemtd)
            const sortedData = tempArray
              .filter((item) => item.field_type === "dropDownPreviousResponses")
              .sort((a, b) => b.field_value.length - a.field_value.length);
            const otherData = tempArray.filter((item) => item.field_type !== "dropDownPreviousResponses");

            combinedData = [...sortedData, ...otherData];
            //console.log("SORTED DATA", combinedData)

          })
          fieldDataArray.push(combinedData)
        })
        // })
        //  })
        //console.log("++++", fieldDataArray)
        setformTabData(fieldDataArray)
      }
    })
  }, []);



  /**
 * set version id
 */
  useEffect(() => {
    localStorage.setItem("versionid", Date.now().toString());
  }, [1])


  /**
   * @param name 
   * @param value 
   * @param values 
   * @param fieldValues 
   * @returns 
   */
  function onValueChange(name, value, values, fieldValues = null) {
    console.log(values)
    console.log(name[name.length - 1])
    let colnum = name[name.length - 1];
    let rownum = name[name.length - 2];

    let temp: any = cloneDeep(formTabData);
    console.log(temp[rownum][colnum])
    temp[rownum][colnum].field_selected_value = value;
    setformTabData(temp)

    // temp.form_fields[selectedParentIndex][
    //   selectedChildIndex
    // ].field_selected_value = value;
    // setformTabData(temp);
    return [
      {
        ...values,
        [name]: value,
      },
    ];
  }

  /**
   * on save click
   */
  const onSave = async () => {
    let temp: any = cloneDeep(formTabData);
    console.log(temp)
    console.log(values);

    let finalArray: any = [];
    let uploadArray: any = [];

    temp.map((item, i) => {
      let fields: any = [];

      formData && formData.isTable.filter(itm => itm == false).map((itemchk, index) => {
        formData.form_fields[index] && formData.form_fields[index].map((itemtd, j) => {
          let Obj = {
            id: itemtd.id,
            field_selected_value: itemtd.field_selected_value.label
          }
          fields.push(Obj)
        })
      })
      item.map((itemd, j) => {
        let Obj: any = {};
        if (values[itemd.id + i + j]) {
          if (itemd.field_type === "dropDown" ||
            itemd.field_type === "dropDownPreviousResponses") {
            Obj = {
              id: itemd.id,
              field_selected_value: values[itemd.id + i + j].label
            }
          } else if (itemd.field_type === "fileUpload") {
            if (typeof itemd?.field_selected_value != "string") {
              Obj = {
                id: itemd.id,
                field_selected_value: null
              }
              const formData = new FormData();
              formData.append("file", itemd?.field_selected_value && itemd?.field_selected_value[0]);
              itemd?.field_selected_value && uploadArray.push(uploadDocs(itemd.id + i + j, formData));
            }
          } else {
            Obj = {
              id: itemd.id,
              field_selected_value: values[itemd.id + i + j]
            }
          }

          console.log(values[itemd.id + i + j])
        } else {
          Obj = {
            id: itemd.id,
            field_selected_value: null
          }
        }
        fields.push(Obj)
      })



      let versionid: number = Number(localStorage.getItem("versionid"));
      const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
      let endDate = new Date();

      let finalObj = {
        form_id: formData.id,
        version_id: versionid,
        fields: fields,
        start_time: startDate,
        end_time: endDate,
        time_zone: tz
      }

      finalArray.push(finalObj)
    })

    const Docresults = await Promise.all(uploadArray);
    console.log(finalArray, Docresults)
    if (Docresults) {
      Docresults && Docresults.map((item, i) => {
        let colnum = parseInt(item.id[item.id.length - 1]);
        let rownum = item.id[item.id.length - 2];
        console.log(colnum + 1, rownum, item.name)
        finalArray[rownum].fields[colnum + 1].field_selected_value = item.name;
      })

      console.log(finalArray)
      try {
        let response = await updateBulkForm(finalArray)
        if (response) {
          showToaster(toasterTypes.SUCCESS, response.message);
          props.onTableSubmit(false, true)
        }
      } catch (error) {
        console.log(error)
      }
    }




    console.log(finalArray)
  }
  const editIndex = () => {
    setformTabData([])
    props.onEditTable(props.editIndexV, formData)
  }


  /**
 * 
 * @param data 
 * @param resolve 
 * @param reject 
 */
  const uploadDoc = async (id, data, resolve, reject) => {
    try {
      dispatch(setLoading(true));
      let response = await uploadPhoto(data);
      if (response.mediaUrl) {
        resolve({ name: response.mediaUrl, id: id });
      }
      dispatch(setLoading(false));
    } catch (e: any) {
      reject("");
      dispatch(setLoading(false));
    }
  };

  /**
   * create promise all function to upload images
   */
  const uploadDocs = (id, formData) => {
    return new Promise(async (resolve, reject) => {
      await uploadDoc(id, formData, resolve, reject);
    })
  }

  const tableContainerRef: any = useRef(null);

  const handleScroll = (direction) => {
    console.log(tableContainerRef)
    if (tableContainerRef.current) {
      const scrollAmount = 100; // Adjust as needed
      tableContainerRef.current.scrollLeft += direction === 'right' ? scrollAmount : -scrollAmount;
    }
  };

  return (
    <>
      {
        edit &&
        <ModalPopup
          modalType="delete"
          modalText="Are you sure, you want to edit?"
          actionText="This will erase the data of next variables"
          closeModal={() => SetEdit(false)}
          onAction={() => editIndex()}
        />
      }
      <div className="table-view-container">

        {formTabData && formTabData.length > 0 ?
          <><div
            style={{
              position: 'absolute',
              left: '0%',
              top: '50%',
              transform: 'translateY(-50%)',
              zIndex: 1,
              borderLeft: '1px solid #ccc',
              backgroundColor: '#006BD9',
              cursor: 'pointer',
              padding: '5px 5px',
              borderRadius: '5px'
            }}
            onClick={() => handleScroll('left')}


          >
            <LeftOutlined style={{ color: '#fff' }} />
          </div>
            <div ref={tableContainerRef} className="table-data">
              {
                formTabData && formTabData.map((items, i) => {
                  return <table className="vertical-table">
                    {
                      i == 0 &&
                      <thead>
                        <tr>

                          {
                            items && items.map((item, j) => (
                              <th>{item.field_name}</th>
                            ))
                          }

                        </tr>
                      </thead>
                    }
                    <tbody>
                      <tr>
                        {
                          items && items.map((item, j) => (
                            j == 0 ?
                              <td>{item.field_value[i]?.label}</td>
                              :
                              <td>
                                <FieldItem
                                  className={'dynamic-item'}
                                  name={item.id + i + j}
                                  placeholder={
                                    item.field_type === "number" ||
                                      item.field_type === "textBox" ||
                                      item.field_type === "textArea"
                                      ? "Enter"
                                      : "Select"
                                  }
                                  label={item.field_name}
                                  type={
                                    item.field_type === "fileUpload"
                                      ? FIELD_TYPES.FILE_UPLOAD
                                      : item.field_type === "dateField"
                                        ? FIELD_TYPES.DATEPICKER_NEW
                                        : item.field_type === "dropDown"
                                          ? FIELD_TYPES.DROP_DOWN
                                          : item.field_type === "dropDownPreviousResponses"
                                            ? FIELD_TYPES.DROP_DOWN
                                            : item.field_type === "boolean"
                                              ? FIELD_TYPES.BOOLEAN
                                              : item.field_type === "radioButton"
                                                ? FIELD_TYPES.RADIO_BUTTON
                                                : item.field_type === "checkBox"
                                                  ? FIELD_TYPES.CHECK_BOX
                                                  : item.field_type === "textArea"
                                                    ? FIELD_TYPES.TEXT_AREA
                                                    : item.field_type === "textBox"
                                                      ? FIELD_TYPES.TEXT
                                                      : item.field_type === "time"
                                                        ? FIELD_TYPES.TIME_PICKER
                                                        : item.field_type === "dateTime"
                                                          ? FIELD_TYPES.DATE_TIME_PICKER
                                                          : FIELD_TYPES.TEXT
                                  }
                                  isNumber={item.field_type === "number" ? true : false}
                                  value={values[item.id + i + j]}
                                  // value={item.field_type == "dateField" ? item.field_selected_value ? dayjs(item.field_selected_value) : null : item.field_selected_value}
                                  values={item.field_value}
                                  isListed={true}
                                  onChange={(...e) => {
                                    selectedParentIndex = i + 1;
                                    selectedChildIndex = j;
                                    handleChange(item.id + i + j, ...e);
                                  }}

                                />
                              </td>

                          ))
                        }

                      </tr>
                    </tbody>
                  </table>
                })
              }
            </div>
            <div
              style={{
                position: 'absolute',
                right: '5px',
                top: '50%',
                transform: 'translateY(-50%)',
                zIndex: 1,
                borderLeft: '1px solid #ccc',
                backgroundColor: '#006BD9',
                cursor: 'pointer',
                padding: '5px 5px',
                borderRadius: '5px'
              }}
              onClick={() => handleScroll('right')}
            >
              <RightOutlined style={{ color: '#fff' }} />

            </div>
          </>
          : <div style={{ display: "flex", justifyContent: "center" }}>Data Entry in Table format is not possible due to non available parent data. Please contact admin</div>}


        {
          formTabData && formTabData.length > 0 &&
          <Row className="footer-card">
            <Col className="p-0">

            </Col>
            <Col className="p-0 d-flex m-4 justify-content-end">
              <CustomButton
                type="primary"
                onClick={() => onSave()}
                text="Submit"
              ></CustomButton>
            </Col>
          </Row>
        }

      </div>
    </>
  )
};

export default TableViewData;