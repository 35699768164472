import { useEffect, useState } from "react";
import { Image } from "react-bootstrap";
import { CustomButton, showToaster, toasterTypes } from "../../widgets";
import { useDispatch } from "react-redux";
import VariableModal from "./VariableModal";
import { setLoading } from "../../store/slices/auth";
import { getAnalysisChartTypes, getOutcomeVariable, updateSelectedConfig } from "./apiController";
import useFieldItem from "../../widgets/fields/UseFieldItem";
import { FIELD_TYPES, FieldItem } from "../../widgets/fields";
import { Col, Row } from "reactstrap";

export default function AddConfigurationMobile(props) {
    const [values, fields, handleChange, { validateValues, reset, updateValue, updateError }] = useFieldItem({}, {}, {});
    const [popupType, SetpopupType] = useState("");
    const [variableType, SetVaraibleType] = useState("");
    const [outcomeVariable, SetOutcomeVariable] = useState<any>([]);
    const [explanatoryVariable, SetexplanatoryVariable] = useState<any>([]);
    const [isChart, SetisChart] = useState(false);
    const [chartTypes, SetChartTypes] = useState<any>([]);
    const [formulaTypes, SetFormulaTypes] = useState<any>([]);
    const [splitonTypes, SetSplitonTypes] = useState<any>([]);
    const [featureTypes, SetFeatureTypes] = useState<any>([]);
    const [addedConfig, SetAddedConfig] = useState<any>([]);
    const [variables, Setvariables] = useState<any>([]);
    const dispatch = useDispatch();

    useEffect(() => {
        console.log("--->", props)
        fetchIntialData();
    }, [])

    /**
     * get chart types
     * get variables data
     * find previous data variables details and update the state SetAddedConfig 
     */
    const fetchIntialData = async () => {
        try {
            dispatch(setLoading(true));
            let response = await getAnalysisChartTypes();
            // let formulaResponse = await getFormulaList();
            // let splitonResponse = await getSplitOnList();
            // let featureResponse = await getFeatureList();

            SetChartTypes(response.map(({
                key: value,
                name: label
            }) => ({
                value,
                label
            })))

            if (props.previousData != "") {
                SetOutcomeVariable(props.previousData.outcomeVar)
                SetexplanatoryVariable(props.previousData.expoVariable)
                updateValue({ chart: props.previousData.charttype, combineddata: props.previousData.combineddata ? [true] : [false] })
                SetSplitonTypes(props.previousData.spliton)
                SetFeatureTypes(props.previousData.features)
            }

            dispatch(setLoading(false));
        } catch (e: any) {
            dispatch(setLoading(false));
        }
    }

    const onClose = () => {
        SetpopupType("")
    }

    const onAddOutcomeVaraible = (data) => {
        console.log(data)
        if(variableType == "outcome"){
            SetOutcomeVariable(data)
        }else{
            SetSplitonTypes(data)
        }
    }

    /**
     * on Add action set variables to respective states
     * @param data 
     */
    const onAddExplantoryVaraible = (data) => {
        console.log(data)
        if (variableType == "formula") {
            SetFormulaTypes(data)
        } 
        // else if (variableType == "spliton") {
        //     SetSplitonTypes(data)
        // } 
        else if (variableType == "feature") {
            SetFeatureTypes(data)
        } else {
            SetexplanatoryVariable(data)
        }
    }

    /**
     * remove selected items 
     * @param itemRemove 
     * @param type 
     */
    const removeItem = (itemRemove, type) => {
        if (type == "formula") {
            let removedVariable = formulaTypes && formulaTypes.filter(item => item != itemRemove);
            SetFormulaTypes(removedVariable)
        } else if (type == "spliton") {
            let removedVariable = splitonTypes && splitonTypes.filter(item => item.label != itemRemove);
            SetSplitonTypes(removedVariable)
        } else if (type == "feature") {
            let removedVariable = featureTypes && featureTypes.filter(item => item != itemRemove);
            SetFeatureTypes(removedVariable)
        } else {
            let removedVariable = explanatoryVariable && explanatoryVariable.filter(item => item != itemRemove);
            SetexplanatoryVariable(removedVariable)
        }

    }

    /**Add data: pass information to configlistmobile component */
    const addConfiguration = () => {
        let data = {
            outcomeVar: outcomeVariable,
            expoVariable: explanatoryVariable,
            charttype: values.chart,
            spliton: splitonTypes,
            features: featureTypes,
            combineddata: values.combineddata
        }
        props.getData(data, props.previousData ? props.previousData.id : "");
        props.onClose();
    }



    return (
        <>
            {
                popupType == "variable" ?
                    <VariableModal
                        onClose={onClose}
                        data={props.data}
                        formData={props.formData}
                        id={props.id}
                        outcomeSelected={outcomeVariable.length > 0 ? outcomeVariable[0]['value'] : ""}
                        getData={onAddOutcomeVaraible}
                        getExplanatory={onAddExplantoryVaraible}
                        variabletype={variableType}
                        selectedValues={variableType == "formula" ? formulaTypes : variableType == "spliton" ? splitonTypes[0] : variableType == "feature" ? featureTypes : explanatoryVariable} />
                    : ""
            }

            <div className="analysis-add-config-list-mobile">
                <div className="header-container">
                    <div className='pl-3 pt-4 form-name'>
                        <Image className='profile-image' alt='Profile' src={`${process.env.PUBLIC_URL}/images/logo.png`} />
                        Data Analysis
                    </div>
                    <div className='pt-4 closeicon'>
                        <i className='icon icon-close' onClick={() => props.onClose()} />
                    </div>
                </div>
                <div className="analysis-config-body">
                    <div className="outcome-variable" onClick={() => { SetpopupType("variable"); SetVaraibleType("outcome"); }}>
                        <i className="icon icon-add"></i>
                        Outcome Variable
                    </div>
                    <div className="outcome-list">
                        {
                            outcomeVariable && outcomeVariable.map((item, i) => {
                                return <div className="variable-exp" key={0}>
                                    <span style={{ color: "#000" }}>{item.value}</span>
                                    <i className="icon icon-close" style={{ color: "red", padding: "0px 5px" }} onClick={() => console.log("")} />
                                </div>
                            })
                        }
                    </div>

                    <div className="explanatory-variable"
                        onClick={() => {
                            if (outcomeVariable.length) {
                                SetpopupType("variable")
                                SetVaraibleType("explanatory")
                            } else {
                                showToaster(toasterTypes.INFO, "Select Outcome variable")
                            }
                        }}>
                        <i className="icon icon-add"></i>
                        Explanatory Variable
                    </div>
                    <div className="explanatory-list">
                        {
                            explanatoryVariable && explanatoryVariable.map((item, i) => {
                                return <div className="variable-exp" key={i}>
                                    {item.label}
                                    <i className="icon icon-close" style={{ color: "red", padding: "0px 5px" }} onClick={() => removeItem(item, "")} />
                                </div>
                            })
                        }
                    </div>

                    <div className="chart-types">
                        Select Charts
                        <FieldItem
                            name={"chart"}
                            value={values["chart"]}
                            values={outcomeVariable && outcomeVariable?.length > 0 && explanatoryVariable && explanatoryVariable?.length > 0 ?
                                outcomeVariable[0]?.type == "number" && explanatoryVariable[0]?.type == "number" ?
                                    chartTypes.filter(item => item.value == "boxplot" || item.value == "bar" || item.value == "scatterplot")
                                    :
                                    outcomeVariable[0]?.type != "number" && explanatoryVariable[0]?.type == "number" ?
                                        chartTypes.filter(item => item.value == "boxplot" || item.value == "bar")
                                        :
                                        outcomeVariable[0]?.type == "number" && explanatoryVariable[0]?.type != "number" ?
                                            chartTypes.filter(item => item.value == "boxplot" || item.value == "bar" || item.value == "errorbar")
                                            :
                                            outcomeVariable[0]?.type != "number" && explanatoryVariable[0].type != "number" ?
                                                chartTypes.filter(item => item.value == "stackedbarchart" || item.value == "clusterBarchart" || item.value == "horizontalbarchart" || item.value == "errorbar")
                                                : []
                                : []
                            }
                            label={""}
                            type={FIELD_TYPES.MULTI_SELECT}
                            onChange={(...e) => handleChange("chart", ...e)}
                        />
                    </div>

                    <div className="explanatory-variable" onClick={() => { SetpopupType("variable"); SetVaraibleType("spliton"); }}>
                        <i className="icon icon-add"></i>
                        Split On
                    </div>
                    <div className="explanatory-list">
                        {
                            splitonTypes && splitonTypes.map((item, i) => {
                                return <div className="variable-exp" key={i}>
                                    {item.label}
                                    <i className="icon icon-close" style={{ color: "red", padding: "0px 5px" }} onClick={() => removeItem(item.label, "spliton")} />
                                </div>
                            })
                        }
                        {/* {
                            <div className="variable-exp" >
                                {splitonTypes[0].label}
                                <i className="icon icon-close" style={{ color: "red", padding: "0px 5px" }} onClick={() => removeItem(splitonTypes[0].label, "spliton")} />
                            </div>

                        } */}
                    </div>

                    <div className="explanatory-variable" onClick={() => { SetpopupType("variable"); SetVaraibleType("feature"); }}>
                        <i className="icon icon-add"></i>
                        Feature
                    </div>
                    <div className="explanatory-list">
                        {
                            featureTypes && featureTypes.map((item, i) => {
                                return <div className="variable-exp" key={i}>
                                    {item}
                                    <i className="icon icon-close" style={{ color: "red", padding: "0px 5px" }} onClick={() => removeItem(item, "feature")} />
                                </div>
                            })
                        }
                    </div>

                    <div className="combined-data">
                        <FieldItem
                            name={"combineddata"}
                            value={values["combineddata"]}
                            values={[{ label: "Show combined table", value: true }]}
                            type={FIELD_TYPES.CHECK_BOX}
                            onChange={(...e) => handleChange("combineddata", ...e)}
                        />
                    </div>
                </div>
                <div className="add-btn">
                    <CustomButton type="primary" text="Add" onClick={() => addConfiguration()}></CustomButton>
                </div>
            </div>

        </>
    )
}