import { clone, cloneDeep, get, has } from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row } from "reactstrap";
import { authSelector, setLoading } from "../../store/slices/auth";
import { CustomButton, showToaster, toasterTypes } from "../../widgets";
import { FieldItem, FIELD_TYPES } from "../../widgets/fields";
import useFieldItem from "../../widgets/fields/UseFieldItem";
import ModalPopup from "../../widgets/modal";
import { uploadPhoto } from "../profile/apiController";
import dayjs from 'dayjs';
import {
  fetchNextVariable,
  postSearchIndex,
  submitIdentifierForm,
  updateIdentifierForm,
  validateIdentifierForm,
} from "./apiController";
import "./IdentifierLevel.css";
import { DatabaseFilled, DownloadOutlined } from "@ant-design/icons";
import { ModifyReasonModal } from "./ModifyReasonModal";
import ExistingDataModal from "./ExistingDataModal";
import TableViewData from "./TableViewData";
import { error } from "console";

interface PropsData {
  formFieldsData?: FormFieldsObject;
  selectedValues?: any;
  recordId: any;
  onUpload(): void | any;
}
interface FormFieldsObject {
  form_fields?: Array<FieldObject>;
}
interface FieldObject {
  id: string;
  field_type: string;
  field_name: string;
  field_use_type: string;
  field_selected_value?: any;
  field_value?: Array<Object>;
}
export default function IdentifierLevel(props: PropsData) {
  const [values, fields, handleChange, { validateValues, reset, updateValue, updateError, updateAllError, removeErrors }] =
    useFieldItem({}, {}, { onValueChange });
  const [formData, setformData] = useState<any | null>(null);
  const [editIndex, seteditIndex] = useState<any | null>(null);
  const [isSubmit, setisSubmit] = useState(false);
  const [isSubmitted, setisSubmitted] = useState(false);
  const [sourceFormId, setsourceFormId] = useState("");
  const [errorMobile, SeterrorMobile] = useState(false);
  const [errorEmail, SeterrorEmail] = useState(false);
  const [errorRange, SeterrorRange] = useState(false);
  const [errorMandatory, SeterrorMandatory] = useState(false);
  const [errorInteger, SeterrorInteger] = useState(false);
  const [startDate, SetstartDate] = useState(new Date());
  const [isDataEntered, SetDataEntered] = useState( props.selectedValues ? true : false);

  const [nextBtn, SetNextbtn] = useState("");
  var selectedParentIndex: any = null;
  var selectedChildIndex: any = null;
  const dispatch = useDispatch();
  const [isLocked, SetisLocked] = useState(false);
  const [existingData, SetexistingData] = useState<any | null>([]);
  const [isExist, SetisExist] = useState(false);
  const [existSelectedData, SetexistSelectedData] = useState<any | null>([]);
  const [isTableView, SetisTableView] = useState(false);
  const [actionEnabled, SetactionEnabled] = useState(false);
  const projectDataPerm = useSelector(authSelector)
  const [permissionData, setPermissionData] = useState({});


  /**
   * set version id
   */
  useEffect(() => {
    localStorage.setItem("versionid", Date.now().toString());
  }, [1])

  useEffect(() =>{
    let PrjId: any = props.formFieldsData;
    let perData = projectDataPerm.projectPermissionData?.filter(itm => itm.projectId == PrjId.projectId)
    let permArray = {};
    perData && perData[0]?.permissionData.forEach((obj) => {
        if (Object.keys(obj)[0] == "Data") {
            obj[Object.keys(obj)[0]].forEach((objItem) => {
                permArray[objItem.action] = objItem.permissions.filter(itemflt => itemflt.title == "allowed")[0]?.is_selected
            })
            //setPermission(obj[Object.keys(obj)[0]])
        } else if (Object.keys(obj)[0] == "Forms") {
            obj[Object.keys(obj)[0]].forEach((objItem) => {
                permArray[objItem.action] = objItem.permissions.filter(itemflt => itemflt.title == "allowed")[0]?.is_selected
            })
        }
    })
    setPermissionData(permArray)
  },[])
  /**
   * get intial data
   */
  useEffect(() => {
    console.log(props)
    let isTableData: any = props.formFieldsData;
    if (isTableData.isActionEnabled) {
      SetactionEnabled(isTableData.isActionEnabled)
      let temp: any = props.formFieldsData;
      setsourceFormId(temp?.form_fields[0][0]?.source_form_id);
      let isidentifier = false;
      if (temp?.form_fields[0][0]?.field_type === "dropDownPreviousResponses") {
        isidentifier = true;
      }
      //commented if condition on edit 
      if (props.selectedValues && isidentifier && temp.isPartial) {
        fetchFormData(temp);
        SetDataEntered(true);
      } else {
        temp.form_fields = [[]].concat(temp?.form_fields);
        temp.isTable = [false].concat(temp?.isTable);
        setformData(temp);
      }
    } else {

      let temp: any = props.formFieldsData;
      console.log(temp)

      let tempArray: any = []
      temp.form_fields = temp.form_fields.filter(item => item.length != 0);
      //temp.isTable.unshift(false);
      console.log(temp.form_fields)
      setsourceFormId(temp?.form_fields[0][0]?.source_form_id);
      let isidentifier = false;
      if (temp?.form_fields[0][0]?.field_type === "dropDownPreviousResponses") {
        isidentifier = true;
      }
      //commented if condition on edit 
      if (props.selectedValues && isidentifier && temp.isPartial) {
        fetchFormData(temp);
        SetDataEntered(true);
      } else {
        // temp.form_fields = [[]].concat(temp?.form_fields);
        setformData(temp);
      }
    }
  }, []);

  /**
   * switch to table view
   */
  // const switchTableView = (data) => {
  //   switch (data) {
  //     case "Allow Table Data Capture":
  //       SetisTableView(true);
  //       console.log(data)
  //       break;
  //     default:
  //       break;
  //   }
  // }

  /**
   * 
   * @param name 
   * @param value 
   * @param values 
   * @param fieldValues 
   * @returns 
   */
  function onValueChange(name, value, values, fieldValues = null) {
    console.log(values, value)
    console.log("selectedParentIndex::", selectedParentIndex)
    console.log("selectedChildIndex::", selectedChildIndex)
    SetDataEntered(true)
    let temp: any = cloneDeep(formData);
    temp.form_fields[selectedParentIndex][
      selectedChildIndex
    ].field_selected_value = value;
    setformData(temp);
    if (temp.form_fields[selectedParentIndex][
      selectedChildIndex
    ].field_type == "checkBox" || temp.form_fields[selectedParentIndex][
      selectedChildIndex
    ].field_type == "radioButton") {
      updateError(selectedChildIndex, { hasError: false, errorMessage: "" })
      SeterrorMandatory(false)

    }
    return [
      {
        ...values,
        [name]: value,
      },
    ];
  }

  /**
   * validate form data
   */
  async function onValidate() {
    try {
      dispatch(setLoading(true));
      let reqObj: any = [];
      let isEmpty = false;
      formData.form_fields[0].forEach((element) => {
        if (
          element.field_selected_value === undefined ||
          element.field_selected_value === ""
        ) {
          isEmpty = true;
          return;
        }
        reqObj.push({
          field_id: element.id,
          value:
            element.field_type === "dropDownPreviousResponses"
              ? element.field_selected_value.label
              : element.field_selected_value,
        });
      });
      if (isEmpty) {

        showToaster(toasterTypes.ERROR, "Please fill all the required fields");
      } else {
        let response = await validateIdentifierForm(sourceFormId, reqObj);
        if (response.success) {
          fetchFormData();
        }
      }
      dispatch(setLoading(false));
    } catch (e: any) {
      dispatch(setLoading(false));
    }
  }

  /**
   * validate data and call next api
   */
  async function getFetchData(tempFormData: any = "") {
    try {
      dispatch(setLoading(true));
      let fieldId: any = "";
      let answer: any = "";
      let tempForm: any = cloneDeep(tempFormData || formData);
      let isEmpty = false;
      console.log(fieldId)

      fieldId = fieldId.substring(0, fieldId.length - 1);
      answer = answer.substring(0, answer.length - 1);
      let response = await fetchNextVariable(tempForm.id, fieldId, answer);
      if (response?.data == null) {
        setisSubmit(true);
      }
      if (response?.data != null && response.success) {
        response.data.forEach((obj) => {
          if (obj.field_type === "dropDownPreviousResponses") {
            let options: any = [];

            obj.field_value.forEach((elem) => {
              options.push({
                label: elem.value,
                value: elem.version_id,
              });
            });

            if (props.selectedValues && props.selectedValues.length > 0) {
              let tempValue = props.selectedValues.filter(
                (elem) => elem.id === obj.id
              );
              let tempVal = options.filter(
                (elem) => elem.label === tempValue[0].field_selected_value
              );
              if (tempVal.length > 0) {
                obj.field_selected_value = tempVal[0];
              }
            }
            obj.field_value = options;
          } else if (obj.field_type === "boolean") {
            obj.field_selected_value = false;
            if (props.selectedValues && props.selectedValues.length > 0) {
              let tempValue = props.selectedValues.filter(
                (elem) => elem.id === obj.id
              );
              if (tempValue.length === 1) {
                obj.field_selected_value = tempValue[0].field_selected_value;
              }
            }
          } else if (
            obj.field_type === "dropDown" ||
            obj.field_type === "radioButton" ||
            obj.field_type === "checkBox"
          ) {
            let options: any = [];
            obj.field_value.forEach((elem) => {
              options.push({
                label: elem,
                value: elem,
              });
            });
            if (props.selectedValues && props.selectedValues.length > 0) {
              let tempValue = props.selectedValues.filter(
                (elem) => elem.id === obj.id
              );
              if (tempValue.length === 1) {
                if (obj.field_type === "checkBox") {
                  //pushing to array if the values for checkbox is String
                  let selectedData = tempValue[0]?.field_selected_value;
                  if (typeof selectedData == "string") {
                    console.log("-------------", typeof selectedData)
                    let dataArray: any = [];
                    dataArray.push(tempValue[0]?.field_selected_value)
                    selectedData = dataArray;
                  }
                  selectedData.forEach((elem) => {
                    let tempVal = options.filter(
                      (subElem) => subElem.label === elem
                    );
                    if (tempVal.length === 1) {
                      if (
                        obj.field_selected_value &&
                        obj.field_selected_value.length > 0
                      ) {
                        obj.field_selected_value.push(elem);
                      } else {
                        obj.field_selected_value = [elem];
                      }
                    }
                  });
                } else {
                  let tempVal = options.filter(
                    (elem) => elem.label === tempValue[0].field_selected_value
                  );
                  if (tempVal.length === 1) {
                    if (obj.field_type === "dropDown") {
                      obj.field_selected_value = tempVal[0];
                    } else {
                      obj.field_selected_value = tempVal[0].value;
                    }
                  }
                }
              }
            }
            obj.field_value = options;
          } else if (obj.field_type === "fileUpload") {
            //obj.field_selected_value = false;
            if (props.selectedValues && props.selectedValues.length > 0) {
              let tempValue = props.selectedValues.filter(
                (elem) => elem.id === obj.id
              );
              if (tempValue.length === 1) {
                obj.field_selected_value = tempValue[0].field_selected_value;
              }
            }
          } else {
            if (props.selectedValues && props.selectedValues.length > 0) {
              let tempValue = props.selectedValues.filter(
                (elem) => elem.id === obj.id
              );
              if (tempValue.length === 1) {
                obj.field_selected_value =
                  obj.field_type === "dateField"
                    ? new Date(tempValue[0].field_selected_value)
                    : tempValue[0].field_selected_value;
              }
            }
          }
        });
        tempForm.form_fields.push(response.data);
        setformData(tempForm);
      }
    } catch (e: any) {
      if (e?.response?.data?.data === null) {
        setisSubmit(true);
      }
      dispatch(setLoading(false));
    }
  }
  /**
   * on click next action validate form data
   * if conditions come true set complete state to true for data submission
   */
  async function fetchFormData(tempFormData: any = "") {
    let tempForm: any = cloneDeep(tempFormData || formData);
    console.log(tempForm)
    let check = await checkAllValidation(tempForm.form_fields[tempForm.form_fields.length - 1]);
    console.log(check)
    if (check) {
      try {
        dispatch(setLoading(true));
        let fieldId: any = "";
        let answer: any = "";
        let isEmpty = false;
        console.log(tempForm)
        tempForm.form_fields.forEach((obj) => {
          obj.forEach((subObj, i) => {
            subObj?.validations && subObj?.validations.map((validitaionKey) => {
              console.log(validitaionKey, subObj.field_selected_value);

              if (subObj.field_selected_value !== undefined &&
                subObj.field_selected_value !== "" && subObj.field_selected_value != null) {
                let checkBoValue = "";


                if (subObj.field_type === "checkBox") {
                  console.log(subObj.field_selected_value)
                  if (Array.isArray(subObj.field_selected_value)) {
                    checkBoValue = Array.prototype.map
                      .call(subObj.field_selected_value || [], (s) => s)
                      .toString();
                    checkBoValue = checkBoValue.replaceAll("|", ",");
                  }
                  console.log(subObj.field_selected_value)

                }
                fieldId = fieldId + subObj.id + ",";
                console.log(subObj)

                answer =
                  answer +
                  (subObj.field_type === "dropDownPreviousResponses" || subObj.field_type === "dropDown"
                    ? subObj.field_selected_value.label
                    : subObj.field_type == "checkBox" ? checkBoValue : subObj.field_selected_value) +
                  ",";
                console.log("Answer", answer)
                SetNextbtn("");
              } else {
                SetNextbtn("disabled");
                console.log("Empty error")
                updateError(subObj.id, { hasError: true, errorMsg: "Field cannot be empty" });
                isEmpty = true;
                return;
              }
              // }        
            })

            console.log(subObj.field_selected_value);

            if (subObj.field_selected_value == undefined) {
              SetNextbtn("");
              subObj.field_selected_value = "";
              if (subObj.field_type === "checkBox") {
                subObj.field_selected_value = Array.prototype.map
                  .call(subObj.field_selected_value || [], (s) => s)
                  .toString();
                subObj.field_selected_value =
                  subObj.field_selected_value.replaceAll("|", ",");
                console.log(subObj.field_selected_value);
              }

              fieldId = fieldId + subObj.id + ",";
              answer =
                answer +
                (subObj.field_type === "dropDownPreviousResponses" || subObj.field_type === "dropDown"
                  ? subObj.field_selected_value.label
                  : subObj.field_selected_value) +
                ",";
            } else {
              if (!subObj?.validations) {
                fieldId = fieldId + subObj.id + ",";

                answer =
                  answer +
                  (subObj.field_type === "dropDownPreviousResponses" || subObj.field_type === "dropDown"
                    ? subObj.field_selected_value.label
                    : subObj.field_selected_value) +
                  ",";
              }
            }

            // if (
            //   subObj.field_selected_value !== undefined &&
            //   subObj.field_selected_value !== ""
            // ) {


            // } else {

            //   isEmpty = true;
            //   return;
            // }
          });
          if (isEmpty) {
            return;
          }
        });

        console.log(isEmpty)

        if (isEmpty) {
          showToaster(toasterTypes.ERROR, "Please fill all the required fields");

        } else {
          isEmpty = false;
          console.log(fieldId)
          fieldId = fieldId.substring(0, fieldId.length - 1);
          answer = answer.substring(0, answer.length - 1);
          let response = await fetchNextVariable(tempForm.id, fieldId, answer);
          //switch condition for view type
          // response.view_type && switchTableView(response.view_type)

          if (response?.data == null) {
            setisSubmit(true);
          }
          if (response?.data != null && response.success) {
            response.data.forEach((obj) => {

              if (obj.field_type === "dropDownPreviousResponses") {
                let options: any = [];
                obj.field_value.forEach((elem) => {
                  options.push({
                    label: elem.value,
                    value: elem.version_id,
                  });
                });
                if (props.selectedValues && props.selectedValues.length > 0) {
                  let tempValue = props.selectedValues.filter(
                    (elem) => elem.id === obj.id
                  );

                  //added if partial modify 
                  console.log(tempValue)

                  if (tempForm.isPartial && tempValue.length > 0 && tempValue[0].field_selected_value) {
                    obj.isPartial = true
                  }
                  let tempVal = options.filter(
                    (elem) => elem.label == tempValue[0].field_selected_value
                  );
                  console.log(tempVal)

                  if (tempVal.length > 0) {
                    obj.field_selected_value = tempVal[0];
                  }
                }
                obj.field_value = options;
                if (Object.keys(existSelectedData).length > 0) {
                  existSelectedData && existSelectedData.fields.map((elem) => {
                    if (obj.id == elem.id) {
                      obj.field_selected_value = { label: elem.field_selected_value, value: elem.field_selected_value }
                    }

                  })
                }
              } else if (obj.field_type === "boolean") {

                obj.field_selected_value = false;
                if (props.selectedValues && props.selectedValues.length > 0) {
                  let tempValue = props.selectedValues.filter(
                    (elem) => elem.id === obj.id
                  );
                  console.log(tempValue)

                  if (tempForm.isPartial && tempValue.length > 0 && tempValue[0].field_selected_value) {
                    obj.isPartial = true
                  }
                  if (tempValue.length === 1) {
                    obj.field_selected_value = tempValue[0].field_selected_value;
                  }
                }
                if (Object.keys(existSelectedData).length > 0) {

                  existSelectedData && existSelectedData.fields.map((elem) => {
                    if (obj.id == elem.id) {
                      obj.field_selected_value = elem.field_selected_value
                    }

                  })
                }
              }
              else if (
                obj.field_type === "dropDown" ||
                obj.field_type === "radioButton" ||
                obj.field_type === "checkBox"
              ) {

                let options: any = [];
                obj.field_value.forEach((elem) => {
                  options.push({
                    label: elem,
                    value: elem,
                  });
                });
                console.log("Dropdown,radio,check--->", props.selectedValues)

                if (props.selectedValues && props.selectedValues.length > 0) {

                  let tempValue = props.selectedValues.filter(
                    (elem) => elem.id === obj.id
                  );
                  if (tempForm.isPartial && tempValue.length > 0 && tempValue[0].field_selected_value) {
                    obj.isPartial = true
                  }
                  if (tempValue.length === 1) {
                    if (obj.field_type === "checkBox") {

                      if (tempValue[0].field_selected_value && typeof tempValue[0].field_selected_value == "string") {
                        tempValue[0].field_selected_value = [tempValue[0].field_selected_value]
                      }

                      tempValue[0].field_selected_value && tempValue[0].field_selected_value.forEach((elem) => {
                        let tempVal = options.filter(
                          (subElem) => subElem.label === elem
                        );
                        if (tempVal.length === 1) {
                          if (
                            obj.field_selected_value &&
                            obj.field_selected_value.length > 0
                          ) {
                            obj.field_selected_value.push(elem);
                          } else {
                            obj.field_selected_value = [elem];
                          }
                        }
                      });
                      //check existing data

                    } else {
                      let tempVal = options.filter(
                        (elem) => elem.label === tempValue[0].field_selected_value
                      );
                      if (tempVal.length === 1) {
                        if (obj.field_type === "dropDown") {
                          obj.field_selected_value = tempVal[0];
                        } else {
                          obj.field_selected_value = tempVal[0].value;
                        }
                      }



                    }
                  }
                }

                if (obj.field_type === "checkBox" || obj.field_type === "radioButton") {

                  if (Object.keys(existSelectedData).length > 0) {
                    existSelectedData && existSelectedData.fields.map((elem) => {
                      if (obj.id == elem.id) {
                        obj.field_selected_value = elem.field_selected_value
                      }
                    })
                  }
                } else if (obj.field_type === "dropDownPreviousResponses" || obj.field_type === "dropDown") {
                  if (Object.keys(existSelectedData).length > 0) {

                    existSelectedData && existSelectedData.fields.map((elem) => {
                      if (obj.id == elem.id) {
                        obj.field_selected_value = { label: elem.field_selected_value, value: elem.field_selected_value }
                      }
                    })
                  }
                } else {

                }

                obj.field_value = options;

              }
              else if (obj.field_type === "fileUpload") {

                if (props.selectedValues && props.selectedValues.length > 0) {
                  let tempValue = props.selectedValues.filter(
                    (elem) => elem.id === obj.id && elem.field_selected_value && elem.field_selected_value != ""
                  );

                  if (tempForm.isPartial && tempValue.length > 0 && tempValue[0].field_selected_value) {
                    obj.isPartial = true
                  }
                  if (tempValue.length === 1) {
                    obj.field_selected_value = tempValue[0].field_selected_value;
                    console.log("FIrst", tempValue[0].field_selected_value);

                  }
                }
                if (Object.keys(existSelectedData).length > 0) {
                  existSelectedData && existSelectedData.fields.map((elem) => {
                    if (obj.id == elem.id) {
                      obj.field_selected_value = elem.field_selected_value
                    }
                  })
                }
              } else {

                if (props.selectedValues && props.selectedValues.length > 0) {
                  let tempValue = props.selectedValues.filter(
                    (elem) => elem.id === obj.id
                  );

                  if (tempForm.isPartial && tempValue.length > 0 && tempValue[0].field_selected_value) {
                    obj.isPartial = true
                  }


                  if (tempValue.length === 1) {
                    obj.field_selected_value =
                      obj.field_type === "dateField"
                        ? new Date(tempValue[0].field_selected_value)
                        : tempValue[0].field_selected_value;
                  }
                }

                if (Object.keys(existSelectedData).length > 0) {

                  existSelectedData && existSelectedData.fields.map((elem) => {
                    if (obj.id == elem.id) {
                      obj.field_selected_value = elem.field_selected_value
                    }
                  })
                }

              }
            });
            console.log("props.selectedValues", props.selectedValues)
            if (!props.selectedValues) {
              if (response.view_type == "Allow Table Data Capture") {
                tempForm.isTable.push(true)
              } else {
                tempForm.isTable.push(false)
              }
            } else {
              tempForm.isTable.push(false)
            }

            console.log(response.data)
            tempForm.form_fields.push(response.data);
            console.log(tempForm)
            setformData(tempForm);
          }

        }
        dispatch(setLoading(false));
      } catch (e: any) {
        if (e?.response?.data?.data === null) {
          setisSubmit(true);
        }
        dispatch(setLoading(false));
      }
    }
  }

  /**
   * 
   * @returns 
   */
  const getArrayValues = () => {

    let temp: any = cloneDeep(formData);

    if (!actionEnabled) {
      console.log(temp)
      if (temp.form_fields.length > 1) {
        temp = temp.form_fields.splice(1);
      } else {
        temp = temp.form_fields
      }
    } else {
      //temp.isTable.splice(1)
      let fields: Array<unknown> = [];
      // console.log(temp.isTable)
      temp.isTable.map((elem, index) => {
        if (!elem) {
          fields.push(temp.form_fields[index])
        }
      })
      temp = fields
      //console.log("TEMPACtion",fields)

    }
    //console.log(temp)
    return temp;
  };

  /**
   * on change action for form fields
   * @param e 
   * @param index 
   */
  const onEdit = (data) => {
    console.log("Edit", editIndex)
    seteditIndex(null);
    SeterrorEmail(false);
    SeterrorMandatory(false);
    SeterrorMobile(false);
    SeterrorRange(false);
    SeterrorInteger(false);

    let temp: any = cloneDeep(data);
    console.log(data)

    temp.form_fields = temp.form_fields.splice(0, editIndex + 1);
    temp.isTable = temp.isTable.splice(0, editIndex + 1);
    console.log(temp)
    setformData(temp);
    removeErrors(editIndex + 1)
    setformData(temp);


  };

  useEffect(() => { }, [formData])

  //Clear all error message on click of edit
  const clearError = (temp) => {
    temp.map((obj, i) => {
      obj.map((items, j) => {
        console.log(items, j)
        updateError(j, { hasError: false, errorMsg: "" });
        return
      })
    })
  }

  /**
   * 
   * @param data 
   * @param resolve 
   * @param reject 
   */
  const uploadDoc = async (data, resolve, reject) => {
    try {
      dispatch(setLoading(true));
      let response = await uploadPhoto(data);
      if (response.mediaUrl) {
        resolve(response.mediaUrl);
      }
      dispatch(setLoading(false));
    } catch (e: any) {
      reject("");
      dispatch(setLoading(false));
    }
  };
  /**
   * create promise all function to upload images
   */
  const uploadDocs = (formData) => {
    return new Promise(async (resolve, reject) => {
      await uploadDoc(formData, resolve, reject);
    })
  }

  /**
   * save data
   * @param reason 
   */
  const save = async (reason) => {
    try {
      dispatch(setLoading(true));
      let fieldsArray: any = [];
      let tempData: any = cloneDeep(formData.form_fields);
      console.log("Temp-----", tempData)
      let uploadArray: any = [];
      tempData.forEach((obj, index) => {

        obj.forEach(async (subObj, subIndex) => {
          if (subObj.field_type === "fileUpload") {
            if (typeof subObj?.field_selected_value != "string") {
              const formData = new FormData();
              formData.append("file", subObj?.field_selected_value && subObj?.field_selected_value[0]);
              // subObj?.field_selected_value && await new Promise(async (resolve, reject) => {
              //   await uploadDoc(formData, resolve, reject);
              // })
              //   .then((result: any) => {
              //     console.log("Image Link------>",result)
              //     subObj.field_selected_value = result;
              //   })
              //   .catch((error) => {
              //     subObj.field_selected_value = error;
              //   });
              subObj?.field_selected_value && uploadArray.push(uploadDocs(formData))
            }
          } else if (
            subObj.field_type === "dropDown" ||
            subObj.field_type === "dropDownPreviousResponses"
          ) {
            console.log(subObj)
            subObj.field_selected_value = subObj.field_selected_value && subObj.field_selected_value.label;
          }
          fieldsArray.push({
            id: subObj.id || subObj.fieldId,
            field_selected_value: subObj.field_selected_value,
          });


          if (
            tempData.length === index + 1 &&
            tempData[index].length === subIndex + 1
          ) {
            let versionid: number = Number(localStorage.getItem("versionid"));
            let endDate = new Date();

            console.log('=======', uploadArray)
            //if all documents uploaded will submit form
            const Docresults = await Promise.all(uploadArray);
            if (Docresults) {
              let i = 0;
              //update file paths
              obj.forEach((subImageId) => {
                if (subImageId.field_type === "fileUpload") {
                  let objIndex = fieldsArray.findIndex(obj => obj.id == subImageId.id || subImageId.fieldId);
                  fieldsArray[objIndex].field_selected_value = Docresults[i]
                  i++;
                }
              })
              console.log("FInalll-----", fieldsArray)

              //get time zone
              const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;

              let reqObj: any = {
                form_id: formData.id,
                version_id: versionid,
                fields: fieldsArray,
                start_time: startDate,
                end_time: endDate,
                update_remarks: reason,
                time_zone: tz
              };
              let response;
              if (props.selectedValues) {
                reqObj.id = props.recordId;
                console.log(props?.formFieldsData && props?.formFieldsData["islocked"])

                response = await updateIdentifierForm(reqObj);

              } else {
                response = await submitIdentifierForm(reqObj);
              }
              if (response.success) {
                showToaster(toasterTypes.SUCCESS, response.message);
                setisSubmit(false);
                setisSubmitted(true);
                dispatch(setLoading(false));
              }
            }
          }
        });
      });
    } catch (e: any) {
      dispatch(setLoading(false));
    }
  };

  /**
   * onTable data submited
   */
  const onTableSubmit = (isSub, isSubmitd) => {
    setisSubmit(isSub);
    setisSubmitted(isSubmitd);
  }

  /**
  * validate fields on frontend or backend depending on the field type
  * @param obj 
  * @param validate 
  * @param selectedValue 
  */
  const checkAllValidation = (data) => {
    let errorObj: any = [];
    console.log(data)
    data.map((obj, index) => {
      obj.validations?.map((items, i) => {
        if (items.startsWith('FE')) {
          const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
          const numberRegex = /^(\+?[1-9][0-9]{0,3}[\-\s]?)?([0-9]{6,14})$/;

          if (items == "FE-EmailFormat") {
            if (obj.field_selected_value?.length != 0) {
              if (!emailRegex.test(obj.field_selected_value)) {
                SeterrorEmail(true);
                errorObj.push({ name: index, data: { hasError: true, errorMsg: obj.field_selected_value == undefined || obj.field_selected_value == "" ? 'Email cannot be empty' : 'Please entry valid Email' } })
              } else {
                SeterrorEmail(false);
                errorObj.push({ name: index, data: { hasError: false, errorMsg: "" } })

              }
            }
          } else if (items == "FE-Integer") {
            var result = (obj.field_selected_value - Math.floor(obj.field_selected_value)) !== 0;
            if (result) {
              SeterrorInteger(true);
              errorObj.push({ name: index, data: { hasError: true, errorMsg: 'Please enter valid integer' } })
            } else {
              SeterrorInteger(false);
              errorObj.push({ name: index, data: { hasError: false, errorMsg: "" } })
            }
          }
          else if (items == "FE-MobileNumber") {
            if (obj.field_selected_value?.length != 0 && obj.field_selected_value != null) {
              console.log(obj.field_selected_value, numberRegex.test(obj.field_selected_value))
              if (!numberRegex.test(obj.field_selected_value)) {
                console.log("Error Mobile", index)
                SeterrorMobile(true);
                errorObj.push({ name: index, data: { hasError: true, errorMsg: obj.field_selected_value == undefined || obj.field_selected_value == "" ? 'Mobile cannot be empty' : 'Please entry valid Mobile' } })
                updateError(index, { hasError: true, errorMsg: obj.field_selected_value == undefined || obj.field_selected_value == "" ? 'Mobile cannot be empty' : 'Please entry valid Mobile' });
              } else {
                SeterrorMobile(false);
                errorObj.push({ name: index, data: { hasError: false, errorMsg: "" } })

              }
            }
          } else if (items == "FE-Mandatory") {
            console.log(index, obj.field_selected_value)
            if (obj.field_selected_value?.length == 0 || obj.field_selected_value == 'undefined' || obj.field_selected_value == null) {
              SeterrorMandatory(true);
              errorObj.push({ name: index, data: { hasError: true, errorMsg: 'Field cannot be empty' } })
            } else {
              SeterrorMandatory(false);
              errorObj.push({ name: index, data: { hasError: false, errorMsg: "" } })

            }
          } else if (items == "FE-Range") {
            const min = obj.field_min_value;
            const max = obj.field_max_value;
            // Check the range
            if (parseInt(obj.field_selected_value) < parseInt(min) || parseInt(obj.field_selected_value) > parseInt(max)) {
              SeterrorRange(true);
              errorObj.push({ name: index, data: { hasError: true, errorMsg: `Values should be between ${min} to ${max}` } })
            } else {
              SeterrorRange(false);
              errorObj.push({ name: index, data: { hasError: false, errorMsg: "" } })
            }

          } else {

          }


        } else if (items.startsWith('BE')) {
          let versionid: number = Number(localStorage.getItem("versionid"));

          const resObj = {
            field_id: obj.id,
            value: obj.field_selected_value,
            version_id: versionid
          }

          //console.log(resObj);
          const response = validateIdentifierForm("", [resObj]);
          response.then(respData => {
            if (respData.success == true) {
              errorObj.push({ name: index, data: { hasError: false, errorMsg: "" } })
            }
          }).catch(err => {
            errorObj.push({ name: index, data: { hasError: true, errorMsg: "Please enter unique data" } })

          })
        } else {

        }
      });
    })
    updateAllError(errorObj)
    console.log("ERRRORS::--)))", errorObj)
    let chkArr = errorObj.filter(item => item.data.hasError == true)
    console.log("CHCARRAY", chkArr)
    if (chkArr.length > 0) {
      SetDataEntered(false)
      return false;
    } else {
      SetDataEntered(true);
      return true;
    }
  }

  /**
   * on blur validate fields data
   * @param obj 
   * @param index 
   */
  const checkFrontEndValidation = (obj, index) => {
    console.log(obj)
    let errorObj: string = "";
    console.log("1ERRRRRR---->>>", index, errorObj, obj[index])
    obj[index]?.validations &&
      obj[index]?.validations.map((valid) => {
        if (valid.startsWith('FE')) {
          const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
          const numberRegex = /^(\+?[0-9]{0,3}[\-\s]?)?([0-9]{6,14})$/;
          if (valid == "FE-EmailFormat") {
            if (!emailRegex.test(obj[index]?.field_selected_value)) {
              SetNextbtn("disabled");
              SeterrorEmail(true);
              getArrayValues()[getArrayValues().length - 1].filter(items => items.id == obj[index].id).map(item => item.isError = true);
              obj.isError = true;
              errorObj += (obj[index]?.field_selected_value == undefined || obj[index]?.field_selected_value == "" ? 'Email cannot be empty' : 'Please entry valid Email')

              // updateError(index, { hasError: true, errorMsg: obj[index]?.field_selected_value == undefined || obj[index]?.field_selected_value == "" ? 'Email cannot be empty' : 'Please entry valid Email' });
              return;
            } else {
              obj.isError = false;
              getArrayValues()[getArrayValues().length - 1].filter(items => items.id == obj[index].id).map(item => item.isError = false);
              SetNextbtn("");
              SeterrorEmail(false);
              //updateError(index, { hasError: false, errorMsg: "" });
              return
            }
          }
          else if (valid == "FE-MobileNumber") {
            if (obj[index]?.field_selected_value?.length != 0) {
              if (!numberRegex.test(obj[index]?.field_selected_value)) {
                SetNextbtn("disabled");
                SeterrorMobile(true);
                obj.isError = true;
                getArrayValues()[getArrayValues().length - 1].filter(items => items.id == obj[index].id).map(item => item.isError = true);
                errorObj += (obj[index].field_selected_value == undefined || obj[index].field_selected_value == "" ? 'Mobile cannot be empty' : 'Please entry valid Mobile')
                //updateError(index, { hasError: true, errorMsg: obj[index].field_selected_value == undefined || obj[index].field_selected_value == "" ? 'Mobile cannot be empty' : 'Please entry valid Mobile' });
                return
              } else {
                SetNextbtn("");
                SeterrorMobile(false);
                obj.isError = false;
                getArrayValues()[getArrayValues().length - 1].filter(items => items.id == obj[index].id).map(item => item.isError = false);
                errorObj = "";
                //updateError(index, { hasError: false, errorMsg: "" });
                return
              }
            }
          } else if (valid == "FE-Integer") {
            var result = (obj[index]?.field_selected_value - Math.floor(obj[index]?.field_selected_value)) !== 0;

            if (result) {
              SetNextbtn("disabled");
              SeterrorInteger(true);
              obj.isError = true;
              getArrayValues()[getArrayValues().length - 1].filter(items => items.id == obj[index].id).map(item => item.isError = true);
              errorObj += 'Field cannot be decimal number'
              // updateError(index, { hasError: true, errorMsg: 'Field cannot be decimal number' });
              return;
            } else {
              getArrayValues()[getArrayValues().length - 1].filter(items => items.id == obj[index].id).map(item => item.isError = false);
              SetNextbtn("");
              SeterrorInteger(false);
              obj.isError = false;
              errorObj = "";
              //updateError(index, { hasError: false, errorMsg: '' });
              return;
            }
          } else if (valid == "FE-Mandatory") {
            if (obj[index]?.field_selected_value?.length == 0 || obj[index].field_selected_value == 'undefined' || obj[index].field_selected_value == null) {
              SetNextbtn("disabled");
              SeterrorMandatory(true);
              obj.isError = true;
              getArrayValues()[getArrayValues().length - 1].filter(items => items.id == obj[index].id).map(item => item.isError = true);
              errorObj += 'Field cannot be empty'
              //updateError(index, { hasError: true, errorMsg: 'Field cannot be empty' });
              return;
            } else {
              getArrayValues()[getArrayValues().length - 1].filter(items => items.id == obj[index].id).map(item => item.isError = false);
              SetNextbtn("");
              SeterrorMandatory(false);
              obj.isError = false;
              //updateError(index, { hasError: false, errorMsg: '' });
              return;
            }

            // if(obj[index].field_type == "checkBox" && obj[index].field_selected_value.length > 0){
            //   alert("d")
            //   SeterrorMandatory(false);
            // }

          } else if (valid == "FE-Range") {
            const min = obj[index].field_min_value;
            const max = obj[index].field_max_value;
            console.log("YYYYYYY")
            // Check the range
            if (parseInt(obj[index].field_selected_value) < parseInt(min) || parseInt(obj[index].field_selected_value) > parseInt(max)) {
              SetNextbtn("disabled");
              SeterrorRange(true);
              obj.isError = true;
              errorObj += `Values should be between ${min} to ${max}`
              // updateError(index, { hasError: true, errorMsg: `Values should be between ${min} to ${max}` });
              return;
            } else {
              SetNextbtn("");
              SeterrorRange(false);
              obj.isError = false;
              getArrayValues()[getArrayValues().length - 1].filter(items => items.id == obj[index].id).map(item => item.isError = false);
              // updateError(index, { hasError: false, errorMsg: '' });
              return;
            }

          } else {

          }



        } else if (valid.startsWith('BE')) {
          let versionid: number = Number(localStorage.getItem("versionid"));

          const resObj = {
            field_id: obj[index].id,
            value: obj[index].field_selected_value,
            version_id: versionid
          }

          console.log(resObj);
          const response = validateIdentifierForm("", [resObj]);
          response.then(respData => {

          }).catch(err => {
            errorObj += "Please enter unique data"
            //updateError(index, {hasError:true, errorMsg: "Please enter valid data"});

          })
        } else {

        }
      })

    if (errorObj == "") {
      updateError(index, { hasError: false, errorMsg: "" });
      updateAllError([{ name: index, data: { hasError: false, errorMsg: "" } }])
      SetDataEntered(true)
      SeterrorEmail(false)
      SeterrorMandatory(false)
      SeterrorMobile(false)
      SeterrorRange(false)
      SeterrorInteger(false)
    } else {
      updateError(index, { hasError: true, errorMsg: errorObj });
    }


  }

  const getReason = (reason) => {
    console.log(reason)
    save(reason)
    SetisLocked(false)
  }

  const onClose = () => {
    SetisLocked(false)
    SetisExist(false)

  }

  /**
  * get category to print
  */
  let cat = "";
  const getCategory = (data) => {
    if (data.field_category != cat) {
      cat = data.field_category;
      return true
    } else {
      return false
    }
  }

  /**
 * get serach index 
 */
  const getSearchIndex = async (data) => {
    console.log(formData)

    let temp: any = localStorage.getItem("projectData");
    temp = JSON.parse(temp);
    let searchVal: any
    if (data.field_type == "dropDownPreviousResponses" || data.field_type == "dropDown") {
      searchVal = values[data.id].label
      console.log(values[data.id])
    } else {
      searchVal = values[data.id]

    }

    let Obj = [{ "field_id": data.id, "search_value": searchVal, "field_type": data.field_type }]
    try {
      dispatch(setLoading(true));
      let response = await postSearchIndex(temp.TopicID, formData.id, Obj,permissionData['all data view']);
      if (response) {
        SetexistingData(response.data)
        let checkData = response.data.filter(elem => elem.count_info.length > 0)
        console.log(checkData)
        if (checkData.length > 0) {
          SetisExist(true)
        } else {
          showToaster(toasterTypes.ERROR, "No data found")
        }
      }
      dispatch(setLoading(false));

    } catch (error) {
      dispatch(setLoading(false));
      console.log(error)
    }
  }



  /**
   * get data from search index
   */
  const getExistingData = async (data) => {
    console.log("=====", data)
    SetexistSelectedData(data)
    data && data.fields.map((elem) => {
      if (elem.field_type == "textBox") {
        updateValue({ [elem.id]: elem.field_selected_value })
        formData.form_fields.map((obj) => {
          if (obj.id == elem.id) {
            obj.field_selected_value = elem.field_selected_value
          }
        })
      } else if (elem.field_type == "dropDown") {
        updateValue({ [elem.id]: { label: elem.field_selected_value, value: elem.field_selected_value } })
        formData.form_fields.map((obj) => {
          if (obj.id == elem.id) {
            obj.field_selected_value = { label: elem.field_selected_value, value: elem.field_selected_value }
          }
        })
      } else if (elem.field_type == "checkBox" || elem.field_type == "radioButton") {
        updateValue({ [elem.id]: elem.field_selected_value })
        formData.form_fields.map((obj) => {
          if (obj.id == elem.id) {
            obj.field_selected_value = elem.field_selected_value
          }
        })
      } else {
        updateValue({ [elem.id]: elem.field_selected_value })
        formData.form_fields.map((obj) => {
          if (obj.id == elem.id) {
            obj.field_selected_value = elem.field_selected_value
          }
        })
      }

      console.log(formData)
    })
  }



  const onEditTable = (id, data) => {
    SetisTableView(false)
    seteditIndex(null);
    SeterrorEmail(false);
    SeterrorMandatory(false);
    SeterrorMobile(false);
    SeterrorRange(false);
    SeterrorInteger(false);

    let temp: any = cloneDeep(data);
    let idval = id - 1
    temp.form_fields = temp.form_fields.splice(0, idval + 1);
    temp.isTable = temp.isTable.splice(0, idval + 1);
    setformData(temp);
    removeErrors(idval + 1)
    setformData(temp);
  }

  useEffect(() => {
  }, [isTableView])

  return (
    <div>
      {
        isLocked ?
          <ModifyReasonModal getReasonData={getReason} isClose={() => onClose()} />
          :
          ""
      }

      {
        isExist ?
          <ExistingDataModal isClose={() => onClose()} existingData={existingData} getExistingData={getExistingData} />
          :
          ""
      }

      {isSubmitted ? (
        <div className="survey-form">
          <Row className="m-0 mb-3">
            <Col className="p-0" lg={6} md={12} xs={12}>
              <div className="welcome-text">Welcome</div>
              <div className="survey-title">{formData.form_name}</div>
              <div className="survey-description">{formData.form_desc}</div>
            </Col>
            <Col className="p-0" lg={6} md={12} xs={12}>
              <img
                className="survey-tool"
                src={`${process.env.PUBLIC_URL}/images/survey_tools.svg`}
                alt="logo"
              />
            </Col>
          </Row>
          <div className='questions-container row flex-column m-0 justify-content-center align-items-center pt-0'>
            <img src={`${process.env.PUBLIC_URL}/images/awesome.svg`} alt="awesome" className="awesome-img" />
            <div className='completed-text1'>Successfully submitted your form.</div>
          </div>
        </div>
      ) : (
        formData &&
        (formData.form_fields[0].length === 0 || formData.isIdentifier ||
          formData.form_fields[0][0].field_use_type.includes("Identifier")) && (
          <>

            {isSubmit && (
              <ModalPopup
                modalType="delete"
                modalText="There are no more actions as per the answers submitted."
                actionText="Do you want to submit the form?"
                closeModal={() => setisSubmit(false)}
                onAction={() => { props?.formFieldsData && props?.formFieldsData["islocked"] ? SetisLocked(true) : save("") }}
              />
            )}

            {editIndex !== null && editIndex >= 0 && (
              <ModalPopup
                modalType="delete"
                modalText="Are you sure, you want to edit?"
                actionText="This will erase the data of next variables"
                closeModal={() => seteditIndex(null)}
                onAction={() => onEdit(formData)}
              />
            )}


            {/* execute this if modify */}
            {
              props.selectedValues &&
              formData && formData.isTable.map((obj, tabindex) => (
                !obj ?
                  actionEnabled ?
                    <>
                      {/* this section if for action enabled true and root form */}
                      {formData.form_fields[0].length > 0 &&
                        formData.form_fields[tabindex][0].field_type ===
                        "dropDownPreviousResponses" && (
                          <Row>
                            {formData.form_fields[0].map((obj, index) => (
                              <>
                                {
                                  getCategory(obj) ?
                                    <Col md={12}><h5 style={{ borderBottom: "1px solid grey" }}>{obj.field_category}</h5></Col>
                                    : ""
                                }
                                <Col md={obj.field_type === "checkBox" ? 12 : 6} xs={10} key={index}>
                                  <FieldItem
                                    name={obj.id}
                                    placeholder={
                                      obj.field_type === "number" ||
                                        obj.field_type === "textBox" ||
                                        obj.field_type === "textArea"
                                        ? "Enter"
                                        : "Select"
                                    }
                                    label={obj.field_name}
                                    isDisabled={formData.form_fields.length > 1}
                                    type={
                                      obj.field_type === "fileUpload"
                                        ? FIELD_TYPES.FILE_UPLOAD
                                        : obj.field_type === "dateField"
                                          ? FIELD_TYPES.DATEPICKER_NEW
                                          : obj.field_type === "dropDown"
                                            ? FIELD_TYPES.DROP_DOWN
                                            : obj.field_type === "dropDownPreviousResponses"
                                              ? FIELD_TYPES.DROP_DOWN
                                              : obj.field_type === "boolean"
                                                ? FIELD_TYPES.BOOLEAN
                                                : obj.field_type === "radioButton"
                                                  ? FIELD_TYPES.RADIO_BUTTON
                                                  : obj.field_type === "checkBox"
                                                    ? FIELD_TYPES.CHECK_BOX
                                                    : obj.field_type === "textArea"
                                                      ? FIELD_TYPES.TEXT_AREA
                                                      : obj.field_type === "textBox"
                                                        ? FIELD_TYPES.TEXT
                                                        : obj.field_type === "time"
                                                          ? FIELD_TYPES.TIME_PICKER
                                                          : obj.field_type === "dateTime"
                                                            ? FIELD_TYPES.DATE_TIME_PICKER
                                                            : FIELD_TYPES.TEXT
                                    }
                                    isNumber={obj.field_type === "number" ? true : false}
                                    value={obj.field_type == "dateField" ? obj.field_selected_value ? dayjs(obj.field_selected_value) : null : obj.field_selected_value}
                                    values={obj.field_value}
                                    isListed={true}
                                    isMandatory={false}
                                    onChange={(...e) => {
                                      selectedParentIndex = 0;
                                      selectedChildIndex = index;
                                      handleChange(obj.id, ...e);
                                    }}
                                  />
                                  {obj.field_type === "fileUpload" && typeof (obj.field_selected_value && obj.field_selected_value) != "object" ?
                                    <span style={{ fontSize: "12px", paddingBottom: "10px" }}>{obj.field_selected_value && obj.field_selected_value.substring(obj.field_selected_value.lastIndexOf('/') + 1)}
                                      <a href={obj.field_selected_value} download><DownloadOutlined style={{ fontSize: "20px", padding: "0px 5px" }} /> </a>
                                    </span>
                                    : ""}
                                </Col>
                                <Col md={1} xs={2}>
                                  {
                                    obj.field_use_type.includes("SearchIndex") ?
                                      <div style={window.innerWidth > 700 ? { marginTop:'30%', cursor: "pointer" } : { marginTop:'100%', cursor: "pointer" }} onClick={() => getSearchIndex(obj)}><DatabaseFilled style={{ color: "#007bff", fontSize: "20px" }} /></div>
                                      : ""
                                  }
                                </Col>
                              </>
                            ))}
                            <Col
                              xs={12}
                              className="w-100 d-flex m-0 mt-4 mb-4 justify-content-end"
                            >
                              {formData.form_fields.length === 1 && (
                                <CustomButton
                                  type="primary"
                                  onClick={() => onValidate()}
                                  text="Fetch"
                                ></CustomButton>
                              )}
                            </Col>
                          </Row>
                        )}
                      {formData.form_fields[0].length > 0 &&
                        formData.form_fields[tabindex][0].field_type ===
                        "dropDownPreviousResponses" &&
                        (
                          <div className="edit-border">
                            <div className="edit-box">
                              <div className="edit-text" onClick={() => seteditIndex(0)}>
                                Edit
                              </div>
                            </div>
                          </div>
                        )}


                      {tabindex == 1 && formData.form_fields.length > 1 &&
                        getArrayValues().map((obj, index) => (
                          <Row key={index}>
                         
                            {obj.map((subObj, subIndex) => (
                              <>

                                {
                                  getCategory(subObj) ?
                                    <Col md={12}><h5 style={{ borderBottom: "1px solid grey" }}>{subObj.field_category}</h5></Col>
                                    : ""
                                }
                                <Col md={obj.field_type === "checkBox" ? 12 : subObj.field_use_type.includes("SearchIndex") ? 5 : 6} xs={subObj.field_use_type.includes("SearchIndex") ? 10 : 12} key={subIndex}>

                                  <FieldItem
                                    className={'dynamic-item'}
                                    name={subObj.id}
                                    placeholder={
                                      subObj.field_type === "number" ||
                                        subObj.field_type === "textBox" ||
                                        subObj.field_type === "textArea"
                                        ? "Enter"
                                        : "Select"
                                    }
                                    label={subObj.field_name}
                                    isDisabled={subObj.isPartial ? true : index + 1 !== formData.form_fields.length}
                                    type={
                                      subObj.field_type === "fileUpload"
                                        ? FIELD_TYPES.FILE_UPLOAD
                                        : subObj.field_type === "dateField"
                                          ? FIELD_TYPES.DATEPICKER_NEW
                                          : subObj.field_type === "dropDown"
                                            ? FIELD_TYPES.DROP_DOWN
                                            : subObj.field_type === "dropDownPreviousResponses"
                                              ? FIELD_TYPES.DROP_DOWN
                                              : subObj.field_type === "boolean"
                                                ? FIELD_TYPES.BOOLEAN
                                                : subObj.field_type === "radioButton"
                                                  ? FIELD_TYPES.RADIO_BUTTON
                                                  : subObj.field_type === "checkBox"
                                                    ? FIELD_TYPES.CHECK_BOX
                                                    : subObj.field_type === "textArea"
                                                      ? FIELD_TYPES.TEXT_AREA
                                                      : subObj.field_type === "textBox"
                                                        ? FIELD_TYPES.TEXT
                                                        : subObj.field_type === "time"
                                                          ? FIELD_TYPES.TIME_PICKER
                                                          : subObj.field_type === "dateTime"
                                                            ? FIELD_TYPES.DATE_TIME_PICKER
                                                            : FIELD_TYPES.TEXT
                                    }
                                    isNumber={subObj.field_type === "number" ? true : false}
                                    value={subObj.field_type == "dateField" ? subObj.field_selected_value ? dayjs(subObj.field_selected_value) : null : subObj.field_selected_value}
                                    values={subObj.field_value}
                                    isListed={true}
                                    onChange={(...e) => {
                                      selectedParentIndex = index;
                                      selectedChildIndex = subIndex;
                                      handleChange(subObj.id, ...e);
                                    }}
                                    touched={(getArrayValues().length == index + 1) ? fields[subIndex] && fields[subIndex]?.hasError : ""}
                                    error={(getArrayValues().length == index + 1) ? fields[subIndex]?.errorMsg : ""}
                                    onBlur={() => (getArrayValues().length == index + 1) ? checkFrontEndValidation(obj, subIndex) : ""}

                                  />
                                  {subObj.field_type === "fileUpload" && typeof (subObj.field_selected_value && subObj.field_selected_value) != "object" ?
                                    <span style={{ fontSize: "12px", paddingBottom: "10px" }}>{subObj.field_selected_value && subObj.field_selected_value.substring(subObj.field_selected_value.lastIndexOf('/') + 1)}
                                      <a href={subObj.field_selected_value} download><DownloadOutlined style={{ fontSize: "20px", padding: "0px 5px" }} /> </a>
                                    </span>
                                    : ""}
                                </Col>
                                {
                                  subObj.field_use_type.includes("SearchIndex") ?
                                    <Col md={1} xs={2}>
                                      <div style={window.innerWidth > 700 ? { marginTop:'30%', cursor: "pointer" } : { marginTop:'100%', cursor: "pointer" }} onClick={() => getSearchIndex(subObj)}><DatabaseFilled style={{ color: "#007bff", fontSize: "20px" }} /></div>
                                    </Col>
                                    : ""
                                }
                              </>
                            ))}


                            {obj && obj.length > 0 && index + 1 !== formData.form_fields.length && formData.form_fields.length > 1 && (
                              <Col xs={12}>

                                {formData.form_fields.length > 1 && (
                                  <div className="edit-border">
                                    <div className="edit-box">
                                      <div
                                        className="edit-text"
                                        onClick={() => seteditIndex(index)}
                                      >
                                        Edit
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </Col>
                            )}
                          </Row>
                        ))}

                    </>
                    :
                    <>
                      {/* this section if for action enabled true and root form */}

                      {formData.form_fields[0].length > 0 &&
                        formData.form_fields[tabindex][0].field_type ===
                        "dropDownPreviousResponses" && (
                          <Row>
                            {formData.form_fields[0].map((obj, index) => (
                              <>
                                {
                                  getCategory(obj) ?
                                    <Col md={12}><h5 style={{ borderBottom: "1px solid grey" }}>{obj.field_category}</h5></Col>
                                    : ""
                                }
                                <Col md={obj.field_type === "checkBox" ? 12 : 6} key={index}>
                                  <FieldItem
                                    name={obj.id}
                                    placeholder={
                                      obj.field_type === "number" ||
                                        obj.field_type === "textBox" ||
                                        obj.field_type === "textArea"
                                        ? "Enter"
                                        : "Select"
                                    }
                                    label={obj.field_name}
                                    isDisabled={formData.form_fields.length > 1}
                                    type={
                                      obj.field_type === "fileUpload"
                                        ? FIELD_TYPES.FILE_UPLOAD
                                        : obj.field_type === "dateField"
                                          ? FIELD_TYPES.DATEPICKER_NEW
                                          : obj.field_type === "dropDown"
                                            ? FIELD_TYPES.DROP_DOWN
                                            : obj.field_type === "dropDownPreviousResponses"
                                              ? FIELD_TYPES.DROP_DOWN
                                              : obj.field_type === "boolean"
                                                ? FIELD_TYPES.BOOLEAN
                                                : obj.field_type === "radioButton"
                                                  ? FIELD_TYPES.RADIO_BUTTON
                                                  : obj.field_type === "checkBox"
                                                    ? FIELD_TYPES.CHECK_BOX
                                                    : obj.field_type === "textArea"
                                                      ? FIELD_TYPES.TEXT_AREA
                                                      : obj.field_type === "textBox"
                                                        ? FIELD_TYPES.TEXT
                                                        : obj.field_type === "time"
                                                          ? FIELD_TYPES.TIME_PICKER
                                                          : obj.field_type === "dateTime"
                                                            ? FIELD_TYPES.DATE_TIME_PICKER
                                                            : FIELD_TYPES.TEXT
                                    }
                                    isNumber={obj.field_type === "number" ? true : false}
                                    value={obj.field_type == "dateField" ? obj.field_selected_value ? dayjs(obj.field_selected_value) : null : obj.field_selected_value}
                                    values={obj.field_value}
                                    isListed={true}
                                    isMandatory={false}
                                    onChange={(...e) => {
                                      selectedParentIndex = 0;
                                      selectedChildIndex = index;
                                      handleChange(obj.id, ...e);
                                    }}
                                    onBlur={() => checkFrontEndValidation(obj, index)}
                                  />
                                  {obj.field_type === "fileUpload" && typeof (obj.field_selected_value && obj.field_selected_value) != "object" ?
                                    <span style={{ fontSize: "12px", paddingBottom: "10px" }}>{obj.field_selected_value && obj.field_selected_value.substring(obj.field_selected_value.lastIndexOf('/') + 1)}
                                      <a href={obj.field_selected_value} download><DownloadOutlined style={{ fontSize: "20px", padding: "0px 5px" }} /> </a>
                                    </span>
                                    : ""}
                                </Col>
                                <Col md={1}>
                                  {
                                    obj.field_use_type.includes("SearchIndex") ?
                                      <div style={{ marginTop: "25%", cursor: "pointer" }} onClick={() => getSearchIndex(obj)}><DatabaseFilled style={{ color: "#007bff", fontSize: "20px" }} /></div>
                                      : ""
                                  }
                                </Col>
                              </>
                            ))}
                            {/* <Col
                        xs={12}
                        className="w-100 d-flex m-0 mt-4 mb-4 justify-content-end"
                      >
                        {formData.form_fields.length === 1 && (
                          <CustomButton
                            type="primary"
                            onClick={() => onValidate()}
                            text="Fetch"
                          ></CustomButton>
                        )}
                      </Col> */}
                          </Row>
                        )}
                      {formData.form_fields[0].length > 0 &&
                        formData.form_fields[tabindex][0].field_type ===
                        "dropDownPreviousResponses" &&
                        tabindex == 0 && (
                          <div className="edit-border">
                            <div className="edit-box">
                              <div className="edit-text" onClick={() => seteditIndex(0)}>
                                Edit
                              </div>
                            </div>
                          </div>
                        )}
                      {/* added tabindex == 1 to avoid multiple render of same data need to test more */}
                      {tabindex == 1 && formData.form_fields.length > 0 &&
                        formData.form_fields[tabindex].length > 0 &&
                        getArrayValues().map((obj, index) => (
                          <Row key={index}>

                            {obj.map((subObj, subIndex) => (
                              <>
                                {
                                  getCategory(subObj) ?
                                    <Col md={12}><h5 style={{ borderBottom: "1px solid grey" }}>{subObj.field_category}</h5></Col>
                                    : ""
                                }

                                <Col md={obj.field_type === "checkBox" ? 12 : 6} xs={10} key={subIndex}>

                                  <FieldItem
                                    className={'dynamic-item'}
                                    name={subObj.id}
                                    placeholder={
                                      subObj.field_type === "number" ||
                                        subObj.field_type === "textBox" ||
                                        subObj.field_type === "textArea"
                                        ? "Enter"
                                        : "Select"
                                    }
                                    label={subObj.field_name}
                                    isDisabled={subObj.isPartial ? true : index + 2 !== formData.form_fields.length}
                                    type={
                                      subObj.field_type === "fileUpload"
                                        ? FIELD_TYPES.FILE_UPLOAD
                                        : subObj.field_type === "dateField"
                                          ? FIELD_TYPES.DATEPICKER_NEW
                                          : subObj.field_type === "dropDown"
                                            ? FIELD_TYPES.DROP_DOWN
                                            : subObj.field_type === "dropDownPreviousResponses"
                                              ? FIELD_TYPES.DROP_DOWN
                                              : subObj.field_type === "boolean"
                                                ? FIELD_TYPES.BOOLEAN
                                                : subObj.field_type === "radioButton"
                                                  ? FIELD_TYPES.RADIO_BUTTON
                                                  : subObj.field_type === "checkBox"
                                                    ? FIELD_TYPES.CHECK_BOX
                                                    : subObj.field_type === "textArea"
                                                      ? FIELD_TYPES.TEXT_AREA
                                                      : subObj.field_type === "textBox"
                                                        ? FIELD_TYPES.TEXT
                                                        : subObj.field_type === "time"
                                                          ? FIELD_TYPES.TIME_PICKER
                                                          : subObj.field_type === "dateTime"
                                                            ? FIELD_TYPES.DATE_TIME_PICKER
                                                            : FIELD_TYPES.TEXT
                                    }
                                    isNumber={subObj.field_type === "number" ? true : false}
                                    value={subObj.field_type == "dateField" ? subObj.field_selected_value ? dayjs(subObj.field_selected_value) : null : subObj.field_selected_value}
                                    values={subObj.field_value}
                                    isListed={true}
                                    onChange={(...e) => {
                                      selectedParentIndex = index + 1;
                                      selectedChildIndex = subIndex;
                                      handleChange(subObj.id, ...e);
                                      //checkFrontEndValidation(obj, subIndex)
                                    }}
                                    touched={(getArrayValues().length == index + 1) ? fields[subIndex] && fields[subIndex]?.hasError : ""}
                                    error={(getArrayValues().length == index + 1) ? fields[subIndex]?.errorMsg : ""}
                                    onBlur={() => (getArrayValues().length == index + 1) ? checkFrontEndValidation(obj, subIndex) : ""}

                                  />
                                  {subObj.field_type === "fileUpload" && typeof (subObj.field_selected_value && subObj.field_selected_value) != "object" ?
                                    <span style={{ fontSize: "12px", paddingBottom: "10px" }}>{subObj.field_selected_value && subObj.field_selected_value.substring(subObj.field_selected_value.lastIndexOf('/') + 1)}
                                      <a href={subObj.field_selected_value} download><DownloadOutlined style={{ fontSize: "20px", padding: "0px 5px" }} /> </a>
                                    </span>
                                    : ""}
                                </Col>
                                {
                                  subObj.field_use_type.includes("SearchIndex") ?
                                    <Col md={1} xs={2}>
                                      <div style={{ marginTop: "25%", cursor: "pointer" }} onClick={() => getSearchIndex(subObj)}><DatabaseFilled style={{ color: "#007bff", fontSize: "20px" }} /></div>
                                    </Col>
                                    : ""
                                }
                              </>
                            ))}
                            {index + 2 !== formData.form_fields.length && (
                              <Col xs={12}>
                                {formData.form_fields.length > 1 && (
                                  <div className="edit-border">
                                    <div className="edit-box">
                                      <div
                                        className="edit-text"
                                        onClick={() => seteditIndex(index + 1)}
                                      >
                                        Edit
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </Col>
                            )}
                          </Row>
                        ))}

                    </>
                  :
                  <>
                    // <TableViewData existData={existSelectedData} formData={formData} onTableSubmit={onTableSubmit} onEditTable={onEditTable} editIndexV={tabindex} />
                  </>
              ))
            }


            {/* execute this if add */}
            {
              !props.selectedValues && formData && formData.isTable.map((obj, tabindex) => (
                !obj ?
                  actionEnabled ?
                    <>
                      {/* this section if for action enabled true and root form */}
                      {formData.form_fields[0].length > 0 &&
                        formData.form_fields[tabindex][0].field_type ===
                        "dropDownPreviousResponses" && (
                          <Row>
                            {formData.form_fields[0].map((obj, index) => (
                              <>
                                {
                                  getCategory(obj) ?
                                    <Col md={12}><h5 style={{ borderBottom: "1px solid grey" }}>{obj.field_category}</h5></Col>
                                    : ""
                                }
                                <Col md={obj.field_type === "checkBox" ? 12 : 6} xs={10} key={index}>
                                  <FieldItem
                                    name={obj.id}
                                    placeholder={
                                      obj.field_type === "number" ||
                                        obj.field_type === "textBox" ||
                                        obj.field_type === "textArea"
                                        ? "Enter"
                                        : "Select"
                                    }
                                    label={obj.field_name}
                                    isDisabled={formData.form_fields.length > 1}
                                    type={
                                      obj.field_type === "fileUpload"
                                        ? FIELD_TYPES.FILE_UPLOAD
                                        : obj.field_type === "dateField"
                                          ? FIELD_TYPES.DATEPICKER_NEW
                                          : obj.field_type === "dropDown"
                                            ? FIELD_TYPES.DROP_DOWN
                                            : obj.field_type === "dropDownPreviousResponses"
                                              ? FIELD_TYPES.DROP_DOWN
                                              : obj.field_type === "boolean"
                                                ? FIELD_TYPES.BOOLEAN
                                                : obj.field_type === "radioButton"
                                                  ? FIELD_TYPES.RADIO_BUTTON
                                                  : obj.field_type === "checkBox"
                                                    ? FIELD_TYPES.CHECK_BOX
                                                    : obj.field_type === "textArea"
                                                      ? FIELD_TYPES.TEXT_AREA
                                                      : obj.field_type === "textBox"
                                                        ? FIELD_TYPES.TEXT
                                                        : obj.field_type === "time"
                                                          ? FIELD_TYPES.TIME_PICKER
                                                          : obj.field_type === "dateTime"
                                                            ? FIELD_TYPES.DATE_TIME_PICKER
                                                            : FIELD_TYPES.TEXT
                                    }
                                    isNumber={obj.field_type === "number" ? true : false}
                                    value={obj.field_type == "dateField" ? obj.field_selected_value ? dayjs(obj.field_selected_value) : null : obj.field_selected_value}
                                    values={obj.field_value}
                                    isListed={true}
                                    isMandatory={false}
                                    onChange={(...e) => {
                                      selectedParentIndex = 0;
                                      selectedChildIndex = index;
                                      handleChange(obj.id, ...e);
                                    }}
                                  />
                                  {obj.field_type === "fileUpload" && typeof (obj.field_selected_value && obj.field_selected_value) != "object" ?
                                    <span style={{ fontSize: "12px", paddingBottom: "10px" }}>{obj.field_selected_value && obj.field_selected_value.substring(obj.field_selected_value.lastIndexOf('/') + 1)}
                                      <a href={obj.field_selected_value} download><DownloadOutlined style={{ fontSize: "20px", padding: "0px 5px" }} /> </a>
                                    </span>
                                    : ""}
                                </Col>
                                <Col md={1} xs={2}>
                                  {
                                    obj.field_use_type.includes("SearchIndex") ?
                                      <div style={window.innerWidth > 700 ? { marginTop:'30%', cursor: "pointer" } : { marginTop:'100%', cursor: "pointer" }} onClick={() => getSearchIndex(obj)}><DatabaseFilled style={{ color: "#007bff", fontSize: "20px" }} /></div>
                                      : ""
                                  }
                                </Col>
                              </>
                            ))}
                            <Col
                              xs={12}
                              className="w-100 d-flex m-0 mt-4 mb-4 justify-content-end"
                            >
                              {formData.form_fields.length === 1 && (
                                <CustomButton
                                  type="primary"
                                  onClick={() => onValidate()}
                                  text="Fetch"
                                ></CustomButton>
                              )}
                            </Col>
                          </Row>
                        )}
                      {formData.form_fields[0].length > 0 &&
                        formData.form_fields[tabindex][0].field_type ===
                        "dropDownPreviousResponses" &&
                        (
                          <div className="edit-border">
                            <div className="edit-box">
                              <div className="edit-text" onClick={() => seteditIndex(0)}>
                                Edit
                              </div>
                            </div>
                          </div>
                        )}


                      {tabindex == 1 && formData.form_fields.length > 1 &&
                        getArrayValues().map((obj, index) => (
                          <Row key={index}>
                         
                            {obj.map((subObj, subIndex) => (
                              <>

                                {
                                  getCategory(subObj) ?
                                    <Col md={12}><h5 style={{ borderBottom: "1px solid grey" }}>{subObj.field_category}</h5></Col>
                                    : ""
                                }
                                <Col md={obj.field_type === "checkBox" ? 12 : subObj.field_use_type.includes("SearchIndex") ? 5 : 6} xs={subObj.field_use_type.includes("SearchIndex") ? 10 : 12} key={subIndex}>

                                  <FieldItem
                                    className={'dynamic-item'}
                                    name={subObj.id}
                                    placeholder={
                                      subObj.field_type === "number" ||
                                        subObj.field_type === "textBox" ||
                                        subObj.field_type === "textArea"
                                        ? "Enter"
                                        : "Select"
                                    }
                                    label={subObj.field_name}
                                    isDisabled={subObj.isPartial ? true : index + 1 !== formData.form_fields.length}
                                    type={
                                      subObj.field_type === "fileUpload"
                                        ? FIELD_TYPES.FILE_UPLOAD
                                        : subObj.field_type === "dateField"
                                          ? FIELD_TYPES.DATEPICKER_NEW
                                          : subObj.field_type === "dropDown"
                                            ? FIELD_TYPES.DROP_DOWN
                                            : subObj.field_type === "dropDownPreviousResponses"
                                              ? FIELD_TYPES.DROP_DOWN
                                              : subObj.field_type === "boolean"
                                                ? FIELD_TYPES.BOOLEAN
                                                : subObj.field_type === "radioButton"
                                                  ? FIELD_TYPES.RADIO_BUTTON
                                                  : subObj.field_type === "checkBox"
                                                    ? FIELD_TYPES.CHECK_BOX
                                                    : subObj.field_type === "textArea"
                                                      ? FIELD_TYPES.TEXT_AREA
                                                      : subObj.field_type === "textBox"
                                                        ? FIELD_TYPES.TEXT
                                                        : subObj.field_type === "time"
                                                          ? FIELD_TYPES.TIME_PICKER
                                                          : subObj.field_type === "dateTime"
                                                            ? FIELD_TYPES.DATE_TIME_PICKER
                                                            : FIELD_TYPES.TEXT
                                    }
                                    isNumber={subObj.field_type === "number" ? true : false}
                                    value={subObj.field_type == "dateField" ? subObj.field_selected_value ? dayjs(subObj.field_selected_value) : null : subObj.field_selected_value}
                                    values={subObj.field_value}
                                    isListed={true}
                                    onChange={(...e) => {
                                      selectedParentIndex = index;
                                      selectedChildIndex = subIndex;
                                      handleChange(subObj.id, ...e);
                                    }}
                                    touched={(getArrayValues().length == index + 1) ? fields[subIndex] && fields[subIndex]?.hasError : ""}
                                    error={(getArrayValues().length == index + 1) ? fields[subIndex]?.errorMsg : ""}
                                    onBlur={() => (getArrayValues().length == index + 1) ? checkFrontEndValidation(obj, subIndex) : ""}

                                  />
                                  {subObj.field_type === "fileUpload" && typeof (subObj.field_selected_value && subObj.field_selected_value) != "object" ?
                                    <span style={{ fontSize: "12px", paddingBottom: "10px" }}>{subObj.field_selected_value && subObj.field_selected_value.substring(subObj.field_selected_value.lastIndexOf('/') + 1)}
                                      <a href={subObj.field_selected_value} download><DownloadOutlined style={{ fontSize: "20px", padding: "0px 5px" }} /> </a>
                                    </span>
                                    : ""}
                                </Col>
                                {
                                  subObj.field_use_type.includes("SearchIndex") ?
                                    <Col md={1} xs={2}>
                                      <div style={window.innerWidth > 700 ? { marginTop:'30%', cursor: "pointer" } : { marginTop:'100%', cursor: "pointer" }} onClick={() => getSearchIndex(subObj)}><DatabaseFilled style={{ color: "#007bff", fontSize: "20px" }} /></div>
                                    </Col>
                                    : ""
                                }
                              </>
                            ))}


                            {obj && obj.length > 0 && index + 1 !== formData.form_fields.length && formData.form_fields.length > 1 && (
                              <Col xs={12}>

                                {formData.form_fields.length > 1 && (
                                  <div className="edit-border">
                                    <div className="edit-box">
                                      <div
                                        className="edit-text"
                                        onClick={() => seteditIndex(index)}
                                      >
                                        Edit
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </Col>
                            )}
                          </Row>
                        ))}

                    </>
                    :
                    <>
                      {/* this section if for action enabled true and root form */}

                      {formData.form_fields[0].length > 0 &&
                        formData.form_fields[tabindex][0].field_type ===
                        "dropDownPreviousResponses" && (
                          <Row>
                            {formData.form_fields[0].map((obj, index) => (
                              <>
                                {
                                  getCategory(obj) ?
                                    <Col md={12}><h5 style={{ borderBottom: "1px solid grey" }}>{obj.field_category}</h5></Col>
                                    : ""
                                }
                                <Col md={obj.field_type === "checkBox" ? 12 : 6} key={index}>
                                  <FieldItem
                                    name={obj.id}
                                    placeholder={
                                      obj.field_type === "number" ||
                                        obj.field_type === "textBox" ||
                                        obj.field_type === "textArea"
                                        ? "Enter"
                                        : "Select"
                                    }
                                    label={obj.field_name}
                                    isDisabled={formData.form_fields.length > 1}
                                    type={
                                      obj.field_type === "fileUpload"
                                        ? FIELD_TYPES.FILE_UPLOAD
                                        : obj.field_type === "dateField"
                                          ? FIELD_TYPES.DATEPICKER_NEW
                                          : obj.field_type === "dropDown"
                                            ? FIELD_TYPES.DROP_DOWN
                                            : obj.field_type === "dropDownPreviousResponses"
                                              ? FIELD_TYPES.DROP_DOWN
                                              : obj.field_type === "boolean"
                                                ? FIELD_TYPES.BOOLEAN
                                                : obj.field_type === "radioButton"
                                                  ? FIELD_TYPES.RADIO_BUTTON
                                                  : obj.field_type === "checkBox"
                                                    ? FIELD_TYPES.CHECK_BOX
                                                    : obj.field_type === "textArea"
                                                      ? FIELD_TYPES.TEXT_AREA
                                                      : obj.field_type === "textBox"
                                                        ? FIELD_TYPES.TEXT
                                                        : obj.field_type === "time"
                                                          ? FIELD_TYPES.TIME_PICKER
                                                          : obj.field_type === "dateTime"
                                                            ? FIELD_TYPES.DATE_TIME_PICKER
                                                            : FIELD_TYPES.TEXT
                                    }
                                    isNumber={obj.field_type === "number" ? true : false}
                                    value={obj.field_type == "dateField" ? obj.field_selected_value ? dayjs(obj.field_selected_value) : null : obj.field_selected_value}
                                    values={obj.field_value}
                                    isListed={true}
                                    isMandatory={false}
                                    onChange={(...e) => {
                                      selectedParentIndex = 0;
                                      selectedChildIndex = index;
                                      handleChange(obj.id, ...e);
                                    }}
                                    onBlur={() => checkFrontEndValidation(obj, index)}
                                  />
                                  {obj.field_type === "fileUpload" && typeof (obj.field_selected_value && obj.field_selected_value) != "object" ?
                                    <span style={{ fontSize: "12px", paddingBottom: "10px" }}>{obj.field_selected_value && obj.field_selected_value.substring(obj.field_selected_value.lastIndexOf('/') + 1)}
                                      <a href={obj.field_selected_value} download><DownloadOutlined style={{ fontSize: "20px", padding: "0px 5px" }} /> </a>
                                    </span>
                                    : ""}
                                </Col>
                                <Col md={1}>
                                  {
                                    obj.field_use_type.includes("SearchIndex") ?
                                      <div style={{ marginTop: "25%", cursor: "pointer" }} onClick={() => getSearchIndex(obj)}><DatabaseFilled style={{ color: "#007bff", fontSize: "20px" }} /></div>
                                      : ""
                                  }
                                </Col>
                              </>
                            ))}
                            {/* <Col
                        xs={12}
                        className="w-100 d-flex m-0 mt-4 mb-4 justify-content-end"
                      >
                        {formData.form_fields.length === 1 && (
                          <CustomButton
                            type="primary"
                            onClick={() => onValidate()}
                            text="Fetch"
                          ></CustomButton>
                        )}
                      </Col> */}
                          </Row>
                        )}
                      {formData.form_fields[0].length > 0 &&
                        formData.form_fields[tabindex][0].field_type ===
                        "dropDownPreviousResponses" &&
                        tabindex == 0 && (
                          <div className="edit-border">
                            <div className="edit-box">
                              <div className="edit-text" onClick={() => seteditIndex(0)}>
                                Edit
                              </div>
                            </div>
                          </div>
                        )}
                      {/* added tabindex == 1 to avoid multiple render of same data need to test more */}
                      {tabindex == 1 && formData.form_fields.length > 0 &&
                        formData.form_fields[tabindex].length > 0 &&
                        getArrayValues().map((obj, index) => (
                          <Row key={index}>

                            {obj.map((subObj, subIndex) => (
                              <>
                                {
                                  getCategory(subObj) ?
                                    <Col md={12}><h5 style={{ borderBottom: "1px solid grey" }}>{subObj.field_category}</h5></Col>
                                    : ""
                                }

                                <Col md={obj.field_type === "checkBox" ? 12 : 6} xs={10} key={subIndex}>

                                  <FieldItem
                                    className={'dynamic-item'}
                                    name={subObj.id}
                                    placeholder={
                                      subObj.field_type === "number" ||
                                        subObj.field_type === "textBox" ||
                                        subObj.field_type === "textArea"
                                        ? "Enter"
                                        : "Select"
                                    }
                                    label={subObj.field_name}
                                    isDisabled={subObj.isPartial ? true : index + 2 !== formData.form_fields.length}
                                    type={
                                      subObj.field_type === "fileUpload"
                                        ? FIELD_TYPES.FILE_UPLOAD
                                        : subObj.field_type === "dateField"
                                          ? FIELD_TYPES.DATEPICKER_NEW
                                          : subObj.field_type === "dropDown"
                                            ? FIELD_TYPES.DROP_DOWN
                                            : subObj.field_type === "dropDownPreviousResponses"
                                              ? FIELD_TYPES.DROP_DOWN
                                              : subObj.field_type === "boolean"
                                                ? FIELD_TYPES.BOOLEAN
                                                : subObj.field_type === "radioButton"
                                                  ? FIELD_TYPES.RADIO_BUTTON
                                                  : subObj.field_type === "checkBox"
                                                    ? FIELD_TYPES.CHECK_BOX
                                                    : subObj.field_type === "textArea"
                                                      ? FIELD_TYPES.TEXT_AREA
                                                      : subObj.field_type === "textBox"
                                                        ? FIELD_TYPES.TEXT
                                                        : subObj.field_type === "time"
                                                          ? FIELD_TYPES.TIME_PICKER
                                                          : subObj.field_type === "dateTime"
                                                            ? FIELD_TYPES.DATE_TIME_PICKER
                                                            : FIELD_TYPES.TEXT
                                    }
                                    isNumber={subObj.field_type === "number" ? true : false}
                                    value={subObj.field_type == "dateField" ? subObj.field_selected_value ? dayjs(subObj.field_selected_value) : null : subObj.field_selected_value}
                                    values={subObj.field_value}
                                    isListed={true}
                                    onChange={(...e) => {
                                      selectedParentIndex = index + 1;
                                      selectedChildIndex = subIndex;
                                      handleChange(subObj.id, ...e);
                                      //checkFrontEndValidation(obj, subIndex)
                                    }}
                                    touched={(getArrayValues().length == index + 1) ? fields[subIndex] && fields[subIndex]?.hasError : ""}
                                    error={(getArrayValues().length == index + 1) ? fields[subIndex]?.errorMsg : ""}
                                    onBlur={() => (getArrayValues().length == index + 1) ? checkFrontEndValidation(obj, subIndex) : ""}

                                  />
                                  {subObj.field_type === "fileUpload" && typeof (subObj.field_selected_value && subObj.field_selected_value) != "object" ?
                                    <span style={{ fontSize: "12px", paddingBottom: "10px" }}>{subObj.field_selected_value && subObj.field_selected_value.substring(subObj.field_selected_value.lastIndexOf('/') + 1)}
                                      <a href={subObj.field_selected_value} download><DownloadOutlined style={{ fontSize: "20px", padding: "0px 5px" }} /> </a>
                                    </span>
                                    : ""}
                                </Col>
                                {
                                  subObj.field_use_type.includes("SearchIndex") ?
                                    <Col md={1} xs={2}>
                                      <div style={{ marginTop: "25%", cursor: "pointer" }} onClick={() => getSearchIndex(subObj)}><DatabaseFilled style={{ color: "#007bff", fontSize: "20px" }} /></div>
                                    </Col>
                                    : ""
                                }
                              </>
                            ))}
                            {index + 2 !== formData.form_fields.length && (
                              <Col xs={12}>
                                {formData.form_fields.length > 1 && (
                                  <div className="edit-border">
                                    <div className="edit-box">
                                      <div
                                        className="edit-text"
                                        onClick={() => seteditIndex(index + 1)}
                                      >
                                        Edit
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </Col>
                            )}
                          </Row>
                        ))}

                    </>
                  :
                  <>
                    <TableViewData existData={existSelectedData} formData={formData} onTableSubmit={onTableSubmit} onEditTable={onEditTable} editIndexV={tabindex} />
                  </>
              ))
            }


            {
              formData && formData.isTable[formData.isTable.length - 1] == false ?

                <div className="footer-card">
                  <div className="p-0 m-1" >
                    <CustomButton
                      type="alert-secondary"
                      onClick={() => props.onUpload()}
                      text="Upload"
                    ></CustomButton>
                  </div>
                  <div className="p-0 m-1" >
                    <CustomButton
                      isDisabled={isDataEntered == false || errorEmail == true || errorMandatory == true || errorMobile == true || errorRange == true || errorInteger == true ? "disabled" : ""}
                      type="primary"
                      onClick={() => fetchFormData()}
                      text="Next"
                    ></CustomButton>
                  </div>
                </div>
                : ""
            }
          </>
        )
      )}


    </div>
  );
}
