import React from 'react';

const TableSkeleton = ({ rows = 10, columns = 4 }) => (
  <>
  <table className="skeleton-table">
    <thead>
      <tr>
        {Array.from({ length: columns }).map((_, i) => (
          <th key={i} className="skeleton-cell"></th>
        ))}
      </tr>
    </thead>
    <tbody>
      {Array.from({ length: rows }).map((_, rowIndex) => (
        <tr key={rowIndex}>
          {Array.from({ length: columns }).map((_, colIndex) => (
            <td key={colIndex} className="skeleton-cell"></td>
          ))}
        </tr>
      ))}
    </tbody>
  </table>
  </>
);

export default TableSkeleton;
